import React from "react";
import "./banner.css";
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <div className="container-fluid work-banner-section px-0 top-wrapper">
      <div className="work-banner-container">
        <div className="services-container-header">
          <h1>
            How Solar Works:
            <span>Harnessing the Power of the Sun for Your Business</span>
          </h1>
        </div>
        <div className="services-container-body">
          <p>
            Welcome to SunChoice's How Solar Works page, where we demystify the
            process of harnessing solar energy for your business. Explore the
            journey from sunlight to electricity and discover how solar can
            revolutionize your energy landscape.
          </p>
          <NavLink to="/solar-system">Get started </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Banner;
