import React, { useEffect, useState } from "react";
import "./blog.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import headingBorder from "../../../assets/images/heading-border.png";
import { NavLink } from "react-router-dom";
import BlogCard from "./blogCard";
import Loader from "../../common/loader/loader";
import axios from "axios";
const Blog = (props) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setSpinner(true);
        const response = await axios.get(`${baseUrl}/get-post-data`);
        setBlogData(response.data.data.blog);
        setSpinner(false);
      } catch (error) {
        console.error("Error fetching blog data:", error);
        setSpinner(false);
      }
    };

    fetchBlogData();
  }, [baseUrl]);
  useEffect(() => {
    const stHeight = document.querySelector(".slick-track").clientHeight;
    document.querySelectorAll(".slick-slide").forEach((slide) => {
      slide.style.height = stHeight + "px";
    });
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
  var settingss = {
    dots: false,
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 5000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <div
      className={`container-fluid blog-container ${
        props.className || ""
      } px-0 px-sm-3`}
    >
      <div className="container blog-container-inner px-0 px-sm-3">
        <div
          className="blod-section-header animated-element px-3 px-sm-0"
          data-aos="fade-down"
        >
          {props.title ? (
            <h1>
              {props.title}
              <img src={headingBorder} />
            </h1>
          ) : (
            <h1>
              Discover the Latest Blogs{" "}
              <span>Stay informed with our blog posts</span>
            </h1>
          )}
        </div>
        <Slider {...settingss}>
          {blogData.map((blog, index) => (
            <div
              key={blog.id}
              className="blog-card-wrapper animated-element"
              data-aos="fade-up"
            >
              <BlogCard {...blog} />
            </div>
          ))}
        </Slider>
        {props.title ? (
          ""
        ) : (
          <div className="blod-section-footer">
            <NavLink to="/blog">
              View all blog posts{" "}
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.83332 0C9.58332 0 9.41666 0.083333 9.24999 0.25L0.916657 8.58333C0.583323 8.91667 0.583323 9.41667 0.916657 9.75C1.24999 10.0833 1.74999 10.0833 2.08332 9.75L10.4167 1.41667C10.75 1.08333 10.75 0.583333 10.4167 0.25C10.25 0.083333 10.0833 0 9.83332 0Z"
                  fill="#007BFF"
                />
                <path
                  d="M9.833 0.000325203H2.333C1.833 0.000325203 1.49966 0.333658 1.49966 0.833658C1.49966 1.33366 1.833 1.66699 2.333 1.66699H8.99966V8.33366C8.99966 8.83366 9.333 9.16699 9.833 9.16699C10.333 9.16699 10.6663 8.83366 10.6663 8.33366V0.833658C10.6663 0.333658 10.333 0.000325203 9.833 0.000325203Z"
                  fill="#007BFF"
                />
              </svg>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
