import React, { useState, useEffect } from "react";
import Banner from "../components/blogDetail/banner/banner";
import BlogContent from "../components/blogDetail/blogContent/blogContent";
import { useParams } from "react-router-dom";
import Blog from "../components/common/blogSection/blog";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import axios from "axios";
import Loader from "../components/common/loader/loader";

const BlogDetail = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setSpinner(true);
        const response = await axios.get(`${baseUrl}/get-post-data`);
        setBlogData(response?.data?.data?.blog);
        setSpinner(false);
      } catch (error) {
        console.error("Error fetching blog data:", error);
        setSpinner(false);
      }
    };

    fetchBlogData();
  }, [baseUrl]);

  const blogContent = blogData.find((blog) => blog.id.toString() === id);

  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      {blogContent && (
        <>
          <Banner {...blogContent} />
          <BlogContent {...blogContent} />
        </>
      )}
      <Blog className="blog-section-white" title="You might like" />
    </>
  );
};

export default BlogDetail;
