import React, { useEffect, useState } from "react";
import sunImage from "../../../assets/images/030.png";

const SunAnimation = () => {
  const [scrollOpacity, setScrollOpacity] = useState(0.2);
  const [scrollWidth, setScrollWidth] = useState(50);
  const [scrollScale, setScrollScale] = useState(50);
  useEffect(() => {
    document.body.style.overflow = "auto";
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxScroll = 5000;
      const opacity = Math.min(0.2 + scrollPosition / maxScroll, 0.7);
      setScrollOpacity(opacity);
      const maxWidth = 100;
      const minWidth = 70;
      const width = Math.min(
        minWidth + (scrollPosition / maxScroll) * (maxWidth - minWidth),
        maxWidth
      );
      setScrollWidth(width);
      const maxScale = 2.5;
      const minScale = 1.5;
      const scale = Math.max(
        minScale + (scrollPosition / maxScroll) * (maxScale - minScale),
        minScale
      );
      setScrollScale(scale);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const containerStyle = {
    opacity: scrollOpacity,
    width: `${scrollWidth}%`,
    maxWidth: "100%",
    transform: `scale(${scrollScale})`,
    transition: "width 0.3s ease",
  };
  return (
    <img className="sun-container " src={sunImage} style={containerStyle} />
  );
};

export default SunAnimation;
