import React, { useEffect } from "react";
import HeadingBorder from "../../../assets/images/heading-border.png";
import ValueImage from "../../../assets/images/sunchoice-img.JPG";
import ValueIcon from "../../../assets/images/values-icon.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "./value.css";
import { NavLink } from "react-router-dom";

const Value = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
  return (
    <div className="container value-section-wrapper py-5 mt-3">
      <div className="value-section">
        <div
          className="mission-section-right animated-element"
          data-aos="fade-up"
        >
          <img src={ValueImage} alt="" />
          <div className="mission-section-right-icon-wrapper">
            <img src={ValueIcon} alt="" />
          </div>
        </div>
        <div
          className="mission-section-left animated-element"
          data-aos="fade-down"
        >
          <div className="mission-section-left-header">
            <h1>
              Our
              <span>
                Values <img src={HeadingBorder} alt="" />
              </span>
            </h1>
          </div>
          <div className="mission-section-left-body mt-4 mt-md-2">
            <p>
              To truly <span>CARE ↑</span>, we believe in embodying the values
              of:
            </p>
            <ul>
              <li>
                <div>C</div>
                lear integrity
              </li>
              <li>
                <div>A</div>
                ccountability
              </li>
              <li>
                <div>R</div>
                espect
              </li>
              <li>
                <div>E</div>
                mpathy, in all we do.
              </li>
            </ul>
            <p>
              As a company, we use these core values as a compass to guide us
              through every phase of our business to make sure you as the client
              are taken care of. To ensure we continue to hold true to our
              mission and these core values, as we grow we earnestly seek to
              hire individuals that reside locally in the territories we serve.
              In addition to this, every new member of our team receives
              hands-on training on our values and signs what we call a “Core 4
              Pledge” on which they commit to helping us uphold our core small
              town values no matter how big our business becomes.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 value-section-btn pt-4 pt-md-5 text-center">
        <NavLink to="/about">
          Learn more about our company
          <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.66667 0C9.41667 0 9.25 0.083333 9.08333 0.25L0.75 8.58333C0.416667 8.91667 0.416667 9.41667 0.75 9.75C1.08333 10.0833 1.58333 10.0833 1.91667 9.75L10.25 1.41667C10.5833 1.08333 10.5833 0.583333 10.25 0.25C10.0833 0.083333 9.91667 0 9.66667 0Z"
              fill="#FEFEFE"
            />
            <path
              d="M9.66634 0.000325203H2.16634C1.66634 0.000325203 1.33301 0.333658 1.33301 0.833658C1.33301 1.33366 1.66634 1.66699 2.16634 1.66699H8.83301V8.33366C8.83301 8.83366 9.16634 9.16699 9.66634 9.16699C10.1663 9.16699 10.4997 8.83366 10.4997 8.33366V0.833658C10.4997 0.333658 10.1663 0.000325203 9.66634 0.000325203Z"
              fill="#FEFEFE"
            />
          </svg>
        </NavLink>
      </div>
    </div>
  );
};

export default Value;
