import React, { useEffect, useState } from "react";
import MissionVAlue from "../components/about/mission-value/mission-Value";
import Banner from "../components/about/banner/banner";
import Team from "../components/about/team/team";
import axios from "axios";
import Community from "../components/about/community/community";
import Pioneer from "../components/about/pioneer/pioneer";
import Feedback from "../components/about/feedback/feedback";
import Loader from "../components/common/loader/loader";
import Ourmission from "../components/about/ourmission/ourmission";
import AboutSunchoice from "../components/about/about-sunchoice/aboutSunchoice";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import Blog from "../components/common/blogSection/blog";
import Multitab from "../components/about/multitab/multitab";
import checkIcon from "../assets/images/check-icon.png";
const AboutUs = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const [spinner, setSpinner] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [missionData, setMissionData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [embraceFreedomData, setEmbraceFreedomData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchMissionData = async () => {
    try {
      console.log("*********   API CALL - get-mission-values - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-mission-values`);
      const data = response?.data?.data?.mission_values;
      const transformedData = data.map((item) => ({
        ...item,
        iconImage: imgUrl + item.icon_image,
        icon_image: undefined,
      }));
      setMissionData(transformedData);
      
      fetchCompanyData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching mission values data:", error);
      setSpinner(false);
    }
  };
  const fetchData = async () => {
    setSpinner(true);
    try {
      console.log("*********   API CALL - get-team - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-team`);
      setTeamData(response?.data?.data?.team);
      fetchCommunityData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching team data:", error);
      setSpinner(false);
    }
  };
  const fetchCommunityData = async () => {
    try {
      console.log("*********   API CALL - get-community-impact - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-community-impact`);
      setCommunityData(response?.data?.data?.community_impact);
      fetchMissionData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching community data:", error);
      setSpinner(false);
    }
  };
  const staticClasses = [
    "multitab-section-one",
    "",
    "multitab-section-two",
    "multitab-section-three",
    "multitab-section-one",
    "",
    "multitab-section-two",
  ];
  const fetchCompanyData = async () => {
    try {
      console.log("*********   API CALL - get-multi-tabs- Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-multi-tabs`);
      const data = response?.data?.data?.multi_tabs;
      const transformedData = data.map((item, index) => ({
        ...item,
        icon_path: imgUrl + item.icon_path,
        image_path: imgUrl + item.image_path,
        className: staticClasses[index % staticClasses.length],
      }));
      setCompanyData(transformedData);
      
      fetchFeedbackData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching company data:", error);
      setSpinner(false);
    }
  };
  const fetchFeedbackData = async () => {
    try {
      console.log("*********   API CALL - get-feedback - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-feedback`);
      const data = response?.data?.data?.feedback;
      const transformedData = data.map((item, index) => ({
        ...item,
        reviewer_image: imgUrl + item.reviewer_image,
      }));
      setFeedbackData(transformedData);
      fetchEmbraceFreedomData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching feedback data:", error);
      setSpinner(false);
    }
  };
  const fetchEmbraceFreedomData = async () => {
    try {
      console.log("*********   API CALL - get-aims-card - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-aims-card`);
      const data = response?.data?.data?.aims_cards;
      const missionAimCard = data.filter((data) => data.type === "mission");
      const transformedData = missionAimCard.map((item) => ({
        ...item,
        mission_items: item.mission_items ? JSON.parse(item.mission_items) : [],
        buttonContent: "Get started",
        icon: checkIcon,
      }));
      setEmbraceFreedomData(transformedData);
      setSpinner(false);
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching embrace freedom data:", error);
      setSpinner(false);
    }
  };
  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      <Banner />
      <MissionVAlue missionData={missionData} />
      <Multitab companyData={companyData} />
      <Team teamData={teamData} />
      <AboutSunchoice />
      <Community communityData={communityData} />
      <Pioneer />
      <Feedback feedbackData={feedbackData} />
      <Ourmission embraceFreedomData={embraceFreedomData} />
      <Blog className="blog-section-whtie" />
    </>
  );
};

export default AboutUs;
