import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";

const AddFeedback = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [coverImageView, setCoverImageView] = useState(null);
  let navigation = useNavigate();

  const handleCoverFileChange = (e) => {
    const file = e.target.files[0];
    setCoverImagePreview(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImageView(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setCoverImageView(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    let coverImageURL = null;
    setSpinner(true);
    if (coverImagePreview) {
      try {
        const coverImageFormData = new FormData();
        coverImageFormData.append("files", coverImagePreview);
        const coverImageResponse = await axios.post(
          `${baseUrl}/upload_files`,
          coverImageFormData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        coverImageURL = coverImageResponse.data.data.files[0].path;
      } catch (error) {
        console.error("Error uploading icon:", error);
      }
    }

    async function convertBase64ToBlob(imagePreview) {
      const matches = imagePreview.match(
        /^data:image\/([a-zA-Z+]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        throw new Error("Invalid base64 image format");
      }
      const [_, imageType, imageData] = matches;

      const byteCharacters = atob(imageData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let j = 0; j < byteCharacters.length; j++) {
        byteNumbers[j] = byteCharacters.charCodeAt(j);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: `image/${imageType}` });

      return blob;
    }

    const name = e.target.reviewer_name.value.trim();
    if (!name) {
      toast.error("Name is required.");
      setSpinner(false);
      return;
    }
    const postFormData = {
      reviewer_image: coverImageURL,
      reviewer_name: e.target.reviewer_name.value,
      reviewer_designation: e.target.reviewer_designation.value,
      review: e.target.review.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-feedback`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success("Feedback added successfully", {
        onClose: () => navigation("/feedback-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error adding feedback:", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Add Feedback</h1>
              </div>
            </div>
            <div className="mb-4 form-field">
              <label htmlFor="coverImageUpload" className="custom-file-upload">
                Add Profile
              </label>
              <input
                id="coverImageUpload"
                type="file"
                className="form-control"
                onChange={handleCoverFileChange}
              />
            </div>
            {coverImageView && (
              <div className="mb-4 cover-image-view community-icon-viewer">
                <img src={coverImageView} alt="Cover Preview" />
              </div>
            )}
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="Name here..."
                name="reviewer_name"
              />
            </div>
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="Designation here..."
                name="reviewer_designation"
              />
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="Review here..."
                name="review"
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Add</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddFeedback;
