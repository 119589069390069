import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";

const AddSocialLinks = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [socialLinks, setSocialLinks] = useState({
    instagram: "",
    facebook: "",
    twitter: "",
    linkedin: "",
  });
  const [spinner, setSpinner] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSocialLinks({ ...socialLinks, [name]: value });
  };
  const isValidUrl = (url) => {
    const regex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?$/i;
    return regex.test(url);
  };
  useEffect(() => {
    const fetchSocialLinks = async () => {
      setSpinner(true);
      try {
        const response = await axios.get(`${baseUrl}/get-social-link`);
        const links = response?.data?.data?.social_links ?? [];
        const socialLinksMapped = links.reduce((acc, link) => {
          acc[link.social_type] = link.social_link;
          return acc;
        }, {});

        setSocialLinks(socialLinksMapped);
      } catch (error) {
        toast.error("Failed to fetch social links.");
      } finally {
        setSpinner(false);
      }
    };

    fetchSocialLinks();
  }, [baseUrl]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { instagram, facebook, twitter, linkedin } = socialLinks;

    if (
      (instagram && !isValidUrl(instagram)) ||
      (facebook && !isValidUrl(facebook)) ||
      (twitter && !isValidUrl(twitter)) ||
      (linkedin && !isValidUrl(linkedin))
    ) {
      toast.error(
        "Please enter valid URLs for the social media links provided."
      );
      return;
    }
    const { token } = localStorage;
    setSpinner(true);
    try {
      const response = await axios.post(
        `${baseUrl}/add-update-social-link`,
        {
          socialLinks: [
            {
              facebook: socialLinks.facebook,
              instagram: socialLinks.instagram,
              linkedin: socialLinks.linkedin,
              twitter: socialLinks.twitter,
            },
          ],
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Social links added successfully!");
    } catch (error) {
      toast.error("Failed to add social links.");
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Social Links</h1>
              </div>
            </div>
            <div className="mb-4 form-field form-field-social">
              <input
                type="text"
                className="form-control"
                placeholder="Instagram link here ..."
                name="instagram"
                value={socialLinks.instagram}
                onChange={handleChange}
              />
              <InstagramIcon className="icon" />
            </div>
            <div className="mb-4 form-field form-field-social">
              <input
                type="text"
                className="form-control"
                placeholder="Facebook link here ..."
                name="facebook"
                value={socialLinks.facebook}
                onChange={handleChange}
              />
              <FacebookIcon className="icon" />
            </div>
            <div className="mb-4 form-field form-field-social">
              <input
                type="text"
                className="form-control"
                placeholder="Twitter link here ..."
                name="twitter"
                value={socialLinks.twitter}
                onChange={handleChange}
              />
              <XIcon className="icon" />
            </div>
            <div className="mb-4 form-field form-field-social">
              <input
                type="text"
                className="form-control"
                placeholder="LinkedIn link here ..."
                name="linkedin"
                value={socialLinks.linkedin}
                onChange={handleChange}
              />
              <LinkedInIcon className="icon" />
            </div>
            <div className="form-field form-field-social pt-4">
              <button type="submit">Add</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSocialLinks;
