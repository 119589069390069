import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";

const AddAboutTabs = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [coverImageView, setCoverImageView] = useState(null);
  const [secondImagePreview, setSecondImagePreview] = useState(null);
  const [secondImageView, setSecondImageView] = useState(null);
  let navigation = useNavigate();

  const handleCoverFileChange = (e) => {
    const file = e.target.files[0];
    setCoverImagePreview(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImageView(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setCoverImageView(null);
    }
  };

  const handleSecondFileChange = (e) => {
    const file = e.target.files[0];
    setSecondImagePreview(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSecondImageView(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSecondImageView(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    let coverImageURL = null;
    let secondImageURL = null;
    setSpinner(true);

    if (coverImagePreview) {
      try {
        const coverImageFormData = new FormData();
        coverImageFormData.append("files", coverImagePreview);
        const coverImageResponse = await axios.post(
          `${baseUrl}/upload_files`,
          coverImageFormData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        coverImageURL = coverImageResponse.data.data.files[0].path;
      } catch (error) {
        console.error("Error uploading cover image:", error);
      }
    }

    if (secondImagePreview) {
      try {
        const secondImageFormData = new FormData();
        secondImageFormData.append("files", secondImagePreview);
        const secondImageResponse = await axios.post(
          `${baseUrl}/upload_files`,
          secondImageFormData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        secondImageURL = secondImageResponse.data.data.files[0].path;
      } catch (error) {
        console.error("Error uploading second image:", error);
      }
    }

    const title = e.target.title.value.trim();
    if (!title) {
      toast.error("Title is required.");
      setSpinner(false);
      return;
    }

    const postFormData = {
      image_path: coverImageURL,
      icon_path: secondImageURL,
      title: e.target.title.value,
      subtitle: e.target.subtitle.value,
      heading: e.target.heading.value,
      description: e.target.description.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-multi-tabs`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success("Company Information added successfully:", {
        onClose: () => navigation("/abouttabs-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error adding Company Information:", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Add Company Details</h1>
              </div>
            </div>

            <div className="mb-4 form-field">
              <label htmlFor="secondImageUpload" className="custom-file-upload">
                Add Icon
              </label>
              <input
                id="secondImageUpload"
                type="file"
                className="form-control"
                onChange={handleSecondFileChange}
              />
            </div>
            {secondImageView && (
              <div className="mb-4 cover-image-view community-icon-viewer">
                <img src={secondImageView} alt="Second Image Preview" />
              </div>
            )}
            <div className="mb-4 form-field">
              <label htmlFor="coverImageUpload" className="custom-file-upload">
                Add Cover Image
              </label>
              <input
                id="coverImageUpload"
                type="file"
                className="form-control"
                onChange={handleCoverFileChange}
              />
            </div>
            {coverImageView && (
              <div className="mb-4 cover-image-view ">
                <img src={coverImageView} alt="Cover Preview" />
              </div>
            )}
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="Title here..."
                name="title"
              />
            </div>
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="subtitle here..."
                name="subtitle"
              />
            </div>
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="heading here..."
                name="heading"
              />
            </div>
            {/* <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="sub_heading here..."
                name="sub_heading"
              />
            </div> */}
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="Description here..."
                name="description"
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Add</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddAboutTabs;
