import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
const AddHomeFreedom = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);

  let navigation = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    setSpinner(true);
    const tab_title = e.target.tab_title.value.trim();
    const tab_content = e.target.tab_content.value.trim();

    if (!tab_title || !tab_content) {
      toast.error("All fields required");
      setSpinner(false);
      return;
    }
    const postFormData = {
      tab_title: e.target.tab_title.value,
      tab_content: e.target.tab_content.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-update-freedom`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success("Power Your Freedom Content added successfully:", {
        onClose: () => navigation("/homefreedom-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error adding Power Your Freedom content ", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            {" "}
            <div className="mb-4">
              <div className="form-heading">
                <h1>Add Power Your Freedom</h1>
              </div>
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="title here..."
                name="tab_title"
              />
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="description here..."
                name="tab_content"
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Add</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddHomeFreedom;
