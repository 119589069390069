import React from "react";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import PrivacyPolicy from "../components/setting/privacyPolicy";
const PrivacyIndex = () => {
  return (
    <>
      <SunAnimation />
      <PrivacyPolicy />
    </>
  );
};

export default PrivacyIndex;
