import React, { useState, useEffect } from "react";
import Banner from "../components/contact/banner/banner";
import Question from "../components/consulting/question/question";
import Office from "../components/contact/office/office";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import axios from "axios";
import Loader from "../components/common/loader/loader";

const Contact = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [contactData, setContactData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchQuestionData = async () => {
    try {
      console.log("*********   API CALL - get-question-data - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-question-data`);
      const data = response?.data?.data?.questions;
      setQuestionData(data);
      setSpinner(false);
    } catch (error) {
      console.log("*********   API CALL - get-question-data - Error ******** "+Date.now())
      console.error("Error fetching question data:", error);
      setSpinner(false);
    }
  };
  const fetchData = async () => {
    setSpinner(true);
    try {
      console.log("*********   API CALL - get-findus - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-findus`);
      setContactData(response.data.data.find_us);
      
    fetchQuestionData();
    } catch (error) {
      console.log("*********   API CALL - get-findus - Error ******** "+Date.now())
      console.error("Error fetching contact data:", error);
      setSpinner(false);
    }
  };
  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      <Banner contactData={contactData} />
      <Question questionData={questionData} />
      <Office />
    </>
  );
};

export default Contact;
