import React, { useState, useEffect } from "react";
import "./office.css";
import headingBorder from "../../../assets/images/mission-heading.png";
import OfficeCard from "./officeCard";
import axios from "axios";

const Office = () => {
  const [officeData, setOfficeData] = useState([]);
  const fetchOffices = async () => {
    try {
      const response = await axios.get(
        "https://sunchoice.us:1090/api/get-office-data"
      );
      setOfficeData(response.data.data.office_data);
    } catch (error) {
      console.log("error fetching data", error);
    }
  };
  useEffect(() => {
    fetchOffices();
  }, []);
  return (
    <div className="container-fluid office-section">
      <div className="container office-container">
        <div className="office-container-header">
          <h1>
            Our Offices{" "}
            <span>
              Explore SunChoice's office locations across the country,
              strategically positioned to serve businesses nationwide.
            </span>
            <img src={headingBorder} alt="" />
          </h1>
        </div>
        <div className="row pt-5">
          {officeData.map((data, index) => (
            <div key={index} className="col-lg-6 mb-3">
              <OfficeCard {...data} customClass="blogCardWhite" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Office;
