import React, { useEffect, useState } from "react";
import Banner from "../components/service/banner/banner";
import Benefit from "../components/service/benefit/benefit";
import Independence from "../components/service/independence/independence";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import Business from "../components/service/business/business";
import Freedom from "../components/service/freedom/freedom";
import Blog from "../components/common/blogSection/blog";
import Loader from "../components/common/loader/loader";
import axios from "axios";
import checkIcon from "../assets/images/check-icon.png";
const Service = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const [spinner, setSpinner] = useState(false);
  const [businessSolarData, setBusinessSolarData] = useState([]);
  const [solarWorkData, setSolarWorkData] = useState([]);
  const [embraceFreedomData, setEmbraceFreedomData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setSpinner(true);
    try {
      console.log("*********   API CALL - get-business-solar-owner - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-business-solar-owner`);
      const data = response?.data?.data?.business_solar_owner;
      const transformedData = data.map((item) => ({
        ...item,
        image: imgUrl + item.image,
      }));
      setBusinessSolarData(transformedData);
      fetchSolarWorkData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching business solar data:", error);
    }
  };
  const fetchSolarWorkData = async () => {
    try {
      console.log("*********   API CALL - get-service-work - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-service-work`);
      const data = response?.data?.data?.service_work;
      setSolarWorkData(data);
      fetchEmbraceFreedomData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching business solar data:", error);
    }
  };
  const fetchEmbraceFreedomData = async () => {
    try {
      console.log("*********   API CALL - get-aims-card - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-aims-card`);
      const data = response?.data?.data?.aims_cards;
      const missionAimCard = data.filter((data) => data.type === "freedom");
      const transformedData = missionAimCard.map((item) => ({
        ...item,
        mission_items: item.mission_items ? JSON.parse(item.mission_items) : [],
        buttonContent: "Get started",
        icon: checkIcon,
      }));
      setEmbraceFreedomData(transformedData);
      setSpinner(false);
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching embrace freedom data:", error);
      setSpinner(false);
    }
  };
  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      <Banner solarWorkData={solarWorkData} />
      <Benefit />
      <Independence />
      <Business businessSolarData={businessSolarData} />
      <Freedom embraceFreedomData={embraceFreedomData} />
      <Blog className="blog-section-whtie" />
    </>
  );
};

export default Service;
