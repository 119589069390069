import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import "./login.css";
import Logo from "../../../assets/images/logo-black.svg";
import UserIcon from "../../../assets/images/user-icon.png";
import PasswordIcon from "../../../assets/images/pasword-icon.png";
import Sun from "../../../assets/images/030.png";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
const SignIn = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  // loader
  const [spinner, setSpinner] = useState(false);
  let navigation = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [displayError, setDisplayError] = useState();
  const userchange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const handleSubmit = async (e) => {
    setSpinner(true);
    e.preventDefault();

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("email", formData.email);
      urlencoded.append("password", formData.password);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded.toString(),
        redirect: "follow",
      };

      const response = await fetch(`${baseUrl}/admin-login`, requestOptions);

      const data = await response.json();

      localStorage.setItem("token", data.data.token);
      setSpinner(false);
      toast.success("Login successfully!", {
        onClose: () => navigation("/location"),
      });
      setDisplayError(null);
    } catch (error) {
      console.log(error);
      setSpinner(false);
      setDisplayError("Login failed. Please check your email and password.");
      navigation("/admin");
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <div className="container-fluid auth-section">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="auth-section-banner">
          <img src={Sun} alt="" />
        </div>
        <div className="auth-section-form">
          <div className="auth-section-form-content">
            <div className="auth-logo">
              <img src={Logo} alt="logo" />
              <p>Enter your Email and Password for login to Sun Choice</p>
            </div>
            <form>
              <div className="mb-4">
                <div className="form-heading">
                  <h1>Sign In</h1>
                </div>
              </div>
              {displayError && (
                <div class="alert alert-danger" role="alert">
                  {displayError}
                </div>
              )}
              <div className="mb-4 form-field form-field-icon">
                <input
                  type="email"
                  className="form-control"
                  placeholder="User Name"
                  name="email"
                  value={formData.email}
                  onChange={userchange}
                />
                <img className="icon-left" src={UserIcon} alt="logo" />
              </div>
              <div className="mb-4 form-field form-field-icon">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  onChange={userchange}
                  value={formData.password}
                />
                <img className="icon-left" src={PasswordIcon} alt="logo" />
              </div>

              <div className="form-field pt-4">
                <button type="submit" onClick={handleSubmit}>
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
