import React from "react";
import "./banner.css";

const Banner = (props) => {
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const bgImage = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("${
      imgUrl + props.cover_image
    }")`,
  };
  return (
    <div
      class="container-fluid blog-detail-section top-wrapper"
      style={bgImage}
    >
      <div class="container blog-detail-container">
        <div class="blog-detail-container-content">
          <div class="blog-tags-wrapper">
            <label className="active">{props.type}</label>
            <label className="">{props.reading_time}</label>
          </div>
          <h1>
            {props.title} <span>{props.subtitle}</span>
          </h1>
          <p>{props.concise_summary}</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
