import React from "react";
import SolarSystem from "../components/solarSystem/banner/solarSystem";
import SolarForm from "../components/solarSystem/solarForm/solarForm";
import SunAnimation from "../components/layout/sun-animation/sunanimation";

const solar = () => {
  return (
    <>
      <SunAnimation />
      <SolarSystem />
      <SolarForm />
    </>
  );
};

export default solar;
