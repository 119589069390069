import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
const AddContact = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);

  let navigation = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    setSpinner(true);
    const email = e.target.email.value.trim();
    const phone_number = e.target.phone_number.value.trim();
    const address = e.target.address.value.trim();

    if (!email && !phone_number && !address) {
      toast.error(
        "At least one of email, phone number, or address must be entered."
      );
      setSpinner(false);
      return;
    }
    const postFormData = {
      email: e.target.email.value,
      phone_number: e.target.phone_number.value,
      address: e.target.address.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-update-findus`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success("Contact added successfully:", {
        onClose: () => navigation("/contact-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error adding contact ", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            {" "}
            <div className="mb-4">
              <div className="form-heading">
                <h1>Add Contact Detail</h1>
              </div>
            </div>
            <div className="mb-4 form-field ">
              <input
                type="email"
                className="form-control"
                placeholder="email here..."
                name="email"
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="phone here..."
                name="phone_number"
              />
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="address here..."
                name="address"
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Add</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddContact;
