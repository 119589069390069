import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const SolarRequestDetail = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();

  const [request, setRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-solar-request`, {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        });
        const data = response?.data?.data?.solar_request;
        const requestData = data.find((item) => item.id == id);
        setRequest(requestData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchRequest();
  }, [baseUrl, id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="admin-wrapper">
      <div className="container-fluid">
        <div className="container solar-form" id="solar-form">
          <div className="mb-4">
            <div className="form-heading text-center">
              <h1>Solar Request Detail</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.name}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Address</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.address}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.phone_number}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.email}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Monthly Electricity Bill</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.monthly_bill}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Roof Condition</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.roof_condition}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Roof Space (sq. ft. or sq. m).</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.roof_space}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Roof Orientation</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.roof_orientation}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Installation Budget</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.installation_budget}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Installation Timeline</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.installation_timeline}
                  readOnly
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3  form-field-solar">
                <label>Status</label>
                <input
                  type="text"
                  className="form-control"
                  value={request.status}
                  readOnly
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3  form-field-solar">
                <label>Requirements</label>
                <textarea
                  className="form-control"
                  value={request.description}
                  readOnly
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolarRequestDetail;
