import React from "react";
import "./benefit.css";
import benefitBar from "../../../assets/images/benefit-bar.png";
import benefitRectangle from "../../../assets/images/benefit-rectangle.png";
import { NavLink } from "react-router-dom";
const Benefit = () => {
  return (
    <div className="container-fluid benefit-section">
      <div className="container benefit-container">
        <div className="benefit-container-left">
          <h1>
            <span>Financial Benefits:</span>
            Unlock the Power of Savings
          </h1>
          <p>
            Welcome to the heart of Solar as a Service, where financial
            empowerment meets sustainable energy. Discover the compelling
            financial advantages that come with embracing solar solutions for
            your business.
          </p>
          <NavLink to="/solar-system">Get started </NavLink>
        </div>
        <div className="benefit-container-right">
          <img className="rectangle-back" src={benefitRectangle} alt="" />
          <div className="benefit-container-right-content">
            <div className="bar-card">
              <h1>
                <svg
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.42418 0.712937L0.824179 5.8039C0.678453 5.94106 0.597817 6.12477 0.59964 6.31546C0.601462 6.50616 0.685598 6.68857 0.833924 6.82341C0.982251 6.95825 1.1829 7.03474 1.39266 7.0364C1.60242 7.03806 1.8045 6.96475 1.95538 6.83227L6.18978 2.98278L6.18978 15.7727C6.18978 15.9656 6.27406 16.1506 6.42409 16.287C6.57412 16.4234 6.77761 16.5 6.98978 16.5C7.20195 16.5 7.40544 16.4234 7.55546 16.287C7.70549 16.1506 7.78978 15.9656 7.78978 15.7727L7.78978 2.98278L12.0242 6.83227C12.098 6.90173 12.1863 6.95714 12.2839 6.99525C12.3815 7.03337 12.4864 7.05343 12.5927 7.05427C12.6989 7.05511 12.8042 7.03671 12.9025 7.00014C13.0009 6.96357 13.0902 6.90957 13.1653 6.84128C13.2404 6.773 13.2998 6.6918 13.34 6.60242C13.3803 6.51304 13.4005 6.41727 13.3996 6.3207C13.3987 6.22413 13.3766 6.1287 13.3347 6.03997C13.2927 5.95124 13.2318 5.87099 13.1554 5.8039L7.55538 0.712937C7.40536 0.576593 7.20191 0.5 6.98978 0.5C6.77765 0.5 6.5742 0.576593 6.42418 0.712937Z"
                    fill="#090A0A"
                  />
                </svg>
                30%
              </h1>
              <img src={benefitBar} alt="" />
              <h1>Tax Credit</h1>
            </div>
            <div className="bar-card">
              <h1>
                <svg
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.42418 0.712937L0.824179 5.8039C0.678453 5.94106 0.597817 6.12477 0.59964 6.31546C0.601462 6.50616 0.685598 6.68857 0.833924 6.82341C0.982251 6.95825 1.1829 7.03474 1.39266 7.0364C1.60242 7.03806 1.8045 6.96475 1.95538 6.83227L6.18978 2.98278L6.18978 15.7727C6.18978 15.9656 6.27406 16.1506 6.42409 16.287C6.57412 16.4234 6.77761 16.5 6.98978 16.5C7.20195 16.5 7.40544 16.4234 7.55546 16.287C7.70549 16.1506 7.78978 15.9656 7.78978 15.7727L7.78978 2.98278L12.0242 6.83227C12.098 6.90173 12.1863 6.95714 12.2839 6.99525C12.3815 7.03337 12.4864 7.05343 12.5927 7.05427C12.6989 7.05511 12.8042 7.03671 12.9025 7.00014C13.0009 6.96357 13.0902 6.90957 13.1653 6.84128C13.2404 6.773 13.2998 6.6918 13.34 6.60242C13.3803 6.51304 13.4005 6.41727 13.3996 6.3207C13.3987 6.22413 13.3766 6.1287 13.3347 6.03997C13.2927 5.95124 13.2318 5.87099 13.1554 5.8039L7.55538 0.712937C7.40536 0.576593 7.20191 0.5 6.98978 0.5C6.77765 0.5 6.5742 0.576593 6.42418 0.712937Z"
                    fill="#090A0A"
                  />
                </svg>
                58%
              </h1>
              <img src={benefitBar} alt="" />
              <h1>Grants</h1>
            </div>
            <div className="bar-card">
              <h1>
                <svg
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.42418 0.712937L0.824179 5.8039C0.678453 5.94106 0.597817 6.12477 0.59964 6.31546C0.601462 6.50616 0.685598 6.68857 0.833924 6.82341C0.982251 6.95825 1.1829 7.03474 1.39266 7.0364C1.60242 7.03806 1.8045 6.96475 1.95538 6.83227L6.18978 2.98278L6.18978 15.7727C6.18978 15.9656 6.27406 16.1506 6.42409 16.287C6.57412 16.4234 6.77761 16.5 6.98978 16.5C7.20195 16.5 7.40544 16.4234 7.55546 16.287C7.70549 16.1506 7.78978 15.9656 7.78978 15.7727L7.78978 2.98278L12.0242 6.83227C12.098 6.90173 12.1863 6.95714 12.2839 6.99525C12.3815 7.03337 12.4864 7.05343 12.5927 7.05427C12.6989 7.05511 12.8042 7.03671 12.9025 7.00014C13.0009 6.96357 13.0902 6.90957 13.1653 6.84128C13.2404 6.773 13.2998 6.6918 13.34 6.60242C13.3803 6.51304 13.4005 6.41727 13.3996 6.3207C13.3987 6.22413 13.3766 6.1287 13.3347 6.03997C13.2927 5.95124 13.2318 5.87099 13.1554 5.8039L7.55538 0.712937C7.40536 0.576593 7.20191 0.5 6.98978 0.5C6.77765 0.5 6.5742 0.576593 6.42418 0.712937Z"
                    fill="#090A0A"
                  />
                </svg>
                15%
              </h1>
              <img src={benefitBar} alt="" />
              <h1>High Returns</h1>
            </div>
          </div>
          <div className="benefit-key-value">
            <h1>Key Points</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefit;
