import React, { useState } from "react";
import "./multitab.css";
import MultitabContent from "./multitabContent";
import tabContentData from "./multitabData";

const Multitab = ({ companyData }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="container multitab-wrapper">
      <div className="multitab-tab-content">
        {companyData.map(
          (tab, index) =>
            activeTab === index && <MultitabContent key={index} {...tab} />
        )}
      </div>
      <div className="container-fluid multitab-tab-section py-4">
        <div className="container multitab-tabs">
          {companyData.map((_, index) => (
            <div
              key={index}
              className={`tab-${index + 1} tabs ${
                activeTab === index ? "active" : ""
              } ${activeTab > index ? "old-active" : ""}`}
              onClick={() => handleTabClick(index)}
            >
              <div className="tab-toggle">{`Tab ${index + 1}`}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Multitab;
