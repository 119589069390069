import React, { useEffect } from "react";
import "./map.css";
import ArrowLeft from "../../../assets/images/arrow-left.png";
import ArrowRight from "../../../assets/images/arrow-right.png";
import RealMap from "../realMap/realMap";
import AOS from "aos";
import "aos/dist/aos.css";
const Map = ({ mapData }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
  const sumAmount = mapData?.reduce(
    (acc, item) => acc + (parseFloat(item.amount) || 0),
    0
  );
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(sumAmount);

  const grantApplicant = mapData.filter((circle) => circle.type === "grant");
  const totalGrantApplicants = grantApplicant.length;
  const awardApplicant = mapData.filter(
    (circle) => circle.type === "awardgrant"
  );
  const totalAwardApplicants = awardApplicant.length;
  return (
    <>
      <div className="container-fluid map-section mt-5">
        <div className="container map-container">
          <div
            className="map-container-header animated-element"
            data-aos="fade-up"
          >
            <h1>
              {" "}
              Explore Our Reach
              <span>
                Explore our interactive map to see how SunChoice is making a
                difference across the nation. Click on each state for details on
                our services, incentives, and success stories. Our Client Impact
                Map showcases the locations of businesses we've assisted,
                highlighting the growing network taking control of their energy
                future.
              </span>
              <span className="map-subheading">
                <img src={ArrowLeft} alt="" /> View our Nationwide Impact Map{" "}
                <img src={ArrowRight} alt="" />
              </span>
            </h1>
          </div>
          <div className="map-container-body pt-5">
            <div
              className="map-content-left animated-element"
              data-aos="fade-down"
            >
              <h1 className="map-content-heading">Client Impact:</h1>
              <div className="impact-card ">
                <h2>
                  <div className="grant-div"></div>Total Submitted Grant
                  Requests
                </h2>
                <h1 title={totalGrantApplicants}>
                  {totalGrantApplicants} <span>clients</span>
                </h1>
              </div>
              <div className="impact-card">
                <h2>
                  <div className="independent-div"></div>Total Grant Dollar
                  Awarded
                </h2>
                <h1 title={formattedAmount}>{formattedAmount}</h1>
              </div>
            </div>
            <div
              className="map-content-right animated-element"
              data-aos="fade-up"
            >
              <div className="map-box-wrapper">
                <div className="map-box">
                  <RealMap mapData={mapData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Map;
