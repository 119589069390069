import React from "react";
import TermCondition from "../components/setting/termCondition";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
const TermIndex = () => {
  return (
    <>
      <SunAnimation />
      <TermCondition />
    </>
  );
};

export default TermIndex;
