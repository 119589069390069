import React, { useState, useEffect } from "react";
import { navbarData } from "./navbarData";
import { NavLink } from "react-router-dom";
import InstagramIcon from "../../../assets/images/insta-icon-white.png";
import FacebookIcon from "../../../assets/images/facebook-icon-white.svg";
import TwitterIcon from "../../../assets/images/twitter-icon-white.png";
import LinkedInIcon from "../../../assets/images/linkedin-icon-white.png";
import axios from "axios";
const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isServicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { logo, menuToggle, navbarLinks } = navbarData;
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleServicesMenu = (event) => {
    if (event.target.id === "services-dropdown") {
      event.preventDefault();
      setServicesMenuOpen(!isServicesMenuOpen);
    }
  };

  const closeMenuOnClick = () => {
    setMenuOpen(false);
    setServicesMenuOpen(false);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isServicesMenuOpen &&
        !event.target.closest(".services-dropdown-wrapper")
      ) {
        setServicesMenuOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isServicesMenuOpen]);
  const logoToShow = windowWidth < 767 ? logo.imageSrcTwo : logo.imageSrc;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [socialLinks, setSocialLinks] = useState([]);
  const fetchSocialLinks = async () => {
    try {
      const response = await axios.get(`${baseUrl}/get-social-link`);
      const links = response.data.data.social_links;
      setSocialLinks(links);
    } catch (error) {
      console.error("Error fetching social links:", error);
    }
  };
  useEffect(() => {
    fetchSocialLinks();
  }, []);
  return (
    <>
      <div className="navbar-wrapper">
        <div className="navbar-logo">
          <NavLink to={logo.link}>
            <img src={logo.imageSrcTwo} alt={logo.altText} />
          </NavLink>
        </div>
        <div className="menu-toggle-wrapper" onClick={toggleMenu}>
          <img
            id="open-menu"
            src={
              isMenuOpen ? menuToggle.closeImageSrc : menuToggle.openImageSrc
            }
            alt={menuToggle.altText}
          />
        </div>
        <div className={`navbar-links ${isMenuOpen ? "open" : ""}`}>
          <ul>
            {navbarLinks.map((link, index) => (
              <li key={index} className={link.className}>
                {link.submenu ? (
                  <div className="services-dropdown-wrapper">
                    <NavLink
                      to={link.to}
                      onClick={link.onClick}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      {link.text}{" "}
                      <img
                        id="services-dropdown"
                        src={link.imageSrc}
                        alt=""
                        onClick={toggleServicesMenu}
                      />
                    </NavLink>
                    <ul
                      className={`services-submenu ${
                        isServicesMenuOpen ? "" : "close"
                      }`}
                    >
                      {link.submenu.map((sublink, subIndex) => (
                        <li key={subIndex}>
                          <NavLink
                            to={sublink.to}
                            onClick={closeMenuOnClick}
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            {sublink.text}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <NavLink
                    to={link.to}
                    onClick={link.onClick}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    {link.text}
                  </NavLink>
                )}
              </li>
            ))}
            <li className="navbar-social-icon-wrapper">
              <ul>
                {socialLinks.map((link, index) => (
                  <li className="navbar-nav-social-icon" key={index}>
                    <a href={link.social_link} target="_blank">
                      {link.social_type === "instagram" && (
                        <div>
                          <img src={InstagramIcon} alt="" />
                        </div>
                      )}
                      {link.social_type === "facebook" && (
                        <div>
                          <img src={FacebookIcon} alt="" />
                        </div>
                      )}
                      {link.social_type === "twitter" && (
                        <div>
                          <img src={TwitterIcon} alt="" />
                        </div>
                      )}
                      {link.social_type === "linkedin" && (
                        <div>
                          <img src={LinkedInIcon} alt="" />
                        </div>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
