import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";

const AddQuestion = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  let navigation = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });

  useEffect(() => {
    if (id) {
      fetchLocationData(id);
    }
  }, [id]);

  const fetchLocationData = async (id) => {
    setSpinner(true);
    try {
      const response = await axios.get(`${baseUrl}/get-question-data`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = response.data.data.questions;

      const location = data.find((item) => item.id == id);
      setSpinner(false);
      setFormData(location);
    } catch (error) {
      setSpinner(false);
      toast.error("Failed to fetch location data. Please try again later.");
    }
  };

  const [displayError, setDisplayError] = useState();
  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const validateForm = () => {
    const { question, answer } = formData;
    if (!question || !answer) {
      setDisplayError("All fields are required.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSpinner(true);
      try {
        const requestData = { ...formData };
        if (id) {
          requestData.id = id;
        }
        const response = await axios.post(
          `${baseUrl}/add-update-question-data`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        setSpinner(false);
        if (response.status === 200) {
          toast.success(
            id
              ? "Question updated successfully!"
              : "Question added successfully!",
            {
              onClose: () => navigation("/question-list"),
            }
          );
          setDisplayError(null);
        } else {
          throw new Error("Failed to add or update question.");
        }
      } catch (error) {
        setSpinner(false);
        toast.error(
          `Failed to ${id ? "update" : "add"} question. ${error.message}`
        );
      }
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <div className="admin-wrapper">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>{id ? "Edit Question" : "Add Question"}</h1>
              </div>
            </div>
            {displayError && (
              <div className="alert alert-danger" role="alert">
                {displayError}
              </div>
            )}
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Question here..."
                name="question"
                value={formData.question}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Answer here..."
                name="answer"
                value={formData.answer}
                onChange={handleChange}
              />
            </div>

            <div className="form-field pt-4">
              <button type="submit">{id ? "Update" : "Add"}</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddQuestion;
