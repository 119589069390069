import React, { useEffect } from "react";
import borerbottom from "../../../assets/images/mission-heading.png";
import cardicon from "../../../assets/images/card-icon.png";
import { missionData } from "./missionValueData";
import AOS from "aos";
import "aos/dist/aos.css";

import "./MissionValue.css";
import CardCommon from "../../common/cardCommom/cardCommon";

const MissionValue = ({ missionData }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
  return (
    <div className="container-fluid mission-value-section  ">
      <div
        className="container mission-value-container animated-element"
        data-aos="fade-down"
      >
        <div className="mission-value-container-header">
          <h1>
            Our Mission and Values
            <img src={borerbottom} alt="Missing image" />
          </h1>
        </div>
        <div className="mission-value-container-body">
          <div className="row pt-5">
            {missionData && missionData.length > 0 ? (
              missionData?.map((mission, index) => (
                <div className="col-md-6 col-lg-4 mb-4" key={index}>
                  <CardCommon
                    {...mission}
                    customClass={"mission-card-common"}
                  />
                </div>
              ))
            ) : (
              <p className="text-center">No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionValue;
