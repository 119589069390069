import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
import "./plan.css";

const AddEmbraceFreedom = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryInput, setCategoryInput] = useState("");
  let navigation = useNavigate();

  const handleAddCategory = () => {
    if (categoryInput.trim() !== "") {
      setCategories([...categories, categoryInput.trim()]);
      setCategoryInput("");
    }
  };

  const handleRemoveCategory = (index) => {
    setCategories(categories.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    setSpinner(true);
    const title = e.target.title.value.trim();
    const description_title = e.target.description_title.value.trim();
    const description_content = e.target.description_content.value.trim();
    const type = e.target.type.value.trim();
    if (
      !title ||
      !description_title ||
      !description_content ||
      categories.length === 0
    ) {
      toast.error("All fields required, including at least one category");
      setSpinner(false);
      return;
    }

    const postFormData = {
      title,
      description_title,
      description_content,
      type,
      mission_items: categories,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-aims-card`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success("New Embrace Freedom added successfully", {
        onClose: () => navigation("/embracefreedom-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error adding Embrace Freedom ", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Add New Embrace Freedom Section</h1>
              </div>
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="heading here..."
                name="title"
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="title here..."
                name="description_title"
              />
            </div>
            <div className="mb-4 form-field ">
              <select className="form-control" name="type">
                <option value="">Select Type...</option>
                <option value="mission">Mission</option>
                <option value="freedom">Freedom</option>
              </select>
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="short description here..."
                name="description_content"
              ></textarea>
            </div>
            <div className="mb-4 form-field">
              <div className="category-wrapper">
                {categories.map((category, index) => (
                  <div key={index} className="category-list">
                    <h1>{category}</h1>
                    <button
                      type="button"
                      onClick={() => handleRemoveCategory(index)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
              <div className="add-category">
                <input
                  type="text"
                  className="form-control"
                  placeholder="category here..."
                  value={categoryInput}
                  onChange={(e) => setCategoryInput(e.target.value)}
                />
                <button type="button" onClick={handleAddCategory}>
                  Add
                </button>
              </div>
            </div>

            <div className="form-field pt-4">
              <button type="submit">Add</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEmbraceFreedom;
