import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";

const EditAboutTabs = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [coverImageView, setCoverImageView] = useState(null);
  const [oldCoverImage, setOldCoverImage] = useState(null);
  const [secondImagePreview, setSecondImagePreview] = useState(null);
  const [secondImageView, setSecondImageView] = useState(null);
  const [oldSecondImage, setOldSecondImage] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    heading: "",
    description: "",
  });
  let navigation = useNavigate();

  useEffect(() => {
    const fetchAboutMultiTab = async (id) => {
      setSpinner(true);
      try {
        const response = await axios.get(`${baseUrl}/get-multi-tabs`);
        const aboutMultiTabData = response?.data?.data?.multi_tabs;
        setSpinner(false);
        const aboutMultiTab = aboutMultiTabData.find(
          (service) => service.id == id
        );
        setFormData({
          title: aboutMultiTab.title,
          description: aboutMultiTab.description,
          subtitle: aboutMultiTab.subtitle,
          heading: aboutMultiTab.heading,
        });
        setOldCoverImage(aboutMultiTab.image_path);
        setCoverImageView(imgUrl + aboutMultiTab.image_path);
        setOldSecondImage(aboutMultiTab.icon_path);
        setSecondImageView(imgUrl + aboutMultiTab.icon_path);
      } catch (error) {
        setSpinner(false);
        console.error("Error fetching company details", error);
        toast.error("Failed to fetch Company Details. Please try again later.");
      }
    };

    fetchAboutMultiTab(id);
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCoverFileChange = (e) => {
    const file = e.target.files[0];
    setCoverImagePreview(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImageView(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setCoverImageView(null);
    }
  };

  const handleSecondFileChange = (e) => {
    const file = e.target.files[0];
    setSecondImagePreview(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSecondImageView(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSecondImageView(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    let coverImageURL = null;
    let secondImageURL = null;
    setSpinner(true);

    if (coverImagePreview) {
      try {
        const coverImageFormData = new FormData();
        coverImageFormData.append("files", coverImagePreview);
        const coverImageResponse = await axios.post(
          `${baseUrl}/upload_files`,
          coverImageFormData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        coverImageURL = coverImageResponse.data.data.files[0].path;
      } catch (error) {
        console.error("Error uploading cover image:", error);
      }
    } else {
      coverImageURL = oldCoverImage;
    }

    if (secondImagePreview) {
      try {
        const secondImageFormData = new FormData();
        secondImageFormData.append("files", secondImagePreview);
        const secondImageResponse = await axios.post(
          `${baseUrl}/upload_files`,
          secondImageFormData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        secondImageURL = secondImageResponse.data.data.files[0].path;
      } catch (error) {
        console.error("Error uploading second image:", error);
      }
    } else {
      secondImageURL = oldSecondImage;
    }

    const title = e.target.title.value.trim();
    if (!title) {
      toast.error("Title is required.");
      setSpinner(false);
      return;
    }
    const postFormData = {
      id: id,
      image_path: coverImageURL,
      icon_path: secondImageURL,
      title: e.target.title.value,
      subtitle: e.target.subtitle.value,
      heading: e.target.heading.value,
      description: e.target.description.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-multi-tabs`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Company Details Updated successfully:", {
        onClose: () => navigation("/abouttabs-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error editing Company Details", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Update Company Details</h1>
              </div>
            </div>
            <div className="mb-4 form-field">
              <label htmlFor="secondImageUpload" className="custom-file-upload">
                Select Second Image
              </label>
              <input
                id="secondImageUpload"
                type="file"
                className="form-control"
                onChange={handleSecondFileChange}
              />
            </div>
            {secondImageView && (
              <div className="mb-4 cover-image-view community-icon-viewer">
                <img src={secondImageView} alt="Second Image Preview" />
              </div>
            )}
            <div className="mb-4 form-field">
              <label htmlFor="coverImageUpload" className="custom-file-upload">
                Select Cover Image
              </label>
              <input
                id="coverImageUpload"
                type="file"
                className="form-control"
                onChange={handleCoverFileChange}
              />
            </div>
            {coverImageView && (
              <div className="mb-4 cover-image-view ">
                <img src={coverImageView} alt="Cover Preview" />
              </div>
            )}

            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="Title here..."
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="Subtitle here..."
                name="subtitle"
                value={formData.subtitle}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="Heading here..."
                name="heading"
                value={formData.heading}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="Description here..."
                name="description"
                value={formData.description}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditAboutTabs;
