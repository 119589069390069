import React from "react";
import "./pv.css";
import headingBorder from "../../../assets/images/heading-border-larger.png";
import CardCommon from "../../common/cardCommom/cardCommon";

const Pv = ({ workData }) => {
  return (
    <div className="container-fluid pv-section ">
      <div className="container pv-container">
        <div className="pv-container-header pb-4">
          <h1>
            How PV Solar Works
            <img src={headingBorder} alt="" />
          </h1>
        </div>
        <div className="pv-container-body pt-5">
          <div className="row">
            {workData.map((data, index) => (
              <div key={index} className="col-md-6 col-lg-4 mb-4">
                <CardCommon
                  {...data}
                  index={index + 1}
                  customClass="pv-card-common"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pv;
