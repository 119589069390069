import React from "react";
import "./pioneer.css";
import pioneerRectangle from "../../../assets/images/pioneer-rectangle.png";
import teamImageOne from "../../../assets/images/team-image-one.jpg";
import arrowUp from "../../../assets/images/arrow-up.png";

const Pioneer = () => {
  return (
    <div className="container-fluid pioneer-section">
      <div className="container pioneer-container">
        <div className="pioneer-container-header">
          <h1>
            Become a SunChoice Pioneer
            <span>
              Join Our Team Committed to Solar Excellence. Explore
              Opportunities: Positions Aligned with Our Core Values and Mission.
            </span>
          </h1>
        </div>
        <div className="pioneer-container-body pt-5">
          <div className="pioneer-container-body-left">
            <div className="pioneer-container-body-rectangle-wrapper">
              <img className="rectangle-back" src={pioneerRectangle} alt="" />
              <h1>
                William Ferrell
                <span> Founder & CEO</span>
              </h1>
            </div>

            <img className="profile-img" src={teamImageOne} alt="" />
          </div>
          <div className="pioneer-container-body-right">
            <div className="pioneer-container-body-right-header">
              <h1>
                Joining SunChoice means becoming part of a dynamic team
                committed to revolutionizing the solar industry and empowering
                communities. As the founder, I invite you to contribute to our
                vision of sustainability, innovation, and positive change.
              </h1>
            </div>
            <div className="pioneer-container-body-right-body pt-5">
              <div className="pionner-value-card">
                <h1>20 years</h1>
                <span>Collaborative expertise in the solar industry</span>
              </div>
              <div className="pionner-value-card">
                <h1>
                  <img src={arrowUp} alt="" />8
                </h1>
                <span>Serving states</span>
              </div>
              <div className="pionner-value-card">
                <h1>
                  <img src={arrowUp} alt="" />
                  92
                </h1>
                <span>Assisted Clients</span>
              </div>
              <div className="pionner-value-card">
                <h1>
                  <img src={arrowUp} alt="" /> 85%
                </h1>
                <span>Grant Award Success Rate</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pioneer;
