import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import gifLoader from "../../assets/images/desktop-loader.mp4";
import gifLoader_Mobile from "../../assets/images/loader_mobile_2.mp4";
const PageLoader = ({ onPageLoader }) => {
  const videoEnded = () => {
    onPageLoader();
  };
  return (
    <div className="page-loader">
      <video autoPlay muted playsInline onEnded={videoEnded}>
        <source
          src={isMobile ? gifLoader_Mobile : gifLoader}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default PageLoader;
