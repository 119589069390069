import React from "react";
import "./feedback.css";
import { NavLink } from "react-router-dom";
const Feedback = ({ feedbackData }) => {
  const totalFeedbacks = feedbackData.length;
  const halfIndex = Math.floor(totalFeedbacks / 2);
  const firstHalf = feedbackData.slice(0, halfIndex);
  const secondHalf = feedbackData.slice(halfIndex);
  return (
    <div class="container-fluid feedback-section">
      <div class="container feedback-container px-sm-0">
        <div class="feedback-container-left">
          <div class="feedback-header">
            <h1>Our Clients Speak</h1>
          </div>
          <div class="feedback-left-body">
            <h1>Real Stories of Empowerment Through Solar Solutions</h1>
            <p>
              At SunChoice, the true measure of our success is found in the
              stories shared by our valued clients. These testimonials
              encapsulate more than just the successful installation of solar
              panels; they are narratives of businesses experiencing
              transformation, cost savings, and a newfound sense of energy
              independence.
            </p>
            <NavLink to="/solar-system" class="mt-4">
              Get started
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66667 0C9.41667 0 9.25 0.083333 9.08333 0.25L0.75 8.58333C0.416667 8.91667 0.416667 9.41667 0.75 9.75C1.08333 10.0833 1.58333 10.0833 1.91667 9.75L10.25 1.41667C10.5833 1.08333 10.5833 0.583333 10.25 0.25C10.0833 0.083333 9.91667 0 9.66667 0Z"
                  fill="#FEFEFE"
                ></path>
                <path
                  d="M9.66634 0.000325203H2.16634C1.66634 0.000325203 1.33301 0.333658 1.33301 0.833658C1.33301 1.33366 1.66634 1.66699 2.16634 1.66699H8.83301V8.33366C8.83301 8.83366 9.16634 9.16699 9.66634 9.16699C10.1663 9.16699 10.4997 8.83366 10.4997 8.33366V0.833658C10.4997 0.333658 10.1663 0.000325203 9.66634 0.000325203Z"
                  fill="#FEFEFE"
                ></path>
              </svg>
            </NavLink>
          </div>
        </div>
        <div class="feedback-container-right">
          <div class="feedback-wrapper">
            {firstHalf.map((data, index) => (
              <div key={index} class="feedback-card">
                <p>{data.review}</p>
                <div class="feedback-card-footer">
                  {data.reviewer_image ? (
                    <img src={data.reviewer_image} alt="" />
                  ) : (
                    <div className="reviewer-name">
                      {data.reviewer_name.charAt(0) || "--"}
                    </div>
                  )}
                  <h1>
                    {data.reviewer_name || "--"}
                    <span>{data.reviewer_designation || "--"}</span>
                  </h1>
                </div>
              </div>
            ))}
          </div>
          <div class="feedback-wrapper">
            {secondHalf.map((data, index) => (
              <div key={index} class="feedback-card">
                <p>{data.review}</p>
                <div class="feedback-card-footer">
                  {data.reviewer_image ? (
                    <img src={data.reviewer_image} alt="" />
                  ) : (
                    <div className="reviewer-name">
                      {data.reviewer_name.charAt(0) || "--"}
                    </div>
                  )}
                  <h1>
                    {data.reviewer_name || "--"}
                    <span>{data.reviewer_designation || "--"}</span>
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
