import React from "react";
import CurveCard from "../../common/curveCard/curvecard";

const Storage = ({ storageData }) => {
  return (
    <div className="container-fluid business-section mb-5">
      <div className="container business-container">
        <div className="common-heading">
          <h1>
            Battery Storage Systems
            <span>
              Uncover the versatility of battery storage systems in maximizing
              solar energy usage for businesses. From backup power during
              outages to load shifting and peak demand reduction, these systems
              offer comprehensive solutions for energy resilience and
              cost-effectiveness.
            </span>
          </h1>
        </div>
        <div className="business-container-body pt-5">
          <div className="row">
            {storageData.map((data, index) => (
              <div key={index} className="col-md-6  mb-3">
                <CurveCard {...data} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Storage;
