import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
import "./plan.css";

const EditEmbraceFreedom = () => {
  // const arrayList =
  //   '["Meaningful Impact","Innovative Environment","Personal and Professional Growth"]';

  // const jsonArray = arrayList ? JSON.parse(arrayList) : [];
  // console.log(jsonArray, "test");

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description_title: "",
    description_content: "",
    mission_items: [],
    type: "",
  });
  const [listInput, setListInput] = useState("");
  let navigate = useNavigate();
  console.log(formData.mission_items, "api");
  useEffect(() => {
    const fetchEmbraceFreedomData = async (id) => {
      setSpinner(true);
      try {
        const response = await axios.get(`${baseUrl}/get-aims-card`);
        const embraceFreedomData = response?.data?.data?.aims_cards;
        setSpinner(false);
        const embraceFreedom = embraceFreedomData.find((item) => item.id == id);
        const missionItems = embraceFreedom.mission_items
          ? JSON.parse(embraceFreedom.mission_items)
          : [];
        setFormData({
          title: embraceFreedom.title,
          description_title: embraceFreedom.description_title,
          description_content: embraceFreedom.description_content,
          type: embraceFreedom.type,
          mission_items: missionItems,
        });
      } catch (error) {
        setSpinner(false);
        console.error("Error fetching embrace freedom data:", error);
        toast.error(
          "Failed to fetch embrace freedom data. Please try again later."
        );
      }
    };

    fetchEmbraceFreedomData(id);
  }, [id]);

  const handleAddListItem = () => {
    if (listInput.trim() !== "") {
      setFormData({
        ...formData,
        mission_items: [...formData.mission_items, listInput.trim()],
      });
      setListInput("");
    }
  };

  const handleRemoveListItem = (index) => {
    setFormData({
      ...formData,
      mission_items: formData.mission_items.filter((_, i) => i !== index),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    setSpinner(true);
    const {
      title,
      description_title,
      description_content,
      mission_items,
      type,
    } = formData;

    if (!title || !description_content || mission_items.length === 0) {
      toast.error("All fields are required, including at least one list item");
      setSpinner(false);
      return;
    }

    const postFormData = {
      id: id,
      title: title,
      description_title: description_title,
      description_content: description_content,
      type: type,
      mission_items: mission_items,
    };

    try {
      await axios.post(`${baseUrl}/add-aims-card`, postFormData, {
        headers: {
          Authorization: token,
        },
      });
      toast.success("Embrace Freedom updated successfully", {
        onClose: () => navigate("/embracefreedom-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error editing embrace freedom", error);
      toast.error("Error updating embrace freedom content");
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Update Our Plan</h1>
              </div>
            </div>
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="heading here..."
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="title here..."
                name="description_title"
                value={formData.description_title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field">
              <select
                className="form-control"
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select Type...</option>
                <option value="mission">Mission</option>
                <option value="freedom">Freedom</option>
              </select>
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="description here..."
                name="description_content"
                value={formData.description_content}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="mb-4 form-field">
              <div className="category-wrapper">
                {formData.mission_items.map((item, index) => (
                  <div key={index} className="category-list">
                    <h1>{item}</h1>
                    <button
                      type="button"
                      onClick={() => handleRemoveListItem(index)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
              <div className="add-category">
                <input
                  type="text"
                  className="form-control"
                  placeholder="item here..."
                  value={listInput}
                  onChange={(e) => setListInput(e.target.value)}
                />
                <button type="button" onClick={handleAddListItem}>
                  Add
                </button>
              </div>
            </div>

            <div className="form-field pt-4">
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditEmbraceFreedom;
