import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import GrantIcon from "../../../assets/images/grant-icon.svg";
import AwardGrantIcon from "../../../assets/images/award-grant.svg";
import { NavLink } from "react-router-dom";
import mapStyles from "./mapStyle";

const containerStyle = {
  width: "100%",
  height: "600px",
  borderRadius: "15px",
};

function RealMap({ mapData }) {
  const googleKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const [center, setCenter] = useState(null);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const validMarkers = mapData
      .filter((marker) => marker.lat && marker.lng)
      .map((marker) => ({
        ...marker,
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      }));
    setMarkers(validMarkers);
    if (validMarkers.length > 0) {
      setCenter({ lat: validMarkers[0].lat, lng: validMarkers[0].lng });
    }
  }, [mapData]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
  });

  const initialZoom = 5;
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const onLoad = React.useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.setZoom(initialZoom);
      setMap(map);
    },
    [center]
  );

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={initialZoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        styles: mapStyles,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={{ lat: marker.lat, lng: marker.lng }}
          onClick={() => handleMarkerClick(marker)}
          icon={{
            url: marker.type === "grant" ? GrantIcon : AwardGrantIcon,
            scaledSize: new window.google.maps.Size(25, 25),
          }}
        />
      ))}
      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={handleCloseInfoWindow}
        >
          <div className="map-card">
            <div>
              {selectedMarker.type === "awardgrant" && (
                <h1>{selectedMarker.applicant}</h1>
              )}
              {selectedMarker.type === "grant" && (
                <h1>SunChoice in {selectedMarker.address}</h1>
              )}

              {selectedMarker.type === "awardgrant" && (
                <div>
                  <h2>Address:</h2>
                  <ul>
                    <li>
                      City:
                      <span> {selectedMarker.city}</span>
                    </li>
                    <li>
                      State:
                      <span> {selectedMarker.state}</span>
                    </li>
                    <li>
                      Zip Code:
                      <span> {selectedMarker.zipCode}</span>
                    </li>
                  </ul>
                </div>
              )}

              <h2>Client Impact:</h2>
              <ul className="client-list">
                {selectedMarker.type === "awardgrant" ? (
                  <li>
                    <span>
                      <div className="independent-div"></div> Total Grant Dollar
                      Awarded
                    </span>
                    <span>
                      {" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(selectedMarker.amount)}
                    </span>
                  </li>
                ) : (
                  <li>
                    <span>
                      <div className="grant-div"></div>Grant Applicants
                    </span>
                    <span>{selectedMarker.applicant}</span>
                  </li>
                )}
              </ul>
              {selectedMarker.type === "grant" ? (
                <NavLink>
                  Learn More
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.16667 0.5C8.91667 0.5 8.75 0.583333 8.58333 0.75L0.25 9.08333C-0.0833333 9.41667 -0.0833333 9.91667 0.25 10.25C0.583333 10.5833 1.08333 10.5833 1.41667 10.25L9.75 1.91667C10.0833 1.58333 10.0833 1.08333 9.75 0.75C9.58333 0.583333 9.41667 0.5 9.16667 0.5Z"
                      fill="#007BFF"
                    />
                    <path
                      d="M9.16634 0.500325H1.66634C1.16634 0.500325 0.833008 0.833658 0.833008 1.33366C0.833008 1.83366 1.16634 2.16699 1.66634 2.16699H8.33301V8.83366C8.33301 9.33366 8.66634 9.66699 9.16634 9.66699C9.66634 9.66699 9.99967 9.33366 9.99967 8.83366V1.33366C9.99967 0.833658 9.66634 0.500325 9.16634 0.500325Z"
                      fill="#007BFF"
                    />
                  </svg>
                </NavLink>
              ) : (
                <NavLink>
                  See Blog
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.16667 0.5C8.91667 0.5 8.75 0.583333 8.58333 0.75L0.25 9.08333C-0.0833333 9.41667 -0.0833333 9.91667 0.25 10.25C0.583333 10.5833 1.08333 10.5833 1.41667 10.25L9.75 1.91667C10.0833 1.58333 10.0833 1.08333 9.75 0.75C9.58333 0.583333 9.41667 0.5 9.16667 0.5Z"
                      fill="#007BFF"
                    />
                    <path
                      d="M9.16634 0.500325H1.66634C1.16634 0.500325 0.833008 0.833658 0.833008 1.33366C0.833008 1.83366 1.16634 2.16699 1.66634 2.16699H8.33301V8.83366C8.33301 9.33366 8.66634 9.66699 9.16634 9.66699C9.66634 9.66699 9.99967 9.33366 9.99967 8.83366V1.33366C9.99967 0.833658 9.66634 0.500325 9.16634 0.500325Z"
                      fill="#007BFF"
                    />
                  </svg>
                </NavLink>
              )}
            </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default RealMap;
