import React from "react";
import "./reap.css";
import CardCommon from "../../common/cardCommom/cardCommon";
import headingBorder from "../../../assets/images/heading-border-larger.png";

const Reap = ({ reapData }) => {
  return (
    <div className="container-fluid plan-section mb-5 ">
      <div className="container plan-container">
        <div className="common-heading-border-large">
          <h1>
            The REAP Program:
            <span>Rural Energy for America</span>
            <img src={headingBorder} alt="" />
          </h1>
        </div>
        <div className="plan-container-body">
          {reapData && reapData.length > 0 ? (
            <div className="row">
              {reapData.map((data, index) => (
                <div key={index} className="col-lg-4 mb-3">
                  <CardCommon {...data} />
                </div>
              ))}
            </div>
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reap;
