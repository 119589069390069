const featureData = [
  {
    heading: "All features compared",
    subHeading: [
      { title: "Deferred Payment Plan" },
      { title: "Equal Monthly Installments" },
      { title: "Customized Flex Plan" },
    ],
    features: [
      {
        title: "Immediate Savings",
        plans: [
          "Zero upfront costs",
          "Predictable monthly payments",
          "Tailored to your needs",
        ],
      },
      {
        title: "Cash Flow Flexibility",
        plans: [
          "Deferred initial payments",
          "Equal manageable payments",
          "Adjusted based on seasons",
        ],
      },
      {
        title: "No Upfront Costs",
        plans: [
          "Start without commitments",
          "Stable fixed installments",
          "Strategic payment planning",
        ],
      },
      {
        title: "Fast Implementation",
        plans: [
          "Swift start with flexibility",
          "Fixed rates for peace of mind",
          "Adjustments for performance",
        ],
      },
      {
        title: "No Interest Accumulation",
        plans: [
          "Interest-free deferral",
          "Seamless integration",
          "Tailored plans with advice",
        ],
      },
      {
        title: "Predictable Budgeting",
        plans: [
          "Budgeting simplicity",
          "Gradual payback approach",
          "Custom schedule for strategy",
        ],
      },
      {
        title: "Fixed Interest Rates",
        plans: [
          "Stability in financial planning",
          "Fixed rates for predictability",
          "Personalized guidance",
        ],
      },
    ],
  },
];

export default featureData;
