import React, { useEffect } from "react";
import "./Risk.css";
import arrow from "../../../assets/images/arrow-btn.png";
import solarpanel from "../../../assets/images/solar-panel.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

const Risk = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false,
    });
  }, []);
  return (
    <div className="container-fluid risk-section-main">
      <div className="container risk-section-container">
        <div
          className="risk-section-heading animated-element"
          data-aos="fade-up"
        >
          <h1>We take on the risk!</h1>
        </div>

        <div className="risk-section-body">
          <div
            className="risk-secion-content animated-element"
            data-aos="zoom-in"
          >
            <p>
              To remain true to our driving focus of helping businesses improve
              their bottom line no matter what, we have structured all of our
              services in a way that allows our clients to see real results
              before they ever have to pay us a penny! This way, as a client you
              can make an investment with peace of mind knowing that you are
              building a relationship with a company that has proven themselves
              to you first, and will always have your back.
            </p>
            <span>Ready to put us to the test?</span>
            <NavLink to="/solar-system">
              <button className="get-start-button ">
                {" "}
                Get Started <img
                  src={arrow}
                  alt="Inactive arrow Missing"
                />{" "}
              </button>
            </NavLink>
          </div>
          <div
            className="risk-secion-image animated-element"
            data-aos="zoom-out"
          >
            <img src={solarpanel} alt="Inactive arrow Missing" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Risk;
