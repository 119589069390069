import React from "react";

const CurveCard = (props) => {
  return (
    <div className="common-card">
      <div className="common-card-header">
        {props.image && props.image.length > 0 && (
          <img src={props.image} alt="" />
        )}
        <div className="common-card-header-text">
          <h1>{props.title}</h1>
        </div>
      </div>
      <div className="common-card-body">
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default CurveCard;
