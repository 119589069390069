import React, { useRef, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./banner.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mouseImage from "../../../assets/images/mouse.png";
import { NavLink } from "react-router-dom";

const Banner = ({ bannerData }) => {
  const sliderRef = useRef(null);
  const [isSliderAtEnd, setIsSliderAtEnd] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "auto";

    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 5000,
    fade: true,
    autoplay: true,
    cssEase: "linear",
    afterChange: (current) => {
      setIsSliderAtEnd(current === 3);
    },
    beforeChange: () => {
      const videoIds = [1, 2];
      videoIds.forEach((videoId) => {
        const currentVideo = document.getElementById(`video${videoId}`);
        if (currentVideo) {
          currentVideo.currentTime = 0;
        }
      });
    },
  };

  let scrollCountAfterEnd = 0;
  let touchStartY = 0;
  const handleMouseClick = () => {
    if (sliderRef.current) {
      if (isSliderAtEnd) {
        document.body.style.overflow = "auto";
      } else {
        sliderRef.current.slickNext();
      }
    }
  };
  const handleWheel = (event) => {
    document.body.style.overflow = "auto";
  };
  const handleTouchStart = (event) => {
    touchStartY = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    document.body.style.overflow = "auto";
  };

  const handleTouchEnd = () => {
    // Perform any cleanup or additional logic after touch ends
  };

  document.addEventListener("scroll", () => {
    if (window.scrollY === 0) {
      document.body.style.overflow = "auto";
    }
  });

  return (
    <div
      className="container-fluid p-0"
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="slider-wrapper">
        <div className="container banner-content-wrapper">
          <div className="banner-content-left">
            <div className="banner-heading">
              <h1>
                <span>{bannerData[0]?.title || "--"}</span>
                {bannerData[0]?.subtitle || "--"}
              </h1>
            </div>
            <div className="banner-btn">
              <NavLink to="/solar-system">
                <button type="button">Get started</button>
              </NavLink>
            </div>
          </div>
          <div className="banner-content-right">
            <div className="banner-content-box">
              <p>{bannerData[0]?.description || "--"}</p>
            </div>
            <div className="banner-btn">
              <NavLink>
                <button>Get started</button>
              </NavLink>
            </div>
          </div>
        </div>
        <Slider ref={sliderRef} {...settings}>
          <div className="slide-content slide-content-one"></div>

          <div className="slide-content slide-content-two">
            <video
              autoPlay
              playsInline
              id="video1"
              loop
              muted
              className="video-background"
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/sunchoice-d6d12.appspot.com/o/Video3full.mp4?alt=media"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="slide-content slide-content-three"></div>
          <div className="slide-content slide-content-four">
            <video
              id="video2"
              autoPlay
              playsInline
              loop
              muted
              className="video-background"
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/sunchoice-d6d12.appspot.com/o/fullvideo1.mp4?alt=media"
                type="video/mp4"
              />
            </video>
          </div>
        </Slider>
        <div className="mouse-slider">
          <div className="mouse-circle-wrapper">
            <div className="mouse-circle-inner">
              <div className="mouse-circle-1"></div>
              <div className="mouse-circle-2"></div>
              <div className="mouse-circle-3"></div>
            </div>

            <img
              className="mouse-icon"
              src={mouseImage}
              onClick={handleMouseClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
