import React, { useState, useEffect } from "react";
import Storage from "../components/work/storage/storage";
import Freedom from "../components/service/freedom/freedom";
import Blog from "../components/common/blogSection/blog";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import Potential from "../components/work/solar-potential/potential";
import Banner from "../components/work/banner.js/banner";
import Pv from "../components/work/pv/pv";
import Metering from "../components/work/metering/metering";
import Loader from "../components/common/loader/loader";
import axios from "axios";
import pvIcon from "../assets/images/pv-icon.png";
import checkIcon from "../assets/images/check-icon.png";
const Work = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const [spinner, setSpinner] = useState(false);
  const [workData, setWorkData] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [billingData, setBillingData] = useState([]);
  const [embraceFreedomData, setEmbraceFreedomData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setSpinner(true);
    try {
      console.log("*********   API CALL - get-solar-work - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-solar-work`);
      const data = response?.data?.data?.solar_work;
      const transformedData = data.map((item) => ({
        ...item,
        title: item.work_heading,
        content: item.work_description,
        iconImage: pvIcon,
        work_description: undefined,
        work_heading: undefined,
      }));
      setWorkData(transformedData);
      fetchBatteryData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.log(error.message);
      setSpinner(false);
    }
  };
  const fetchBatteryData = async () => {
    try {
      console.log("*********   API CALL - get-battery-storage - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-battery-storage`);
      const data = response?.data?.data?.battery_storage;
      const transformedData = data.map((item) => ({
        ...item,
        image: imgUrl + item.image,
      }));
      setStorageData(transformedData);
      
    fetchBillingData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.log(error.message);
      setSpinner(false);
    }
  };
  const fetchBillingData = async () => {
    try {
      console.log("*********   API CALL - get-billing-work - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-billing-work`);
      const data = response?.data?.data?.billing_work;
      const transformedData = data.map((item) => ({
        ...item,
        icon: imgUrl + item.icon,
      }));
      setBillingData(transformedData);
      
    fetchEmbraceFreedomData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.log(error.message);
      setSpinner(false);
    }
  };
  const fetchEmbraceFreedomData = async () => {
    try {
      console.log("*********   API CALL - get-aims-card - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-aims-card`);
      const data = response?.data?.data?.aims_cards;
      const missionAimCard = data.filter((data) => data.type === "freedom");
      const transformedData = missionAimCard.map((item) => ({
        ...item,
        mission_items: item.mission_items ? JSON.parse(item.mission_items) : [],
        buttonContent: "Get started",
        icon: checkIcon,
      }));
      setEmbraceFreedomData(transformedData);
      setSpinner(false);
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching embrace freedom data:", error);
      setSpinner(false);
    }
  };
  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      <Banner />
      <Pv workData={workData} />
      <Potential />
      <Metering billingData={billingData} />
      <Storage storageData={storageData} />
      <Freedom embraceFreedomData={embraceFreedomData} />
      <Blog className="blog-section-whtie" />
    </>
  );
};

export default Work;
