import "./App.css";
import { useState } from "react";
import Home from "./pages/home";
import About from "./pages/aboutUs";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Footer from "./components/layout/footer/footer";
import Navbar from "./components/layout/navbar/navbar";
import AdminNavbar from "./components/admin/navbar/navbar";
import Cursor from "./components/layout/cursor/cursor";
import Service from "./pages/service";
import Work from "./pages/work";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import Support from "./pages/support";
import Consulting from "./pages/consulting";
import Contact from "./pages/contact";
import Blog from "./pages/blog";
import BlogDetail from "./pages/blogDetail";
import PageLoader from "./components/pageLoader/pageLoader";
import Login from "./pages/admin/login";
import Location from "./pages/admin/location";
import AddLocation from "./components/admin/addLocation/addLocation";
import MessageList from "./components/admin/message/message";
import ExcelFile from "./components/admin/excelFile/excelFile";
import SolarSystem from "./pages/solar";
import Lead from "./pages/admin/lead";
import SolarRequestDetail from "./components/admin/leads/solarRequestDetail";
import Terms from "./pages/admin/terms";
import Privacys from "./pages/admin/privacys";
import Accreditations from "./pages/admin/accreditations";
import TermIndex from "./pages/termIndex";
import PrivacyIndex from "./pages/privacyIndex";
import AccreditationIndex from "./pages/accreditationIndex";
import BlogIndex from "./pages/admin/blogIndex";
import AddBlog from "./components/admin/addBlog/addBlog";
import EditBlog from "./components/admin/addBlog/editBlog";
import OfficeIndex from "./pages/admin/officeIndex";
import AddOffice from "./components/admin/addOffice/addOffice";
import EditOffice from "./components/admin/addOffice/editOffice";
import OfficeDetail from "./components/contact/office/officeDetail";
import QuestionList from "./components/admin/questionList/questionList";
import AddQuestion from "./components/admin/addQuestion/addQuestion";
import TeamIndex from "./pages/admin/teamIndex";
import AddTeam from "./components/admin/addTeam/addTeam";
import EditTeam from "./components/admin/addTeam/editTeam";
import SolarWorkList from "./components/admin/solarWork/solarWorkList";
import AddSolarWork from "./components/admin/solarWork/addSolarWork";
import CommunityIndex from "./pages/admin/communityIndex";
import AddCommunity from "./components/admin/community/addCommunity";
import EditCommunity from "./components/admin/community/editCommunity";
import AddMissionValues from "./components/admin/missionValues/addMissionValues";
import EditMissionValues from "./components/admin/missionValues/editMissionValues";
import MissionValuesIndex from "./pages/admin/missionValuesIndex";
import BatteryStorageIndex from "./pages/admin/batteryStorageIndex";
import AddBatteryStorage from "./components/admin/batteryStorage/addBatteryStorage";
import EditBatteryStorage from "./components/admin/batteryStorage/editBatteryStorage";
import ReapProgramIndex from "./pages/admin/reapProgramIndex";
import AddReapProgram from "./components/admin/reapProgram/addReapProgram";
import EditReapProgram from "./components/admin/reapProgram/editReapProgram";
import BusinessSolarIndex from "./pages/admin/businessSolarIndex";
import AddBusinessSolar from "./components/admin/businessSolar/addBusinessSolar";
import EditBusinessSolar from "./components/admin/businessSolar/editBusinessSolar";
import ContactIndex from "./pages/admin/contactIndex";
import AddContact from "./components/admin/contact/addContact";
import EditContact from "./components/admin/contact/editContact";
import HomeBannerIndex from "./pages/admin/homeBannerIndex";
import AddHomeBanner from "./components/admin/homeBanner/addHomeBanner";
import EditHomeBanner from "./components/admin/homeBanner/editHomeBanner";
import HomeFreedomList from "./components/admin/homeFreedom/homeFreedomList";
import AddHomeFreedom from "./components/admin/homeFreedom/addHomeFreedom";
import EditHomeFreedom from "./components/admin/homeFreedom/editHomeFreedom";
import SolarServiceIndex from "./pages/admin/solarServiceIndex";
import AddSolarService from "./components/admin/solarService/addSolarService";
import EditSolarService from "./components/admin/solarService/editSolarService";
import ServiceWorkIndex from "./pages/admin/serviceWorkIndex";
import AddServiceWork from "./components/admin/serviceWork/addServiceWork";
import EditServiceWork from "./components/admin/serviceWork/editServiceWork";
import BillingWorkIndex from "./pages/admin/billingWorkIndex";
import AddBillingWork from "./components/admin/billingWork/addBillingWork";
import EditBillingWork from "./components/admin/billingWork/editBillingWork";
import GrantProgramIndex from "./pages/admin/grantProgramIndex";
import AddGrantProgram from "./components/admin/grantProgram/addGrantProgram";
import EditGrantProgram from "./components/admin/grantProgram/editGrantProgram";
import AboutTabsIndex from "./pages/admin/aboutTabsIndex";
import AddAboutTabs from "./components/admin/aboutTabs/addAboutTabs";
import EditAboutTabs from "./components/admin/aboutTabs/editAboutTabs";
import FeedbackIndex from "./pages/admin/feedbackIndex";
import AddFeedback from "./components/admin/feedback/addFeedback";
import EditFeedback from "./components/admin/feedback/editFeedback";
import EditPlan from "./components/admin/ourPlan/editPlan";
import AddPlan from "./components/admin/ourPlan/addPlan";
import PlanIndex from "./pages/admin/planIndex";
import EmbraceFreedomIndex from "./pages/admin/embraceFreedomIndex";
import AddEmbraceFreedom from "./components/admin/embraceFreedom/addEmbraceFreedom";
import EditEmbraceFreedom from "./components/admin/embraceFreedom/editEmbraceFreedom";
import AddSocialLinks from "./components/admin/socialLinks/addSocialLinks";
function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const onPageLoader = () => {
    setLoading(false);
  };
  const navbarHiddenPaths = ["/admin"];
  const isNavbarVisible = !navbarHiddenPaths.includes(location.pathname);
  const adminRoutes = [
    "/location",
    "/add-location",
    "/message",
    "/sheet",
    "/request",
    "/details",
    "/term-condition",
    "/privacy-policy",
    "/trust-accreditation",
    "/add-blog",
    "/blog-list",
    "/edit-blog",
    "/office-list",
    "/add-office",
    "/edit-office",
    "/question-list",
    "/add-question",
    "/team-list",
    "/add-team",
    "/edit-team",
    "/solarwork-list",
    "/add-solarwork",
    "/community-list",
    "/add-community",
    "/edit-community",
    "/missionvalues-list",
    "/add-missionvalue",
    "/edit-missionvalue",
    "/storage-list",
    "/add-storage",
    "/edit-storage",
    "/reap-list",
    "/add-reap",
    "/edit-reap",
    "/solarbusinees-list",
    "/add-solarbusinees",
    "/edit-solarbusinees",
    "/contact-list",
    "/add-contact",
    "/edit-contact",
    "/homebanner-list",
    "/add-homebanner",
    "/edit-homebanner",
    "/homefreedom-list",
    "/add-homefreedom",
    "/edit-homefreedom",
    "/solarservice-list",
    "/add-solarservice",
    "/edit-solarservice",
    "/servicework-list",
    "/add-servicework",
    "/edit-servicework",
    "/billingwork-list",
    "/add-billingwork",
    "/edit-billingwork",
    "/grantprogram-list",
    "/add-grantprogram",
    "/edit-grantprogram",
    "/abouttabs-list",
    "/add-abouttabs",
    "/edit-abouttabs",
    "/feedback-list",
    "/add-feedback",
    "/edit-feedback",
    "/plan-list",
    "/add-plan",
    "/edit-plan",
    "/embracefreedom-list",
    "/add-embracefreedom",
    "/edit-embracefreedom",
    "/social-links",
  ];
  const isAdminRoute = adminRoutes.some((route) =>
    location.pathname.startsWith(route)
  );
  const isLoginPage = location.pathname === "/admin";
  const isAuthenticated = localStorage.getItem("token");
  return (
    <>
      {loading ? (
        <PageLoader onPageLoader={onPageLoader} />
      ) : (
        <>
          <ScrollToTop />
          {isNavbarVisible && !isAdminRoute ? <Navbar /> : null}
          {isAdminRoute ? <AdminNavbar /> : null}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/work" element={<Work />} />
            <Route path="/service" element={<Service />} />
            <Route path="/grant-consulting" element={<Consulting />} />
            <Route path="/financing-support" element={<Support />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/office/:id" element={<OfficeDetail />} />
            <Route path="/solar-system" element={<SolarSystem />} />
            <Route path="/term" element={<TermIndex />} />
            <Route path="/privacy" element={<PrivacyIndex />} />
            <Route path="/accreditation" element={<AccreditationIndex />} />
            {/* admin */}
            <Route path="/admin" element={<Login />} />
            <Route
              path="/location"
              element={
                isAuthenticated ? <Location /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/add-location/:id?"
              element={
                isAuthenticated ? <AddLocation /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/question-list"
              element={
                isAuthenticated ? <QuestionList /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/add-question/:id?"
              element={
                isAuthenticated ? <AddQuestion /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/solarwork-list"
              element={
                isAuthenticated ? <SolarWorkList /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/add-solarwork/:id?"
              element={
                isAuthenticated ? <AddSolarWork /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/message"
              element={
                isAuthenticated ? <MessageList /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/sheet"
              element={
                isAuthenticated ? <ExcelFile /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="/request"
              element={isAuthenticated ? <Lead /> : <Navigate to="/admin" />}
            />
            <Route
              path="/request/details/:id"
              element={
                isAuthenticated ? (
                  <SolarRequestDetail />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="term-condition"
              element={isAuthenticated ? <Terms /> : <Navigate to="/admin" />}
            />
            <Route
              path="privacy-policy"
              element={
                isAuthenticated ? <Privacys /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="blog-list"
              element={
                isAuthenticated ? <BlogIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-blog"
              element={isAuthenticated ? <AddBlog /> : <Navigate to="/admin" />}
            />
            <Route
              path="edit-blog/:id?"
              element={
                isAuthenticated ? <EditBlog /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="office-list"
              element={
                isAuthenticated ? <OfficeIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-office"
              element={
                isAuthenticated ? <AddOffice /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-office/:id?"
              element={
                isAuthenticated ? <EditOffice /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="team-list"
              element={
                isAuthenticated ? <TeamIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-team"
              element={isAuthenticated ? <AddTeam /> : <Navigate to="/admin" />}
            />
            <Route
              path="edit-team/:id?"
              element={
                isAuthenticated ? <EditTeam /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="community-list"
              element={
                isAuthenticated ? <CommunityIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-community"
              element={
                isAuthenticated ? <AddCommunity /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-community/:id?"
              element={
                isAuthenticated ? <EditCommunity /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="solarservice-list"
              element={
                isAuthenticated ? (
                  <SolarServiceIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-solarservice"
              element={
                isAuthenticated ? <AddSolarService /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-solarservice/:id?"
              element={
                isAuthenticated ? (
                  <EditSolarService />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="missionvalues-list"
              element={
                isAuthenticated ? (
                  <MissionValuesIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-missionvalue"
              element={
                isAuthenticated ? (
                  <AddMissionValues />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="edit-missionvalue/:id?"
              element={
                isAuthenticated ? (
                  <EditMissionValues />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="storage-list"
              element={
                isAuthenticated ? (
                  <BatteryStorageIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-storage"
              element={
                isAuthenticated ? (
                  <AddBatteryStorage />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="edit-storage/:id?"
              element={
                isAuthenticated ? (
                  <EditBatteryStorage />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="reap-list"
              element={
                isAuthenticated ? (
                  <ReapProgramIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-reap"
              element={
                isAuthenticated ? <AddReapProgram /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-reap/:id?"
              element={
                isAuthenticated ? <EditReapProgram /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="contact-list"
              element={
                isAuthenticated ? <ContactIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-contact"
              element={
                isAuthenticated ? <AddContact /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-contact/:id?"
              element={
                isAuthenticated ? <EditContact /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="homebanner-list"
              element={
                isAuthenticated ? <HomeBannerIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-homebanner"
              element={
                isAuthenticated ? <AddHomeBanner /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-homebanner/:id?"
              element={
                isAuthenticated ? <EditHomeBanner /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="homefreedom-list"
              element={
                isAuthenticated ? <HomeFreedomList /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-homefreedom"
              element={
                isAuthenticated ? <AddHomeFreedom /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-homefreedom/:id?"
              element={
                isAuthenticated ? <EditHomeFreedom /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="servicework-list"
              element={
                isAuthenticated ? (
                  <ServiceWorkIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-servicework"
              element={
                isAuthenticated ? <AddServiceWork /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-servicework/:id?"
              element={
                isAuthenticated ? <EditServiceWork /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="billingwork-list"
              element={
                isAuthenticated ? (
                  <BillingWorkIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-billingwork"
              element={
                isAuthenticated ? <AddBillingWork /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-billingwork/:id?"
              element={
                isAuthenticated ? <EditBillingWork /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="grantprogram-list"
              element={
                isAuthenticated ? (
                  <GrantProgramIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-grantprogram"
              element={
                isAuthenticated ? <AddGrantProgram /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-grantprogram/:id?"
              element={
                isAuthenticated ? (
                  <EditGrantProgram />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="abouttabs-list"
              element={
                isAuthenticated ? <AboutTabsIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-abouttabs"
              element={
                isAuthenticated ? <AddAboutTabs /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-abouttabs/:id?"
              element={
                isAuthenticated ? <EditAboutTabs /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="feedback-list"
              element={
                isAuthenticated ? <FeedbackIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-feedback"
              element={
                isAuthenticated ? <AddFeedback /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="edit-feedback/:id?"
              element={
                isAuthenticated ? <EditFeedback /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="solarbusinees-list"
              element={
                isAuthenticated ? (
                  <BusinessSolarIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-solarbusinees"
              element={
                isAuthenticated ? (
                  <AddBusinessSolar />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="edit-solarbusinees/:id?"
              element={
                isAuthenticated ? (
                  <EditBusinessSolar />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="plan-list"
              element={
                isAuthenticated ? <PlanIndex /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="add-plan"
              element={isAuthenticated ? <AddPlan /> : <Navigate to="/admin" />}
            />
            <Route
              path="edit-plan/:id?"
              element={
                isAuthenticated ? <EditPlan /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="embracefreedom-list"
              element={
                isAuthenticated ? (
                  <EmbraceFreedomIndex />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="add-embracefreedom"
              element={
                isAuthenticated ? (
                  <AddEmbraceFreedom />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="edit-embracefreedom/:id?"
              element={
                isAuthenticated ? (
                  <EditEmbraceFreedom />
                ) : (
                  <Navigate to="/admin" />
                )
              }
            />
            <Route
              path="trust-accreditation"
              element={
                isAuthenticated ? <Accreditations /> : <Navigate to="/admin" />
              }
            />
            <Route
              path="social-links"
              element={
                isAuthenticated ? <AddSocialLinks /> : <Navigate to="/admin" />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {!isLoginPage && !isAdminRoute && <Footer />}
        </>
      )}
    </>
  );
}

export default App;
