import React, { useState, useEffect } from "react";
import axios from "axios";
import "./message.css";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../../blog/blogs/pagination";
import Loader from "../../common/loader/loader";
const MessageList = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  // loader
  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationPerPage, setLocationPerPage] = useState(12);
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("grant");
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setSpinner(true);
    try {
      const response = await axios.get(`${baseUrl}/get-contactus-data`, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      });
      const data = response?.data?.data?.contact_us;

      setSpinner(false);
      setOriginalData(data);
      setFilteredData(data);
    } catch (error) {
      setError(error.message);
    }
  };

  // search filter
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  // Calculate the index range for the current page
  const indexOfLastLocation = currentPage * locationPerPage;
  const indexOfFirstLocation = indexOfLastLocation - locationPerPage;
  // Function to filter data based on search query
  const filterDataBySearch = (data) => {
    return data.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  // Filtered data based on search query
  const filteredDataBySearch = filterDataBySearch(filteredData);
  const currentLocation = filteredDataBySearch.slice(
    indexOfFirstLocation,
    indexOfLastLocation
  );

  // Handle page change
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle blogs per page change
  const onLocationPerPageChange = (perPage) => {
    setLocationPerPage(perPage);
    setCurrentPage(1); // Reset to the first page when changing blogs per page
  };
  // Handle next page
  const onNextPage = () => {
    //this line get total number of pages needed
    const totalPages = Math.ceil(filteredDataBySearch.length / locationPerPage);
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  // Handle previous page
  const onPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  const totalLocation = filteredDataBySearch.length;
  const resultsStart = (currentPage - 1) * locationPerPage + 1;
  const resultsEnd = Math.min(currentPage * locationPerPage);
  return (
    <>
      {spinner && <Loader />}
      <div className="admin-wrapper">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="container-fluid location-section">
          <div className="location-header">
            <h1>Message List</h1>
          </div>
          <div className="location-tab-wrapper mt-3">
            <div className="location-tab-right">
              <input
                type="text"
                placeholder="Search here..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="pagination-header mt-3">
            <h1>
              {totalLocation}
              <span> Message</span>
            </h1>
            <div className="dropdown-pagination">
              <h2>Message per Page:</h2>
              <select
                className="form-select"
                value={locationPerPage}
                onChange={(e) =>
                  onLocationPerPageChange(parseInt(e.target.value, 10))
                }
              >
                {[12, 14, 16, 18].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="message-table-wrapper mt-3">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Email</div>
              <div className="table-cell">Message</div>
            </div>
            {filteredData.length === 0 ? (
              <p className="text-center">No data available.</p>
            ) : (
              <>
                {currentLocation.map((data, index) => (
                  <div key={index} className="table-row">
                    <div className="table-cell" data-heading="Name">
                      {data.name}
                    </div>
                    <div className="table-cell" data-heading="Email">
                      {data.email}
                    </div>
                    <div className="table-cell" data-heading="Message">
                      {data.description}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="pagination-footer">
            <div className="pagination-value">
              <p>
                Results {resultsStart} to {resultsEnd} of {totalLocation}
              </p>
            </div>
            <div className="pagination-counter">
              <button
                className=""
                onClick={onPrevPage}
                disabled={currentPage === 1}
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Previous
              </button>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(filteredData.length / locationPerPage)}
                onPageChange={onPageChange}
                locationPerPage={locationPerPage}
              />
              <button
                className=""
                onClick={onNextPage}
                disabled={
                  currentPage ===
                  Math.ceil(filteredData.length / locationPerPage)
                }
              >
                Next
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageList;
