import React from "react";
import { NavLink } from "react-router-dom";

const BlogCard = (props) => {
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  return (
    <div className={`blog-card ${props.customClass}`}>
      <div className="blog-card-content">
        <div className="blog-card-header">
          <img src={imgUrl + props.cover_image} alt="" />
        </div>
        <div className="blog-card-body">
          <div className="card-tag-wrapper">
            <div className="tag-box active">
              <h1>{props.type}</h1>
            </div>
            <div className="tag-box ">
              <h1>{props.reading_time}</h1>
            </div>
          </div>
          <div className="blog-card-body-content">
            <h1>{props.title}</h1>
            <p>{props.subtitle}</p>
          </div>
        </div>
      </div>

      <div className="blog-card-footer">
        <NavLink to={`/blog/${props.id}`}>
          Read More
          <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.83332 0C9.58332 0 9.41666 0.083333 9.24999 0.25L0.916657 8.58333C0.583323 8.91667 0.583323 9.41667 0.916657 9.75C1.24999 10.0833 1.74999 10.0833 2.08332 9.75L10.4167 1.41667C10.75 1.08333 10.75 0.583333 10.4167 0.25C10.25 0.083333 10.0833 0 9.83332 0Z"
              fill="#FEFEFE"
            />
            <path
              d="M9.833 0.000325203H2.333C1.833 0.000325203 1.49966 0.333658 1.49966 0.833658C1.49966 1.33366 1.833 1.66699 2.333 1.66699H8.99966V8.33366C8.99966 8.83366 9.333 9.16699 9.833 9.16699C10.333 9.16699 10.6663 8.83366 10.6663 8.33366V0.833658C10.6663 0.333658 10.333 0.000325203 9.833 0.000325203Z"
              fill="#FEFEFE"
            />
          </svg>
        </NavLink>
      </div>
    </div>
  );
};

export default BlogCard;
