import React from "react";
import "./aboutSunchoice.css";
import sunIcon from "../../../assets/images/sun-icon.png";
import aboutRectangle from "../../../assets/images/about-rectangle.png";
import aboutImageOne from "../../../assets/images/sunchoice-img.JPG";
import { NavLink } from "react-router-dom";

const AboutSunchoice = () => {
  return (
    <div className="container-fluid about-section">
      <div className="container about-container">
        <div className="about-container-left">
          <h1>
            <span>
              About SunChoice <img src={sunIcon} alt="" />
            </span>
            The Best of Both Worlds
          </h1>
          <p>
            While it definitely is an awesome bonus that our services positively
            affect our planet and the environment, this is not our focus.
            SunChoice is a company founded by business owners FOR business
            owners.
          </p>
          <p>
            We believe every product and service we offer must bring significant
            financial benefits to our clients, and if they don’t we simply don’t
            offer them. The reason our team got into the solar industry, and
            have been a part of it for more than 20 years, is because of the
            incredible financial benefits it offers.
          </p>
        </div>
        <div className="about-container-right">
          <div className="about-right-wrapper">
            <img src={aboutImageOne} alt="" />
            <div className="year-counter">
              20+<span>Years</span>
            </div>
          </div>

          <img className="rectangle-back" src={aboutRectangle} alt="" />
        </div>
      </div>
      <div className="container about-container about-container-two mt-5">
        <div className="about-container-left">
          <p>
            By helping business owners successfully navigate the process of
            investing in their own solar generation facility, we help them
            drastically lower a major expense for their business that they
            otherwise would never be able to get away from. This saves them
            money on their bottom line, freeing up more funds to spend on
            helping their business grow.
          </p>
          <p>
            With this as our driving focus, we have been able to successfully
            help business owners all over the country to power their freedom in
            a way that helps them to grow their cash flow rather than shrink it.
          </p>
          <NavLink to="/contact-us" className="mt-5">
            How we help our customers pay on their terms
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.66667 0C9.41667 0 9.25 0.083333 9.08333 0.25L0.75 8.58333C0.416667 8.91667 0.416667 9.41667 0.75 9.75C1.08333 10.0833 1.58333 10.0833 1.91667 9.75L10.25 1.41667C10.5833 1.08333 10.5833 0.583333 10.25 0.25C10.0833 0.083333 9.91667 0 9.66667 0Z"
                fill="#FEFEFE"
              ></path>
              <path
                d="M9.66634 0.000325203H2.16634C1.66634 0.000325203 1.33301 0.333658 1.33301 0.833658C1.33301 1.33366 1.66634 1.66699 2.16634 1.66699H8.83301V8.33366C8.83301 8.83366 9.16634 9.16699 9.66634 9.16699C10.1663 9.16699 10.4997 8.83366 10.4997 8.33366V0.833658C10.4997 0.333658 10.1663 0.000325203 9.66634 0.000325203Z"
                fill="#FEFEFE"
              ></path>
            </svg>
          </NavLink>
        </div>
        <div className="about-container-right">
          <div className="about-right-wrapper">
            <img src={aboutImageOne} alt="" />
          </div>

          <img className="rectangle-back" src={aboutRectangle} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutSunchoice;
