import React from "react";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import BusinessAccreditation from "../components/setting/businessAccreditation";
const AccreditationIndex = () => {
  return (
    <>
      <SunAnimation />
      <BusinessAccreditation />
    </>
  );
};

export default AccreditationIndex;
