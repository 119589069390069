import React from "react";
import "./banner.css";
import protfilOne from "../../../assets/images/team.jpg";
import curveArrow from "../../../assets/images/curve-arrow.png";
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <div class="container-fluid about-banner top-wrapper">
      <div class="container about-banner-container">
        <div class="about-banner-header">
          <h1>
            SunChoice Solar: <span>Your Partner in Solar Success</span>
          </h1>
          <div class="about-banner-header-content pt-3">
            <p>
              20+ Years of Solar Expertise, Dedicated to Your Business Growth
              and Sustainability. Our Passion for Empowering Businesses
              Transcends the Horizon – Let's Illuminate Your Path to Solar
              Excellence Together.
            </p>
            <div class="about-banner-header-content-right">
              <div class="about-banner-header-content-right-images">
                <img src={protfilOne} alt="" />
                <img src={protfilOne} alt="" />
                <img src={protfilOne} alt="" />
              </div>
              <div class="about-banner-header-content-right-button">
                <img src={curveArrow} alt="" />
                <NavLink to="/solar-system">Get started</NavLink>
              </div>
            </div>
          </div>
        </div>
        <div class="about-banner-body">
          {/* <div class="about-banner-body-left"></div>
        <div class="about-banner-body-right"></div> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
