import React from "react";
import "./banner.css";

const Banner = () => {
  return (
    <div class="container-fluid about-banner consulting-section top-wrapper">
      <div class="container about-banner-container">
        <div class="about-banner-header">
          <h1>
            Grant Consulting:
            <span>Navigating Opportunities for Your Success</span>
          </h1>
          <div class="about-banner-header-content pt-3">
            <p>
              Embark on your solar journey with the confidence that comes from
              expert guidance. SunChoice's Grant Consulting services are your
              gateway to unlocking financial opportunities that propel your
              solar project forward. Discover how we navigate complex grant
              landscapes, ensuring your business maximizes benefits while
              minimizing the administrative burden.
            </p>
          </div>
        </div>
        <div class="about-banner-body">
          {/* <div class="about-banner-body-left"></div>
        <div class="about-banner-body-right"></div> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
