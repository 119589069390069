import React from "react";
import "./banner.css";
import headingBorder from "../../../assets/images/heading-border-larger.png";
import { NavLink } from "react-router-dom";

const Banner = ({ solarWorkData }) => {
  return (
    <div className="services-banner-work-wrapper top-wrapper">
      <div className="container-fluid services-section">
        <div className="container-fluid services-container">
          <div className="services-container-header">
            <h1>
              Solar as a Service:
              <span> Empowering Your Business, Your Way</span>
            </h1>
          </div>
          <div className="services-container-body">
            <p>
              Unlock the true potential of your business with Solar as a
              Service. Beyond just a source of power, it's a strategic
              investment that turns energy expenses into assets, providing your
              business with sustainable, cost-effective solutions. Join the
              solar revolution – where your energy independence and financial
              empowerment converge to shape a brighter, more sustainable future
              for your business.
            </p>
            <NavLink to="/solar-system">Get started </NavLink>
          </div>
        </div>
      </div>
      <div className="container-fluid serviceWork-section">
        <div className="container serviceWork-container">
          <div className="serviceWork-container-header">
            <h1>
              How Solar as a Service Works
              <img src={headingBorder} alt="" />
            </h1>
            <p>
              Discover the simplicity and power behind Solar as a Service. Our
              process ensures a seamless journey from energy audit to solar
              installation, with ongoing support for the life of your system.
            </p>
          </div>
          <div className="serviceWork-container-body">
            {solarWorkData.slice(0, 4).map((data) => (
              <div className="serviceWork-circle" key={data.id}>
                <h1>{data.title || "--"}</h1>
              </div>
            ))}
          </div>
          <div className="serviceWork-container-footer">
            {solarWorkData.slice(0, 4).map((data, index) => (
              <div className="serviceWork-container-list" key={data.id}>
                <h1>
                  <span>{index + 1}</span>
                  {data.title || "--"}
                </h1>
                <p>{data.content || "--"}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
