import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";

const AddSolarWork = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  let navigation = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    work_heading: "",
    work_description: "",
  });

  useEffect(() => {
    if (id) {
      fetchSolarWorkData(id);
    }
  }, [id]);

  const fetchSolarWorkData = async (id) => {
    setSpinner(true);
    try {
      const response = await axios.get(`${baseUrl}/get-solar-work`);
      const data = response?.data?.data?.solar_work;
      const solarWork = data.find((item) => item.id == id);
      setSpinner(false);
      setFormData(solarWork);
    } catch (error) {
      setSpinner(false);
      toast.error("Failed to fetch solarWork data. Please try again later.");
    }
  };

  const [displayError, setDisplayError] = useState();
  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const validateForm = () => {
    const { work_heading, work_description } = formData;
    if (!work_heading || !work_description) {
      setDisplayError("All fields are required.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSpinner(true);
      try {
        const requestData = { ...formData };
        if (id) {
          requestData.id = id;
        }
        const response = await axios.post(
          `${baseUrl}/add-update-pv-solar`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        setSpinner(false);
        if (response.status === 200) {
          toast.success(
            id
              ? "Solar Work updated successfully!"
              : "Solar Work added successfully!",
            {
              onClose: () => navigation("/solarwork-list"),
            }
          );
          setDisplayError(null);
        } else {
          throw new Error("Failed to add or update solar work.");
        }
      } catch (error) {
        setSpinner(false);
        toast.error(
          `Failed to ${id ? "update" : "add"} solar work. ${error.message}`
        );
      }
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <div className="admin-wrapper">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>{id ? "Edit Solar Work" : "Add Solar Work"}</h1>
              </div>
            </div>
            {displayError && (
              <div className="alert alert-danger" role="alert">
                {displayError}
              </div>
            )}
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Ttile here..."
                name="work_heading"
                value={formData.work_heading}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="Description here..."
                name="work_description"
                value={formData.work_description}
                onChange={handleChange}
              />
            </div>

            <div className="form-field pt-4">
              <button type="submit">{id ? "Update" : "Add"}</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSolarWork;
