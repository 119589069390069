import React, { useState, useEffect } from "react";
import "./Solar.css";
import AOS from "aos";
import "aos/dist/aos.css";
import exampleImage from "../../../assets/images/waves.svg";
import arrow from "../../../assets/images/arrow.png";
import arrowActive from "../../../assets/images/arrow.active.png";
import circle from "../../../assets/images/circle-image.png";
import { solarData } from "./solarData";
import { NavLink } from "react-router-dom";

const Solar = ({ freedomData }) => {
  const [activeTab, setActiveTab] = useState(null);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  useEffect(() => {
    if (freedomData && freedomData.length > 0) {
      setActiveTab(freedomData[0].id);
    }
  }, [freedomData]);
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false,
    });
  }, []);
  return (
    <div className="container-fluid main-solar-section ">
      <div className="container main-solar-container">
        <div className="solar-Main-Heading ">
          <h1>
            Why Use Solar To{" "}
            <span>
              {" "}
              Power Your Freedom? <img src={exampleImage} alt="Missing image" />
            </span>
          </h1>
        </div>
        <div className="solar-tabs-main">
          <Tab
            data={freedomData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
        </div>
      </div>
    </div>
  );
};

function Tab({ data, activeTab, handleTabClick }) {
  return (
    <div className="solar-tabs-main">
      <div className="solar-tabs-xl">
        <ul
          className="tab-buttons tabs-none animated-element"
          data-aos="fade-down"
        >
          {data.map((item) => (
            <li
              key={item.id}
              onClick={() => handleTabClick(item.id)}
              className={
                activeTab === item.id ? "tab-trigger active" : "tab-trigger"
              }
            >
              {item.tab_title || "--"}
              {activeTab === item.id ? (
                <img src={arrow} alt="Active arrow Missing" />
              ) : (
                <img src={arrowActive} alt="Inactive arrow Missing" />
              )}
            </li>
          ))}
        </ul>
        <div className="tab-contents animated-element" data-aos="fade-up">
          <div className="content-header">
            <div className="tab-trigger-heading">
              {data.map((item) => (
                <p
                  className="content-heading"
                  key={item.id}
                  style={activeTab === item.id ? {} : { display: "none" }}
                >
                  {item.tab_title || "--"}
                </p>
              ))}
            </div>
            <div className="empty-tab-trigger-heading"></div>
          </div>
          <div className="content-body">
            <div className="content-body-text">
              {data.map((item) => (
                <p
                  key={item.id}
                  style={activeTab === item.id ? {} : { display: "none" }}
                >
                  {item.tab_content ? (
                    <p>{item.tab_content || "--"}</p>
                  ) : (
                    // <p
                    //   dangerouslySetInnerHTML={{
                    //     __html: item.tab_content
                    //       .replace(
                    //         /Inflation Reduction Act of 2022 ↑/g,
                    //         "<span> Inflation Reduction Act of 2022 ↑</span>"
                    //       )
                    //       .replace(/grant ↑/g, "<span>grant ↑</span>"),
                    //   }}
                    // ></p>
                    <span>No content available</span>
                  )}
                </p>
              ))}
            </div>
            <div className="content-body-image">
              <img src={circle} alt="Missing image" />
            </div>
          </div>
        </div>
      </div>

      {/* mobile view */}

      <div className="solar-tab-small">
        <ul className="tab-buttons-sm tabs-none">
          {data.map((item) => (
            <div key={item.id}>
              <li
                onClick={() => handleTabClick(item.id)}
                className={
                  activeTab === item.id
                    ? "tab-trigger-sm active"
                    : "tab-trigger-sm"
                }
              >
                {item.tab_title}
                {activeTab === item.id ? (
                  <img src={arrow} alt="Active arrow Missing" />
                ) : (
                  <img src={arrowActive} alt="Inactive arrow Missing" />
                )}
              </li>
              <div
                className="tab-contents-sm"
                style={activeTab === item.id ? {} : { display: "none" }}
              >
                <div className="content-header">
                  <div className="tab-trigger-heading">
                    <p className="content-heading">{item.tab_title}</p>
                  </div>
                  <div className="empty-tab-trigger-heading"></div>
                </div>
                <div className="content-body">
                  <div className="content-body-image">
                    <img src={circle} alt="Missing image" />
                  </div>
                  <div className="content-body-text">
                    {data.map((item) => (
                      <p
                        key={item.id}
                        style={activeTab === item.id ? {} : { display: "none" }}
                      >
                        {item.tab_content ? (
                          <p>{item.tab_content || "--"}</p>
                        ) : (
                          // <p
                          //   dangerouslySetInnerHTML={{
                          //     __html: item.tab_content
                          //       .replace(
                          //         /Inflation Reduction Act of 2022 ↑/g,
                          //         "<span> Inflation Reduction Act of 2022 ↑</span>"
                          //       )
                          //       .replace(/grant ↑/g, "<span>grant ↑</span>"),
                          //   }}
                          // ></p>
                          <span>No content available</span>
                        )}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ul>
      </div>
      <div className="blod-section-footer">
        <NavLink>
          See what incentives your business could be eligible for{" "}
          <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.83332 0C9.58332 0 9.41666 0.083333 9.24999 0.25L0.916657 8.58333C0.583323 8.91667 0.583323 9.41667 0.916657 9.75C1.24999 10.0833 1.74999 10.0833 2.08332 9.75L10.4167 1.41667C10.75 1.08333 10.75 0.583333 10.4167 0.25C10.25 0.083333 10.0833 0 9.83332 0Z"
              fill="#007BFF"
            />
            <path
              d="M9.833 0.000325203H2.333C1.833 0.000325203 1.49966 0.333658 1.49966 0.833658C1.49966 1.33366 1.833 1.66699 2.333 1.66699H8.99966V8.33366C8.99966 8.83366 9.333 9.16699 9.833 9.16699C10.333 9.16699 10.6663 8.83366 10.6663 8.33366V0.833658C10.6663 0.333658 10.333 0.000325203 9.833 0.000325203Z"
              fill="#007BFF"
            />
          </svg>
        </NavLink>
      </div>
    </div>
  );
}

export default Solar;
