const multiTabIcon1 = require("../../../assets/images/multitab-icon1.png");
const multiTabIcon2 = require("../../../assets/images/multitab-icon2.png");
const multiTabIcon3 = require("../../../assets/images/multitab-icon3.png");
const multiTabIcon4 = require("../../../assets/images/multitab-icon4.png");
const multiTabIcon5 = require("../../../assets/images/multitab-icon5.png");
const multiTabIcon6 = require("../../../assets/images/multitab-icon6.png");
const multiTabIcon7 = require("../../../assets/images/multitab-icon7.png");
const multiTabImage1 = require("../../../assets/images/mission-img.JPG");
const multiTabImage2 = require("../../../assets/images/sunchoice-img.JPG");

const tabContentData = [
  {
    title: "From Roots to Radiance",
    subtitle: "Tracing Our Journey in the Solar Industry",
    heading: "Founding the ",
    subHeading: " Solar Vision",
    description:
      " we had seen too many companies not live up to our moral standards. We knew it was time to shake things up, and provide a true quality service to all, especially those in the rural small towns where we ourselves began. And so in 2023 SunChoice was born out of a shared passion for leveraging renewable energies to help businesses improve their cashflow in an environmentally friendly way. It started with a vision of helping businesses thrive, but grew into a campaign to provide businesses with more energy independence.",
    imagePath: multiTabImage1,
    iconPath: multiTabIcon1,
    sectionClass: "multitab-section-one",
  },
  {
    title: "From Roots to Radiance",
    subtitle: "Tracing Our Journey in the Solar Industry",
    heading: "Pioneering ",
    subHeading: " Solar Excellence",
    description:
      "Our team tirelessly worked to pioneer new methods of financing and project development to ensure our clients were receiving the best return on investment possible.",
    imagePath: multiTabImage2,
    iconPath: multiTabIcon2,
    sectionClass: "",
  },
  {
    title: "From Roots to Radiance",
    subtitle: "Tracing Our Journey in the Solar Industry",
    heading: "A CREATIVE FUNDING",
    subHeading: " APPROACH:",
    description:
      "In our search for providing the best value to our clients, we started working with several grant programs that targeted renewable energy technologies. We figured out all the in's and outs of these programs and started working to help our clients apply for and win competitive grants to cover the cost of their projects",
    imagePath: multiTabImage1,
    iconPath: multiTabIcon3,
    sectionClass: "multitab-section-two",
  },
  {
    title: "From Roots to Radiance",
    subtitle: "Tracing Our Journey in the Solar Industry",
    heading: "USDA PREFERRED ",
    subHeading: " PARTNERS:",
    description:
      "After helping several businesses in southeastern Utah win grants for their solar projects, we ended up getting noticed by the Utah USDA Rural Development office. They awarded us a grant to continue assisting businesses and farmers with applying for grants through the Rural Energy for America Program (REAP), only they wanted us to do it throughout the entire state. This began our Preferred Partnership with the USDA.",
    imagePath: multiTabImage2,
    iconPath: multiTabIcon4,
    sectionClass: "multitab-section-three",
  },
  {
    title: "From Roots to Radiance",
    subtitle: "Tracing Our Journey in the Solar Industry",
    heading: "NATIONAL",
    subHeading: " EMPOWERMENT:",
    description:
      "Our relationship with the USDA in Utah helped us to understand the grant programs and how they work on an even deeper level.  It also helped us to see that throughout the entire nation there are businesses and farmers in rural communities that have no clue what incentives and potential savings are available to them. It is for this reason we push ourselves to continuously expand into more and more territories, giving more and more business owners access to these programs.",
    imagePath: multiTabImage1,
    iconPath: multiTabIcon5,
    sectionClass: "multitab-section-one",
  },
  {
    title: "From Roots to Radiance",
    subtitle: "Tracing Our Journey in the Solar Industry",
    heading: "LOCAL ROOTS,",
    subHeading: " NATIONAL REACH:",
    description:
      "As we expanded to states spanning the country, we maintained our dedication to our own small town roots and values. A big part of this has been making sure we are able to work with local teams and contractors wherever possible. This step has proven to be essential to ensuring our personal touch and understanding of the unique needs in each territory we serve.",
    imagePath: multiTabImage2,
    iconPath: multiTabIcon6,
    sectionClass: "",
  },
  {
    title: "From Roots to Radiance",
    subtitle: "Tracing Our Journey in the Solar Industry",
    heading: "TODAY AND",
    subHeading: " TOMORROW:",
    description:
      "UNITING SOLAR EXCELLENCE WITH YOUR BUSINESS GOALS: As of 2024, SunChoice has fostered a 85% success rate with our grant application assistance and continues to help dozens of businesses win valuable grant money to help their business grow. We stand at the forefront of solar excellence, and maintain our resolve to continue empowering businesses everywhere to leverage available incentives to improve their own overall wellbeing.",
    imagePath: multiTabImage1,
    iconPath: multiTabIcon7,
    sectionClass: "multitab-section-two",
  },
];
export default tabContentData;
