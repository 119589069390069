import React from "react";
import "./business.css";
import CurveCard from "../../common/curveCard/curvecard";

const Business = ({ businessSolarData }) => {
  return (
    <div className="container-fluid business-section mb-5">
      <div className="container business-container">
        <div className="common-heading">
          <h1>
            Solar for Business Owners
            <span>
              SunChoice is founded by business owners, for business owners.
              Discover how solar can be a game-changer for your bottom line.
            </span>
          </h1>
        </div>
        <div className="business-container-body pt-5">
          {businessSolarData && businessSolarData.length > 0 ? (
            <div className="row">
              {businessSolarData.map((data, index) => (
                <div key={index} className="col-md-6 col-lg-4 mb-3">
                  <CurveCard {...data} />
                </div>
              ))}
            </div>
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Business;
