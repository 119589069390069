import React from "react";
import SignIn from "../../components/admin/auth/signIn";

const Login = () => {
  return (
    <>
      <SignIn />
    </>
  );
};

export default Login;
