import React from "react";
import "./team.css";
import teamBorder from "../../../assets/images/team-border.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Team = ({ teamData }) => {
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  var settingss = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 5000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container-fluid team-section">
      <div className="container team-container">
        <div className="team-header">
          <h1>
            Meet the Team:
            <span>
              Dedicated Individuals Committed to Your Businesses Success.
            </span>
            <img src={teamBorder} alt="" />
          </h1>
        </div>
        <div className="team-body pt-4">
          {teamData && teamData.length > 0 ? (
            <Slider {...settingss}>
              {teamData?.map((team, index) => (
                <div key={index} className="team-card-wrapper">
                  <div className="team-card">
                    <div className="team-card-content">
                      <div className="team-card-front">
                        <img src={imgUrl + team.member_image} alt="" />
                        <div className="team-profile">
                          <h1>
                            {team.member_name}
                            <span>{team.member_designation}</span>
                          </h1>
                        </div>
                      </div>
                      <div className="team-card-back">
                        <div className="team-card-back-content">
                          {team.member_description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <p className="text-center">No data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;
