import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";

const EditBillingWork = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [coverImageView, setCoverImageView] = useState(null);
  const [oldCoverImage, setOldCoverImage] = useState(null);

  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });
  let navigation = useNavigate();
  useEffect(() => {
    const fetchBlogPost = async (id) => {
      setSpinner(true);
      try {
        const response = await axios.get(`${baseUrl}/get-billing-work`);
        const solarServiceData = response?.data?.data?.billing_work;
        setSpinner(false);
        const solarService = solarServiceData.find(
          (service) => service.id == id
        );
        setFormData({
          title: solarService.title,
          content: solarService.content,
        });
        setOldCoverImage(solarService.icon);
        setCoverImageView(imgUrl + solarService.icon);
      } catch (error) {
        setSpinner(false);
        console.error("Error fetching metering & billing:", error);
        toast.error(
          "Failed to fetch metering & billing. Please try again later."
        );
      }
    };

    fetchBlogPost(id);
  }, [id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCoverFileChange = (e) => {
    const file = e.target.files[0];
    setCoverImagePreview(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImageView(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setCoverImageView(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    let coverImageURL = null;
    setSpinner(true);
    if (coverImagePreview) {
      try {
        const coverImageFormData = new FormData();
        coverImageFormData.append("files", coverImagePreview);
        const coverImageResponse = await axios.post(
          `${baseUrl}/upload_files`,
          coverImageFormData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        coverImageURL = coverImageResponse.data.data.files[0].path;
      } catch (error) {
        console.error("Error uploading metering & billing image:", error);
      }
    } else {
      coverImageURL = oldCoverImage;
    }

    async function convertBase64ToBlob(imagePreview) {
      const matches = imagePreview.match(
        /^data:image\/([a-zA-Z+]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        throw new Error("Invalid base64 image format");
      }
      const [_, imageType, imageData] = matches;

      const byteCharacters = atob(imageData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let j = 0; j < byteCharacters.length; j++) {
        byteNumbers[j] = byteCharacters.charCodeAt(j);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: `image/${imageType}` });

      return blob;
    }

    const title = e.target.title.value.trim();
    if (!title) {
      toast.error("Title is required.");
      setSpinner(false);
      return;
    }
    const postFormData = {
      id: id,
      icon: coverImageURL,
      title: e.target.title.value,
      content: e.target.content.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-billing-work`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Metering & Billing Updated successfully:", {
        onClose: () => navigation("/billingwork-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error editing metering & billing:", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Update Metering & Billing</h1>
              </div>
            </div>

            <div className="mb-4 form-field ">
              <label htmlFor="coverImageUpload" className="custom-file-upload">
                Select Image
              </label>
              <input
                id="coverImageUpload"
                type="file"
                className="form-control"
                onChange={handleCoverFileChange}
              />
            </div>
            {coverImageView && (
              <div className="mb-4 cover-image-view community-icon-viewer">
                <img src={coverImageView} alt="Cover Preview" />
              </div>
            )}
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="title here..."
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="Description here..."
                name="content"
                value={formData.content}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditBillingWork;
