const logoImage = require("../../../assets/images/logo-gif.gif");
const logoImageTwo = require("../../../assets/images/logo.png");
const closeMenu = require("../../../assets/images/close-menu.png");
const openMenu = require("../../../assets/images/open-menu.png");
const arrowDown = require("../../../assets/images/down-arrow.png");

export const navbarData = {
  logo: {
    link: "/",
    imageSrc: logoImage,
    imageSrcTwo: logoImageTwo,
    altText: "Example Image",
  },
  menuToggle: {
    openImageSrc: openMenu,
    closeImageSrc: closeMenu,
    altText: "Menu Toggle",
  },
  navbarLinks: [
    { text: "Home", to: "/" },
    { text: "About Us", to: "/about" },
    { text: "How it Works", to: "/work" },
    {
      text: "Services",
      to: "/service",
      imageSrc: arrowDown,
      submenu: [
        { text: "Grant Consulting", to: "/grant-consulting" },
        { text: "Financing Support", to: "/financing-support" },
      ],
    },
    { text: "Blog", to: "/blog" },
    { text: "Contact Us", to: "/contact-us" },
    { text: "Get started", to: "solar-system", className: "get-started" },
  ],
};
