const checkIcon = require("../../../assets/images/check-icon.png");

const ourmissionData = [
  {
    title: "Join Our Mission",
    descriptionTtile: "Partner with SunChoice:",
    descriptionContent:
      "Join Us to Improve Businesses and Strengthen Communities.",
    missionItems: [
      { icon1: checkIcon, text1: "Meaningful Impact" },
      { icon2: checkIcon, text2: "Innovative Environment" },
      { icon3: checkIcon, text3: "Personal and Professional Growth" },
    ],
    buttonText: "Get started",
    buttonIcon: true,
  },
];

export default ourmissionData;
