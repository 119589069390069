import React from "react";
import "./metering.css";
import headingBorder from "../../../assets/images/mission-heading.png";
import meteringImage from "../../../assets/images/metering-img.png";
import iconOne from "../../../assets/images/net-icon.png";
import iconTwo from "../../../assets/images/impact-icon-2.png";
import iconThree from "../../../assets/images/impact-icon-6.png";
import iconFour from "../../../assets/images/impact-icon-3.png";

const Metering = ({ billingData }) => {
  const midIndex = Math.floor(billingData.length / 2);
  const firstHalf = billingData.slice(0, midIndex);
  const secondHalf = billingData.slice(midIndex);
  return (
    <div className="container-fluid metering-section ">
      <div className="container metering-container">
        <div className="metering-container-header">
          <h1>
            <span>
              How Net Metering and <span>Billing Work</span>
            </span>
            <span>
              Metering and billing play crucial roles in the integration of
              solar energy into your business's operations.
            </span>
            <img src={headingBorder} alt="" />
          </h1>
        </div>
        <div className="metering-container-body pt-5">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="row metering-card-wrapper">
                {firstHalf.map((data) => (
                  <div className="col-md-6 col-lg-12 mb-3">
                    <div className="metering-card">
                      <img src={data.icon || iconOne} alt="" />
                      <h1>{data.title || "--"}</h1>
                      <p>{data.content || "--"}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="metering-image">
                <img src={meteringImage} alt="" />
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="row metering-card-wrapper">
                {secondHalf.map((data) => (
                  <div className="col-md-6 col-lg-12 mb-3">
                    <div className="metering-card">
                      <img src={data.icon || iconTwo} alt="" />
                      <h1>{data.title || "--"}</h1>
                      <p>{data.content || "--"}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metering;
