import React, { useState, useEffect } from "react";
import Reap from "../components/consulting/reap/reap";
import Banner from "../components/consulting/banner/banner";
import Grant from "../components/consulting/grant/grant";
import Question from "../components/consulting/question/question";
import Freedom from "../components/service/freedom/freedom";
import Blog from "../components/common/blogSection/blog";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import axios from "axios";
import Loader from "../components/common/loader/loader";
import checkIcon from "../assets/images/check-icon.png";
const Consulting = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const [spinner, setSpinner] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [reapData, setReapData] = useState([]);
  const [grantData, setGrantData] = useState([]);
  const [embraceFreedomData, setEmbraceFreedomData] = useState([]);
  useEffect(() => {
    fetchQuestionData();
  }, []);

  const fetchQuestionData = async () => {
    try {
      setSpinner(true);
      console.log("*********   API CALL - get-question-data - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-question-data`);
      const data = response?.data?.data?.questions;
      setQuestionData(data);
      fetchReapData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching question data:", error);
      setSpinner(false);
    }
  };
  const fetchReapData = async () => {
    try {
      console.log("*********   API CALL - get-reap-program - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-reap-program`);
      const data = response?.data?.data?.reap_programs;
      const transformedData = data.map((item) => ({
        ...item,
        image: imgUrl + item.image,
      }));
      setReapData(transformedData);
      
      fetchGrantData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching reap data:", error);
      setSpinner(false);
    }
  };
  const fetchGrantData = async () => {
    try {
      console.log("*********   API CALL - get-grant-program - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-grant-program`);
      const data = response?.data?.data?.grant_program;
      const transformedData = data.map((item) => ({
        ...item,
        icon: imgUrl + item.icon,
      }));
      setGrantData(transformedData);
      
      fetchEmbraceFreedomData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching grant program data:", error);
      setSpinner(false);
    }
  };
  const fetchEmbraceFreedomData = async () => {
    try {
      console.log("*********   API CALL - get-aims-card - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-aims-card`);
      const data = response?.data?.data?.aims_cards;
      const missionAimCard = data.filter((data) => data.type === "freedom");
      const transformedData = missionAimCard.map((item) => ({
        ...item,
        mission_items: item.mission_items ? JSON.parse(item.mission_items) : [],
        buttonContent: "Get started",
        icon: checkIcon,
      }));
      setEmbraceFreedomData(transformedData);
      setSpinner(false);
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching embrace freedom data:", error);
      setSpinner(false);
    }
  };
  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      <Banner />
      <Reap reapData={reapData} />
      <Grant grantData={grantData} />
      <Question questionData={questionData} />
      <Freedom embraceFreedomData={embraceFreedomData} />
      <Blog />
    </>
  );
};

export default Consulting;
