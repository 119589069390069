import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";

const EditHomeBanner = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    description: "",
  });
  let navigation = useNavigate();
  useEffect(() => {
    const fetchHomeBanner = async (id) => {
      setSpinner(true);
      try {
        const response = await axios.get(`${baseUrl}/get-slider`);
        const homeBannerData = response.data.data.sliders;
        setSpinner(false);
        const homeBannerValue = homeBannerData.find((slide) => slide.id == id);
        setFormData({
          title: homeBannerValue.title,
          subtitle: homeBannerValue.subtitle,
          description: homeBannerValue.description,
        });
      } catch (error) {
        setSpinner(false);
        console.error("Error fetching home banner data:", error);
        toast.error(
          "Failed to fetch home banner data. Please try again later."
        );
      }
    };

    fetchHomeBanner(id);
  }, [id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    setSpinner(true);
    const title = e.target.title.value.trim();
    const subtitle = e.target.subtitle.value.trim();
    const description = e.target.description.value.trim();

    if (!title || !subtitle || !description) {
      toast.error("All fields required");
      setSpinner(false);
      return;
    }
    const postFormData = {
      id: id,
      title: e.target.title.value,
      subtitle: e.target.subtitle.value,
      description: e.target.description.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-update-slider`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Home Banner Updated successfully:", {
        onClose: () => navigation("/homebanner-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error editing home banner", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Update Home Banner</h1>
              </div>
            </div>

            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="title here..."
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="subtitle here..."
                name="subtitle"
                value={formData.subtitle}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="description here..."
                name="description"
                value={formData.description}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditHomeBanner;
