const icon = require("../../../assets/images/card-icon.png");
const icon1 = require("../../../assets/images/card-icon2.png");
const icon2 = require("../../../assets/images/card-icon3.png");
export const missionData = [
  {
    iconImage: icon,
    title: "Empowering Businesses, Strengthening Communities",
    content:
      "Our mission is to lead businesses towards sustainable energy solutions, fostering community strength, economic growth, and self-sufficiency.",
  },
  {
    iconImage: icon1,
    title: "Guided by Core Principles.",
    content:
      "At SunChoice, we hold steadfast to Clear Integrity, Accountability, Respect, and Empathy. These principles illuminate our path in every interaction and decision.",
  },
  {
    iconImage: icon2,
    title: "Local Roots, National Impact.",
    content:
      "From small town beginnings, we've expanded our reach to empower businesses nationwide. We embody our values and commit to building local teams in every territory we serve.",
  },
];
