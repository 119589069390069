import React, { useState, useEffect } from "react";
import "./listLocation.css";
import axios from "axios";
import { NavLink } from "react-router-dom";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../../blog/blogs/pagination";
import Loader from "../../common/loader/loader";
import ExcelToJsonConverter from "../excelFile/excelFile";
import * as XLSX from "xlsx";
const ListLocation = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // loader
  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationPerPage, setLocationPerPage] = useState(12);
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("grant");
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchData();
    filterDataByType("grant");
  }, []);
  const fetchData = async () => {
    setSpinner(true);
    try {
      const response = await axios.get(`${baseUrl}/get-location-data`, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      });
      const data = response?.data?.data?.location;

      setSpinner(false);
      setOriginalData(data);
      const filtered = data.filter((item) => item.type === "grant");
      setFilteredData(filtered);
    } catch (error) {
      setError(error.message);
    }
  };
  const deleteData = async (locationId) => {
    setSpinner(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage.getItem("token"),
        },
        body: new URLSearchParams({ location_id: locationId }).toString(),
      };

      const response = await fetch(
        `${baseUrl}/delete-location-data`,
        requestOptions
      );
      setSpinner(false);
      // toast.success("Location deleted successfully!");
      fetchData();
    } catch (error) {
      setSpinner(false);
    }
  };

  const filterDataByType = (type) => {
    const filtered = originalData.filter((data) => data.type === type);
    setFilteredData(filtered);
    setActiveFilter(type);
    setCurrentPage(1);
  };
  // search filter
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  // Calculate the index range for the current page
  const indexOfLastLocation = currentPage * locationPerPage;
  const indexOfFirstLocation = indexOfLastLocation - locationPerPage;
  // Function to filter data based on search query
  const filterDataBySearch = (data) => {
    return data.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  // Filtered data based on search query
  const filteredDataBySearch = filterDataBySearch(filteredData);
  const currentLocation = filteredDataBySearch.slice(
    indexOfFirstLocation,
    indexOfLastLocation
  );

  // Handle page change
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle blogs per page change
  const onLocationPerPageChange = (perPage) => {
    setLocationPerPage(perPage);
    setCurrentPage(1); // Reset to the first page when changing blogs per page
  };
  // Handle next page
  const onNextPage = () => {
    //this line get total number of pages needed
    const totalPages = Math.ceil(filteredDataBySearch.length / locationPerPage);
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  // Handle previous page
  const onPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  const totalLocation = filteredDataBySearch.length;
  const resultsStart = (currentPage - 1) * locationPerPage + 1;
  const resultsEnd = Math.min(currentPage * locationPerPage);
  // Convert data to Excel format
  const convertToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(originalData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Locations");
    XLSX.writeFile(wb, "locations.xlsx");
  };
  return (
    <>
      {spinner && <Loader />}
      <div className="admin-wrapper">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="container-fluid location-section">
          <div className="location-headers">
            <h1>All Location</h1>
            <div className="location-right">
              <button onClick={convertToExcel}>Export Location Data</button>
              <NavLink to="/add-location">Add Location</NavLink>
            </div>
          </div>

          <div className="upload-sheet-section">
            <ExcelToJsonConverter
              fetchData={fetchData}
              setIsLoading={setSpinner}
            />
          </div>
          <div className="location-tab-wrapper mt-3">
            <div className="location-tab-left">
              <button
                className={activeFilter === "grant" ? "active" : ""}
                onClick={() => filterDataByType("grant")}
              >
                Grant Applicant
              </button>
              <button
                className={activeFilter === "awardgrant" ? "active" : ""}
                onClick={() => filterDataByType("awardgrant")}
              >
                Grants Awarded
              </button>
            </div>
            <div className="location-tab-right">
              <input
                type="text"
                placeholder="Search here..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="pagination-header mt-3">
            <h1>
              {totalLocation}
              <span>
                {" "}
                {activeFilter === "grant"
                  ? "Grant Applicant"
                  : "Award Grant Applicant"}
              </span>
            </h1>
            <div className="dropdown-pagination">
              <h2>Location per Page:</h2>
              <select
                className="form-select"
                value={locationPerPage}
                onChange={(e) =>
                  onLocationPerPageChange(parseInt(e.target.value, 10))
                }
              >
                {[12, 14, 16, 18].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="location-table-wrapper mt-3">
            <div className="table-header">
              <div className="table-cell">Name</div>
              <div className="table-cell">Address</div>
              <div className="table-cell">City</div>
              <div className="table-cell">State</div>
              <div className="table-cell">ZipCode</div>
              {activeFilter === "awardgrant" && (
                <div className="table-cell">Amount</div>
              )}
              <div className="table-cell">Type</div>
              <div className="table-cell">Action</div>
            </div>
            {filteredData.length === 0 ? (
              <p className="text-center">No data available.</p>
            ) : (
              <>
                {currentLocation?.map((data, index) => (
                  <div key={index} className="table-row">
                    <div className="table-cell" data-heading={data.applicant}>
                      {data.applicant || "--"}
                    </div>
                    <div className="table-cell" data-heading={data.address}>
                      {data.address || "--"}
                    </div>
                    <div className="table-cell" data-heading={data.city}>
                      {data.city || "--"}
                    </div>
                    <div className="table-cell" data-heading={data.state}>
                      {data.state || "--"}
                    </div>
                    <div className="table-cell" data-heading={data.zipCode}>
                      {data.zipCode || "--"}
                    </div>
                    {activeFilter === "awardgrant" && (
                      <div className="table-cell" data-heading={data.amount}>
                        {data.amount || "--"}
                      </div>
                    )}
                    <div className="table-cell" data-heading={data.type}>
                      {data.type || "--"}
                    </div>
                    <div className="table-cell " data-heading="Action">
                      <div className="table-cell-action">
                        <NavLink to={`/add-location/${data.id}`}>
                          <img className="update-data" src={EditIcon} alt="" />
                        </NavLink>
                        <NavLink>
                          <img
                            src={DeleteIcon}
                            alt=""
                            onClick={() => {
                              deleteData(data.id);
                            }}
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="pagination-footer">
            <div className="pagination-value">
              <p>
                Results {resultsStart} to {resultsEnd} of {totalLocation}
              </p>
            </div>
            <div className="pagination-counter">
              <button
                className=""
                onClick={onPrevPage}
                disabled={currentPage === 1}
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Previous
              </button>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(filteredData.length / locationPerPage)}
                onPageChange={onPageChange}
                locationPerPage={locationPerPage}
              />
              <button
                className=""
                onClick={onNextPage}
                disabled={
                  currentPage ===
                  Math.ceil(filteredData.length / locationPerPage)
                }
              >
                Next
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListLocation;
