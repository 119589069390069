import React from "react";
import "./banner.css";
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <div className="container-fluid services-section support-section top-wrapper">
      <div className="container-fluid services-container">
        <div className="services-container-header">
          <h1>
            The Future of Solar Energy:
            <span> Trends and Innovations </span>
          </h1>
        </div>
        <div className="services-container-body">
          <p>
            rom advancements in solar panel efficiency to the rise of smart
            solar solutions, we uncover how businesses can leverage these
            developments to harness the full potential of solar power. Stay
            tuned to learn more about the exciting future of solar energy and
            how it can empower your business towards a sustainable and
            energy-efficient future.
          </p>
        </div>
        <div className="services-container-footer">
          <NavLink to="/solar-system">Get started </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Banner;
