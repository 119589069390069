import React from "react";
import Aimcard from "../../common/airmCard/aimcard";
import ourmissionData from "./ourmissionData";

const Ourmission = ({ embraceFreedomData }) => {
  return (
    <>
      {embraceFreedomData.map((data, index) => (
        <Aimcard key={index} {...data} />
      ))}
    </>
  );
};

export default Ourmission;
