import React, { useState } from "react";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";

const ExcelToJsonConverter = (props) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [jsonData, setJsonData] = useState(null);
  const [dataType, setDataType] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setJsonData(excelData);
      setDataType(null); // Reset data type selection
    };

    reader.readAsArrayBuffer(file);
    setSelectedFileName(file.name);
  };

  const handleTypeChange = (e) => {
    setDataType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!jsonData || !dataType) {
      toast.error("Please upload Excel data and select data type first.");
      return;
    }

    props.setIsLoading(true);
    try {
      const filteredData = jsonData.filter(
        (item) => item[1] && item[2] && item[3] && item[4] && item[5]
      );
      const mappedData = filteredData.map((item) => ({
        applicant: item[1],
        address: item[2],
        city: item[3],
        state: item[4],
        zipCode: item[5],
        type: dataType || "",
      }));
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mappedData), // Convert to JSON string
      };

      const url = `${baseUrl}/add-multiple-location`;
      const response = await fetch(url, requestOptions);
      props.setIsLoading(false);

      if (response.ok) {
        // toast.success("Location data added successfully!");
        props.fetchData();
        setJsonData(null);
        setDataType(null);
        setSelectedFileName("");
        document.getElementById("fileInput").value = "";
      } else {
        const errorData = await response.text();
        throw new Error(errorData || "Failed to add location data.");
      }
    } catch (error) {
      props.setIsLoading(false);
      toast.error(`Failed to add location data. ${error.message}`);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="container-fluid px-0 file-upload-wrapper pt-3">
        <div className="custom-file-input">
          <input
            type="file"
            className="input-file"
            id="fileInput"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
          />
          <label htmlFor="fileInput">
            {selectedFileName || "Choose File To Upload Location"}
          </label>
        </div>
        {jsonData && (
          <div className="file-control-wrapper">
            <h2>ChooseType:</h2>
            <div className="select-type-wrapper">
              <select value={dataType} onChange={handleTypeChange}>
                <option value="">Select Type</option>
                <option value="grant">Grant</option>
                <option value="awardgrant">Award Grant</option>
              </select>
              <button onClick={handleSubmit}>Upload</button>
            </div>

            {/* <h2>Converted JSON Data:</h2>
            <pre>
              {JSON.stringify(
                jsonData.map((item) => ({
                  applicant: item[1] || "",
                  address: item[2] || "",
                  city: item[3] || "",
                  state: item[4] || "",
                  zipCode: item[5] || "",
                  type: dataType || "",
                })),
                null,
                2
              )}
            </pre> */}
          </div>
        )}
      </div>
    </>
  );
};

export default ExcelToJsonConverter;
