import React, { useState, useEffect } from "react";
import "./Service.css";
import sun from "../../../assets/images/sun-tick.png";
import bluearrrow from "../../../assets/images/active-tab.png";
import grayarrrow from "../../../assets/images/inactive-tab.png";
import Icon from "../../../assets/images/tabs-icon.png";
import textng from "../../../assets/images/text-background.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Service = ({ serviceData }) => {
  const [activeTab, setActiveTab] = useState(null);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  useEffect(() => {
    if (serviceData && serviceData.length > 0) {
      setActiveTab(serviceData[0].id);
    }
  }, [serviceData]);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
  return (
    <div className="container-fluid service-main-section">
      <div className="container">
        <div
          className="service-heading-header animated-element"
          data-aos="fade-up"
        >
          <div className="service-heading-content">
            <h1>SunChoice</h1>
            <h2>is a Solar as a Service Company.</h2>
          </div>

          <img src={sun} alt="Missing" />
        </div>

        <div className="service-card-main">
          <Tab
            data={serviceData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
        </div>
      </div>
    </div>
  );
};
function Tab({ data, activeTab, handleTabClick }) {
  return (
    <div className="service-card-section animated-element" data-aos="zoom-in">
      <div className="service-card-main">
        {data.map((tab) => (
          <div
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={
              activeTab === tab.id ? "service-card active" : "service-card"
            }
          >
            <div className="service-card-body">
              <div className="card-body-title">
                {activeTab === tab.id && <img src={Icon} alt="Missing Image" />}
                <p>{tab.tab_title}</p>
              </div>
              <div className="card-body-image">
                {activeTab === tab.id ? (
                  <img src={bluearrrow} alt="Active arrow Missing" />
                ) : (
                  <img src={grayarrrow} alt="Inactive arrow Missing" />
                )}
              </div>
            </div>
            <div
              className={
                activeTab === tab.id ? "card-border active" : "card-border"
              }
            ></div>
          </div>
        ))}
      </div>
      <div className="service-content-main">
        {data.map((tab) => (
          <div
            key={tab.id}
            className="service-tab-content"
            style={activeTab === tab.id ? {} : { display: "none" }}
          >
            <div className="service-company-tab">
              <div className="service-company-tab-left">
                <img src={textng} alt="" />
                <div className="service-company-tab-left-content">
                  <div className="service-company-tab-left-header">
                    <h1>{tab.tab_sub_title || "--"}</h1>
                  </div>
                  <div className="service-company-tab-left-mobile"></div>
                  <div className="service-company-tab-left-body">
                    <p>{tab.tab_content || "--"}</p>
                  </div>
                  <div className="service-company-tab-left-mobile-two"></div>
                </div>
              </div>
              <div className="service-company-tab-right">
                <img src={tab.image} alt="Image" />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* mobile view */}

      <div className="service-card-main-sm">
        {data.map((tab) => (
          <div
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={
              activeTab === tab.id
                ? "service-card-sm active"
                : "service-card-sm"
            }
          >
            <div className="service-card-body">
              <div className="card-body-title">
                {activeTab === tab.id && <img src={Icon} alt="Missing Image" />}
                <p>{tab.tab_title}</p>
              </div>
              <div className="card-body-image">
                {activeTab === tab.id ? (
                  <img src={bluearrrow} alt="Active arrow Missing" />
                ) : (
                  <img src={grayarrrow} alt="Inactive arrow Missing" />
                )}
              </div>
            </div>
            <div
              className="service-tab-content"
              style={activeTab === tab.id ? {} : { display: "none" }}
            >
              <div className="service-company-tab">
                <div className="service-company-tab-left">
                  <img src={textng} alt="" />
                  <div className="service-company-tab-left-content">
                    <div className="service-company-tab-left-header">
                      <h1>{tab.tab_sub_title}</h1>
                    </div>
                    <div className="service-company-tab-left-mobile"></div>
                    <div className="service-company-tab-left-body">
                      <p>{tab.tab_content}</p>
                    </div>
                    <div className="service-company-tab-left-mobile-two"></div>
                  </div>
                </div>
                <div className="service-company-tab-right">
                  <img src={tab.image} alt="Missing Image" />
                </div>
              </div>
            </div>
            <div
              className={
                activeTab === tab.id ? "card-border active" : "card-border"
              }
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Service;
