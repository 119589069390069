import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
import "./setting.css";
import axios from "axios";

const Term = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleSendToAPI = async () => {
    const { token } = localStorage;
    const trimmedEditorData = editorData.trim();
    if (!trimmedEditorData) {
      toast.error("Please add content");
      return;
    }
    setSpinner(true);
    const requestBody = {
      type: "terms",
      description: trimmedEditorData,
    };

    try {
      await axios.post(`${baseUrl}/add-admin-content`, requestBody, {
        headers: {
          Authorization: token,
        },
      });
      setSpinner(false);
      toast.success("Content sent successfully");
    } catch (error) {
      setSpinner(false);
      if (error.response) {
        toast.error("Failed to send content. Please try again later.");
      }
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid editor-wrapper">
          <div className="location-header">
            <h1>Terms and Condition</h1>
          </div>
          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onChange={handleEditorChange}
          />
          <div className="submit-btn-wrapper mt-3">
            <button onClick={handleSendToAPI}>Save Content</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Term;
