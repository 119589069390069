const footerData = [
  {
    items: [
      { text: "Home", link: "/" },
      { text: "About Us", link: "/about" },
      { text: "How it Works", link: "/work" },
      { text: "Services", link: "/service" },
      { text: "Grant Consulting", link: "/grant-consulting" },
      { text: "Financing Support", link: "/financing-support" },
      { text: "Blog", link: "/blog" },
      { text: "Contact Us", link: "/contact-us" },
      { text: "Instagram", link: "/instagram" },
      { text: "Facebook", link: "/facebook" },
      { text: "Twitter", link: "/twitter" },
      { text: "LinkedIn", link: "/linkedin" },
      { icon: "../../../assets/images/insta-icon.svg", link: "/instagram" },
      { icon: "../../../assets/images/facebook-icon.svg", link: "/facebook" },
      { icon: "../../../assets/images/twitter-icon.svg", link: "/twitter" },
      { icon: "../../../assets/images/linkedin-icon.svg", link: "/linkedin" },
    ],
  },
  {
    items: [
      { text: "Terms and Conditions", link: "/term" },
      {
        text: "Better Business Bureau Accreditation",
        link: "/accreditation",
      },
      { text: "Privacy Statement", link: "/privacy" },
    ],
  },
];
export default footerData;
