import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const EditBlog = () => {
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [coverImageView, setCoverImageView] = useState(null);
  const [contentImagePreviews, setContentImagePreviews] = useState([]);
  const [contentImages, setContentImages] = useState([]);
  const [oldCoverImage, setOldCoverImage] = useState(null);
  const [blogContent, setBlogContent] = useState([
    { heading: "", description: "", post_image: null },
  ]);

  const [formData, setFormData] = useState({
    type: "",
    reading_time: "",
    title: "",
    subtitle: "",
    concise_summary: "",
  });
  let navigation = useNavigate();
  useEffect(() => {
    const fetchBlogPost = async (id) => {
      setSpinner(true);
      try {
        const response = await axios.get(`${baseUrl}/get-post-data`);
        const postData = response.data.data.blog;
        setSpinner(false);
        const post = postData.find((post) => post.id == id);
        setFormData({
          type: post.type,
          reading_time: post.reading_time,
          title: post.title,
          subtitle: post.subtitle,
          concise_summary: post.concise_summary,
        });
        setOldCoverImage(post.cover_image);
        setCoverImageView(imgUrl + post.cover_image);
        const blogContentArray = JSON.parse(`[${post.blog_content}]`);
        setBlogContent(blogContentArray);
        const postImages = blogContentArray.map(
          (content) => content.post_image
        );
        setContentImages(postImages);
      } catch (error) {
        setSpinner(false);
        console.error("Error fetching blog post:", error);
        toast.error("Failed to fetch blog post data. Please try again later.");
      }
    };

    fetchBlogPost(id);
  }, [id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCoverFileChange = (e) => {
    const file = e.target.files[0];
    setCoverImagePreview(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImageView(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setCoverImageView(null);
    }
  };

  const handleContentFileChange = (index, e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const updatedPreviews = [...contentImagePreviews];
        updatedPreviews[index] = reader.result;
        setContentImagePreviews(updatedPreviews);

        const updatedContent = [...blogContent];
        updatedContent[index].imagePreview = reader.result;
        setBlogContent(updatedContent);
      };
      reader.readAsDataURL(file);
    } else {
      const updatedContent = [...blogContent];
      updatedContent[index].imagePreview = null;
      setBlogContent(updatedContent);
    }
  };

  const handleAddMoreContent = () => {
    setBlogContent([
      ...blogContent,
      { heading: "", description: "", post_image: null },
    ]);
  };

  const handleContentChange = (index, key, value) => {
    const updatedContent = [...blogContent];
    updatedContent[index][key] = value;
    setBlogContent(updatedContent);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    let coverImageURL = null;
    setSpinner(true);
    if (coverImagePreview) {
      try {
        const coverImageFormData = new FormData();
        coverImageFormData.append("files", coverImagePreview);
        const coverImageResponse = await axios.post(
          `${baseUrl}/upload_files`,
          coverImageFormData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        coverImageURL = coverImageResponse.data.data.files[0].path;
      } catch (error) {
        console.error("Error uploading cover image:", error);
      }
    } else {
      coverImageURL = oldCoverImage;
    }

    async function convertBase64ToBlob(imagePreview) {
      const matches = imagePreview.match(
        /^data:image\/([a-zA-Z+]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        throw new Error("Invalid base64 image format");
      }
      const [_, imageType, imageData] = matches;

      const byteCharacters = atob(imageData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let j = 0; j < byteCharacters.length; j++) {
        byteNumbers[j] = byteCharacters.charCodeAt(j);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: `image/${imageType}` });

      return blob;
    }

    const contentImageURLs = [];
    for (let i = 0; i < blogContent.length; i++) {
      const content = blogContent[i];
      if (content.imagePreview) {
        try {
          const blob = await convertBase64ToBlob(content.imagePreview);
          const contentImageFormData = new FormData();
          contentImageFormData.append("files", blob);
          const contentImageResponse = await axios.post(
            `${baseUrl}/upload_files`,
            contentImageFormData,
            {
              headers: {
                Authorization: token,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          contentImageURLs.push(contentImageResponse.data.data.files[0].path);
        } catch (error) {
          console.error("Error uploading content image:", error);
        }
      } else {
        contentImageURLs.push(contentImages);
      }
    }

    const title = e.target.title.value.trim();
    if (!title) {
      toast.error("Blog title is required.");
      setSpinner(false);
      return;
    }
    const postFormData = {
      blogId: id,
      cover_image: coverImageURL,
      type: e.target.type.value,
      reading_time: e.target.reading_time.value,
      title: e.target.title.value,
      subtitle: e.target.subtitle.value,
      concise_summary: e.target.concise_summary.value,
      content: blogContent.map((content, index) => ({
        heading: content.heading,
        description: content.description,
        post_image: contentImageURLs[index],
      })),
    };

    try {
      const response = await axios.post(
        `${baseUrl}/edit-post-data`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Blog edit successfully:", {
        onClose: () => navigation("/blog-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error editing blog:", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Edit Blog</h1>
              </div>
            </div>
            <div className="mb-4 add-blog-heading">
              <h1>Banner Content</h1>
            </div>
            <div className="mb-4 form-field ">
              <label htmlFor="coverImageUpload" className="custom-file-upload">
                Select Cover Image
              </label>
              <input
                id="coverImageUpload"
                type="file"
                className="form-control"
                onChange={handleCoverFileChange}
              />
            </div>
            {coverImageView && (
              <div className="mb-4 cover-image-view">
                <img src={coverImageView} alt="Cover Preview" />
              </div>
            )}
            <div className="mb-4 form-field ">
              <select
                className="form-control"
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select Type...</option>
                <option value="finance">Finance</option>
                <option value="guide">Guide</option>
                <option value="values">Values</option>
              </select>
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Reading time here..."
                name="reading_time"
                value={formData.reading_time}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Title here..."
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Sub Title here..."
                name="subtitle"
                value={formData.subtitle}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Write a concise summary..."
                name="concise_summary"
                value={formData.concise_summary}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 add-blog-heading">
              <h1>Body Detail Content</h1>
            </div>
            <div className="blog-content-more">
              {blogContent.map((content, index) => (
                <div key={index} className="blog-content-more-inner mb-4">
                  <div className="mb-4 form-field ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Heading here..."
                      value={content.heading}
                      onChange={(e) =>
                        handleContentChange(index, "heading", e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4 form-field ">
                    <CKEditor
                      editor={ClassicEditor}
                      data={content.description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleContentChange(index, "description", data);
                      }}
                    />
                  </div>
                  <div className="mb-4 form-field ">
                    <label
                      htmlFor={`contentImageUpload-${index}`}
                      className="custom-file-upload"
                    >
                      Upload Images
                    </label>
                    <input
                      id={`contentImageUpload-${index}`}
                      type="file"
                      className="form-control"
                      onChange={(e) => handleContentFileChange(index, e)}
                    />
                  </div>
                  {content.imagePreview || content.post_image ? (
                    <div className="mb-4 cover-image-view">
                      <img
                        src={
                          content.imagePreview || imgUrl + content.post_image
                        }
                        alt="Content Preview"
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            <div className="form-field more-btn">
              <button type="button" onClick={handleAddMoreContent}>
                +
              </button>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditBlog;
