import React, { useEffect, useState } from "react";
import Solar from "../components/home/solar/solar";
import Risk from "../components/home/risk/risk";
import Misson from "../components/home/mission/misson";
import Value from "../components/home/values/value";
import Banner from "../components/home/banner/banner";
import Service from "../components/home/service/service";
import Map from "../components/home/map/map";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import Blog from "../components/common/blogSection/blog";
import Loader from "../components/common/loader/loader";
import axios from "axios";

const Home = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const [spinner, setSpinner] = useState(false);
  const [mapData, setMapData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [freedomData, setFreedomData] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [baseUrl]);

  const call2 = async () => {
    try {
      console.log(
        "*********   API CALL - get-slider - Success ******** " + Date.now()
      );
      const response = await axios.get(`${baseUrl}/get-slider`);
      const bannerData = response?.data?.data?.sliders;
      setBannerData(bannerData);
      localStorage.setItem("bannerData", JSON.stringify(bannerData));
      call3();
    } catch (error) {
      console.log(
        "*********   API CALL - get-slider - Failed ******** " + Date.now()
      );
      console.error("Error fetching banner data:", error);
      const bannerData = JSON.parse(localStorage.getItem("bannerData") || "[]");
      setBannerData(bannerData);
    }
  };

  const call3 = async () => {
    try {
      console.log(
        "*********   API CALL - get-freedom - Success ******** " + Date.now()
      );
      const response = await axios.get(`${baseUrl}/get-freedom`);
      const freedomData = response?.data?.data?.freedom_tabs;
      setFreedomData(freedomData);
      localStorage.setItem("freedomData", JSON.stringify(freedomData));
      call4();
    } catch (error) {
      console.log(
        "*********   API CALL - get-freedom - Failed ******** " + Date.now()
      );
      console.error("Error fetching freedom data:", error);
      const freedomData = JSON.parse(
        localStorage.getItem("freedomData") || "[]"
      );
      setFreedomData(freedomData);
    }
  };
  const call4 = async () => {
    try {
      console.log(
        "*********   API CALL - get-service - Success ******** " + Date.now()
      );
      const response = await axios.get(`${baseUrl}/get-service`);
      const data = response?.data?.data?.service_company;
      const transformedData = data.map((item) => ({
        ...item,
        image: imgUrl + item.image,
      }));
      setServiceData(transformedData);
      localStorage.setItem("serviceData", JSON.stringify(transformedData));
    } catch (error) {
      console.log(
        "*********   API CALL - get-service - Failed ******** " + Date.now()
      );
      console.error("Error fetching service data:", error);
      const serviceData = JSON.parse(
        localStorage.getItem("serviceData") || "[]"
      );
      setServiceData(serviceData);
    }
  };
  const fetchData = async () => {
    setSpinner(true);
    try {
      try {
        console.log(
          "*********   API CALL - get-location-data - Success ******** " +
            Date.now()
        );
        const response = await axios.get(`${baseUrl}/get-location-data`);
        const mapData = response?.data?.data?.location;
        setMapData(mapData);
        localStorage.setItem("mapData", JSON.stringify(mapData));
        call2();
      } catch (error) {
        console.log(
          "*********   API CALL - get-location-data - Failed ******** " +
            Date.now()
        );
        console.error("Error fetching map data:", error);
        const mapData = JSON.parse(localStorage.getItem("mapData") || "[]");
        setMapData(mapData);
      }
    } catch (error) {
      console.error("Error in fetchData:", error);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      <Banner bannerData={bannerData} />
      <Solar freedomData={freedomData} />
      <Service serviceData={serviceData} />
      <Map mapData={mapData} />
      <Risk />
      <Misson />
      <Value />
      <Blog />
    </>
  );
};

export default Home;
