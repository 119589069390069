import React from "react";
import "./cardCommon.css";
import { NavLink } from "react-router-dom";

const CardCommon = (props) => {
  return (
    <div className={`card-common ${props.customClass}`}>
      <div className="card-common-content">
        {props.iconImage && (
          <div className="card-common-counter-wrapper">
            <div className="card-common-counter">
              <img src={props.iconImage} alt="" />
              {props.index && <h1>{props.index}</h1>}
            </div>
          </div>
        )}
        <div className="card-common-header">
          <h1>
            {props.icon && <img src={props.icon} alt="" />}
            {props.title}
          </h1>
        </div>
        <div className="card-common-body">
          <p>{props.content}</p>
          {props.list && (
            <ul>
              {props.list.map((lists, index) => (
                <li key={index}>
                  <svg
                    width="18"
                    height="13"
                    viewBox="0 0 18 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.95067 9.36152L14.9462 0.416909C15.2257 0.138969 15.5815 0 16.0135 0C16.4454 0 16.8012 0.138969 17.0807 0.416909C17.3602 0.694849 17.5 1.04859 17.5 1.47813C17.5 1.90768 17.3602 2.26142 17.0807 2.53936L7.01794 12.5452C6.713 12.8484 6.35725 13 5.95067 13C5.5441 13 5.18834 12.8484 4.88341 12.5452L0.919282 8.6035C0.63976 8.32556 0.5 7.97182 0.5 7.54227C0.5 7.11273 0.63976 6.75899 0.919282 6.48105C1.1988 6.20311 1.55456 6.06414 1.98655 6.06414C2.41853 6.06414 2.77429 6.20311 3.05381 6.48105L5.95067 9.36152Z"
                      fill="#007BFF"
                    />
                  </svg>

                  {lists}
                </li>
              ))}
            </ul>
          )}
          {props.image && <img src={props.image} alt="" />}
        </div>
      </div>
      {props.buttonContent && (
        <div className="card-common-footer">
          <NavLink to="/solar-system">{props.buttonContent}</NavLink>
        </div>
      )}
    </div>
  );
};

export default CardCommon;
