import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";

const EditContact = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone_number: "",
    address: "",
  });
  let navigation = useNavigate();
  useEffect(() => {
    const fetchContactData = async (id) => {
      setSpinner(true);
      try {
        const response = await axios.get(`${baseUrl}/get-findus`);
        const contactData = response.data.data.find_us;
        setSpinner(false);
        const contactValue = contactData.find((cont) => cont.id == id);
        setFormData({
          email: contactValue.email,
          phone_number: contactValue.phone_number,
          address: contactValue.address,
        });
      } catch (error) {
        setSpinner(false);
        console.error("Error fetching contact data:", error);
        toast.error("Failed to fetch contact data. Please try again later.");
      }
    };

    fetchContactData(id);
  }, [id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    setSpinner(true);
    const email = e.target.email.value.trim();
    const phone_number = e.target.phone_number.value.trim();
    const address = e.target.address.value.trim();

    if (!email && !phone_number && !address) {
      toast.error(
        "At least one of email, phone number, or address must be entered."
      );
      setSpinner(false);
      return;
    }
    const postFormData = {
      id: id,
      email: e.target.email.value,
      phone_number: e.target.phone_number.value,
      address: e.target.address.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-update-findus`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Contact Updated successfully:", {
        onClose: () => navigation("/contact-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error editing contact", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Update Contact Detail</h1>
              </div>
            </div>

            <div className="mb-4 form-field ">
              <input
                type="email"
                className="form-control"
                placeholder="email here..."
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="phone here..."
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="address here..."
                name="address"
                value={formData.address}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditContact;
