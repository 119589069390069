import React, { useState } from "react";
import "./banner.css";
import headingBorder from "../../../assets/images/heading-border.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contactIcon1 from "../../../assets/images/mailIcon.png";
import contactIcon2 from "../../../assets/images/phoneIcon.png";
import contactIcon3 from "../../../assets/images/locationIcon.png";
const Banner = ({ contactData }) => {
  console.log(contactData);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
    setErrors({ ...errors, [e.target.name]: "" });
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", email: "", message: "" };

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }
    if (!formData.message) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        // console.log(urlencoded)
        // return
        urlencoded.append("email", formData.email);
        urlencoded.append("name", formData.name);
        urlencoded.append("message", formData.message);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded.toString(),
          redirect: "follow",
        };

        const response = await fetch(
          "https://sunchoice.us:1090/api/send-mail",
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Message send failed");
        }

        const result = await response.text();

        toast.success("Message sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      } catch (error) {
        toast.error("Message send failed. Please try again.");
      }
    } else {
      toast.error("Please fill all the required fields correctly.");
    }
  };

  return (
    <div className="container-fluid contact-section top-wrapper">
      <ToastContainer position="top-right" autoClose={5000} theme="colored" />
      <div className="container contact-container">
        <div className="contact-container-left">
          <h1>
            Get in touch with <span>SunChoice</span> for all your solar energy
            inquiries and needs
          </h1>
          <p>
            Our team is here to provide assistance and answer any questions you
            may have about solar solutions for your business. Whether you're
            interested in installation, grants, financing, or simply want to
            learn more, we're ready to help. Reach out to us today!
          </p>
        </div>
        <div className="contact-container-right">
          <form onSubmit={handleSubmit}>
            <div className="mb-3  form-field-contact">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Your name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && (
                <div className="error-message">{errors.name}</div>
              )}
            </div>
            <div className="mb-3  form-field-contact">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="your@gmail.com"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <div className="error-message">{errors.email}</div>
              )}
            </div>
            <div className="mb-3  form-field-contact">
              <label>How can we help?</label>
              <textarea
                className="form-control"
                placeholder="Tell us a little about your destination dream"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
              {errors.message && (
                <div className="error-message">{errors.message}</div>
              )}
            </div>
            <div className="form-field pt-4">
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
      <div className="container findus-container">
        <div className="common-heading-border-large">
          <h1>
            Find Us
            <img src={headingBorder} alt="" />
          </h1>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4 mb-3">
            <div className="card-common contact-card">
              <div className="card-common-content">
                <div className="card-common-header">
                  <h1>
                    <img src={contactIcon1} alt="" />
                    Email
                  </h1>
                </div>
                <div className="card-common-body pt-3">
                  <ul>
                    {contactData.map((data, index) => (
                      <li key={index}>{data?.email || "-"}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="card-common contact-card">
              <div className="card-common-content">
                <div className="card-common-header">
                  <h1>
                    <img src={contactIcon2} alt="" />
                    Phone
                  </h1>
                </div>
                <div className="card-common-body pt-3">
                  <ul>
                    {contactData.map((data, index) => (
                      <li key={index}>{data?.phone_number || "-"}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="card-common contact-card">
              <div className="card-common-content">
                <div className="card-common-header">
                  <h1>
                    <img src={contactIcon3} alt="" />
                    Address
                  </h1>
                </div>
                <div className="card-common-body pt-3">
                  <ul>
                    {contactData.map((data, index) => (
                      <li key={index}>{data?.address || ""}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
