import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
import { NavLink } from "react-router-dom";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
const PlanList = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setSpinner(true);
    try {
      const response = await axios.get(`${baseUrl}/get-plan`);
      setFilteredData(response?.data?.data?.plans);
      setSpinner(false);
    } catch (error) {
      console.error("Error fetching plan data:", error);
      setSpinner(false);
    }
  };
  const handleDelete = async (ID) => {
    setSpinner(true);
    try {
      const response = await axios.post(
        `${baseUrl}/delete-plan`,
        {
          id: ID,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSpinner(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting plan data:", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <div className="admin-wrapper">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="container-fluid location-section">
          <div className="location-headers">
            <h1>Our Plans</h1>
            <div className="location-right">
              <NavLink to="/add-plan">Add New</NavLink>
            </div>
          </div>
          <div className="location-table-wrapper mt-5">
            <div className="table-header">
              <div className="table-cell">Title</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Action</div>
            </div>
            {filteredData.length === 0 ? (
              <p className="text-center">No data available.</p>
            ) : (
              <>
                {filteredData.map((data, index) => (
                  <div key={index} className="table-row">
                    <div className="table-cell" data-heading="Title">
                      {data?.title || "--"}
                    </div>

                    <div className="table-cell" data-heading="Description">
                      {data?.content || "--"}
                    </div>
                    <div className="table-cell " data-heading="Action">
                      <div className="table-cell-action">
                        <NavLink to={`/edit-plan/${data.id}`}>
                          <img className="update-data" src={EditIcon} alt="" />
                        </NavLink>
                        <NavLink>
                          <img
                            src={DeleteIcon}
                            alt=""
                            onClick={() => handleDelete(data.id)}
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanList;
