import React, { useState } from "react";
import "./question.css";
import plusIcon from "../../../assets/images/plus-icon.png";
import minusIcon from "../../../assets/images/minus-icon.png";
// import questionData from "./questionData";

const Question = ({ questionData }) => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const handleToggleContent = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };
  return (
    <div className="container-fluid question-section">
      <div className="container question-container">
        <div className="common-heading common-heading-black">
          <h1>Most common Questions</h1>
        </div>
        <div className="question-container-body">
          {questionData && questionData.length > 0 ? (
            questionData?.map((question, index) => (
              <div className="question-card" key={index}>
                <div
                  className="question-tab"
                  onClick={() => handleToggleContent(index)}
                >
                  <h1>{question.question}</h1>
                  <img src={plusIcon} alt="" />
                </div>
                {activeQuestion === index && (
                  <div className="question-tab-content">
                    <div className="question-tab-content-header">
                      <p>{question.answer}</p>
                      <img
                        src={minusIcon}
                        alt=""
                        onClick={() => handleToggleContent(index)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Question;
