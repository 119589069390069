import React, { useState, useEffect } from "react";
import "./potential.css";
import sunIcon from "../../../assets/images/sun-icon-white.png";
import shareIcon from "../../../assets/images/share-icon.png";
import { NavLink } from "react-router-dom";

const Potential = () => {
  const [sliderValue1, setSliderValue1] = useState(100);
  const [sliderValue2, setSliderValue2] = useState(5);

  useEffect(() => {
    updateSliderBackground(1);
    updateSliderBackground(2);
  }, [sliderValue1, sliderValue2]);

  const handleSliderChange = (event, sliderNumber) => {
    const tempSliderValue = event.target.value;

    if (sliderNumber === 1) {
      setSliderValue1(tempSliderValue);
    } else if (sliderNumber === 2) {
      setSliderValue2(tempSliderValue);
    }

    updateSliderBackground(sliderNumber);
  };

  const updateSliderBackground = (sliderNumber) => {
    const progress1 = (sliderValue1 / 1000) * 100;
    const progress2 = (sliderValue2 / 24) * 100;

    const sliderEl1 = document.getElementById("range1");
    const sliderEl2 = document.getElementById("range2");

    if (sliderNumber === 1 && sliderEl1) {
      sliderEl1.style.background = `linear-gradient(to right, #007BFF ${progress1}%, #ccc ${progress1}%)`;
    } else if (sliderNumber === 2 && sliderEl2) {
      sliderEl2.style.background = `linear-gradient(to right, #007BFF ${progress2}%, #ccc ${progress2}%)`;
    }
  };

  return (
    <div className="container-fluid potential-section">
      <div className="container potential-container px-sm-0">
        <div className="potential-container-header">
          <h1>Estimate solar potential for your business</h1>
          <p>
            Gain valuable insights into potential energy savings and
            environmental impact, empowering you to make informed decisions
            about transitioning to solar power.
          </p>
        </div>
        <div className="potential-container-body">
          <div className="potential-container-left">
            <div className="power-card">
              <h1>Annual Electricity Needs (in kWh):</h1>
              <input
                className="range-input"
                type="range"
                id="range1"
                name="slider1"
                min="0"
                max="1000"
                value={sliderValue1}
                onChange={(e) => handleSliderChange(e, 1)}
              />
              <p>{sliderValue1} kWh</p>
            </div>
            <div className="power-card">
              <h1>Peak Sun Hours in Your Area (in Hours):</h1>

              <input
                className="range-input"
                type="range"
                id="range2"
                name="slider2"
                min="0"
                max="24"
                value={sliderValue2}
                onChange={(e) => handleSliderChange(e, 2)}
              />
              <p>{sliderValue2} Hours</p>
            </div>
            <NavLink>
              <img src={shareIcon} alt="" />
              Share results with colleagues
            </NavLink>
          </div>
          <div className="potential-container-right">
            <div className="estimation-card active">
              <div className="estimation-card-header">
                <h1>$103,543/year</h1>
                <p>Estimated savings with SunChoice</p>
              </div>
              <div className="estimation-card-body">
                <img src={sunIcon} alt="" />
                <h1>8.77 kW</h1>
                <p>
                  Estimated size of Solar System to Cover All Electrical Needs
                </p>
              </div>
            </div>
            <div className="estimation-card">
              <div className="estimation-card-header">
                <h1>$82,454/year</h1>
                <p>Estimated savings with next best competitor</p>
              </div>
              <div className="estimation-card-body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Potential;
