import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
const AddHomeBanner = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);

  let navigation = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    setSpinner(true);
    const title = e.target.title.value.trim();
    const subtitle = e.target.subtitle.value.trim();
    const description = e.target.description.value.trim();

    if (!title || !subtitle || !description) {
      toast.error("All fields required");
      setSpinner(false);
      return;
    }
    const postFormData = {
      title: e.target.title.value,
      subtitle: e.target.subtitle.value,
      description: e.target.description.value,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/add-update-slider`,
        postFormData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success("Banner Content added successfully:", {
        onClose: () => navigation("/homebanner-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error adding home banner content ", error);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            {" "}
            <div className="mb-4">
              <div className="form-heading">
                <h1>Add Home Banner</h1>
              </div>
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="title here..."
                name="title"
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="subtitle here..."
                name="subtitle"
              />
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="description here..."
                name="description"
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Add</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddHomeBanner;
