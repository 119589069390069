import React from "react";
import "./community.css";

const Community = ({ communityData }) => {
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  return (
    <div class="container-fluid impact-section">
      <div class="container impact-section-container px-sm-0">
        <div class="impact-section-header">
          <h1>Our Community Impact</h1>
        </div>
        <div class="impact-section-body">
          <div class="row pt-4 px-2 px-sm-4">
            {communityData && communityData.length > 0 ? (
              communityData?.map((community) => (
                <div class="col-lg-6 mb-4" key={community.id}>
                  <div class="impact-card">
                    <img src={imgUrl + community.community_icon} alt="" />
                    <h1>{community.community_title}</h1>
                    <p>{community.community_description}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
