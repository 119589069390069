import React, { useEffect } from "react";
import "./mission.css";
import HeadingBorder from "../../../assets/images/heading-border.png";
import MissionImage from "../../../assets/images/mission-img.JPG";
import MissionIcon from "../../../assets/images/mission-icon.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
const Misson = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false,
    });
  }, []);
  return (
    <div className="container mission-section">
      <div className="mission-section-left animated-element" data-aos="zoom-in">
        <div className="mission-section-left-header">
          <h1>
            Our
            <span>
              Mission <img src={HeadingBorder} alt="" />
            </span>
          </h1>
        </div>
        <div className="mission-section-left-body mt-4">
          <p>
            SunChoice is founded with small town roots and maintains a strong
            commitment to our foundation of small town values. This has led us
            to specialize in providing services to rural communities throughout
            the entire country that share these same sets of values.
          </p>
          <h1>We want to:</h1>
          <div className="mission-section-left-body-inner">
            <p>
              Empower rural communities across America to strengthen their local
              economies and personal self-sufficiency through renewable
              energies. At SunChoice, caring for our customers and the
              communities they operate in has gained a new meaning.
            </p>
          </div>
        </div>
      </div>
      <div
        className="mission-section-right animated-element"
        data-aos="zoom-out"
      >
        <img src={MissionImage} alt="" />
        <div className="mission-section-right-icon-wrapper">
          <img src={MissionIcon} alt="" />
        </div>
      </div>
      <div className="col-12 value-section-btn pt-2 pt-md-5 text-center">
        <NavLink href="">
          Learn more about our Mission
          <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.66667 0C9.41667 0 9.25 0.083333 9.08333 0.25L0.75 8.58333C0.416667 8.91667 0.416667 9.41667 0.75 9.75C1.08333 10.0833 1.58333 10.0833 1.91667 9.75L10.25 1.41667C10.5833 1.08333 10.5833 0.583333 10.25 0.25C10.0833 0.083333 9.91667 0 9.66667 0Z"
              fill="#FEFEFE"
            />
            <path
              d="M9.66634 0.000325203H2.16634C1.66634 0.000325203 1.33301 0.333658 1.33301 0.833658C1.33301 1.33366 1.66634 1.66699 2.16634 1.66699H8.83301V8.33366C8.83301 8.83366 9.16634 9.16699 9.66634 9.16699C10.1663 9.16699 10.4997 8.83366 10.4997 8.33366V0.833658C10.4997 0.333658 10.1663 0.000325203 9.66634 0.000325203Z"
              fill="#FEFEFE"
            />
          </svg>
        </NavLink>
      </div>
    </div>
  );
};

export default Misson;
