import React from "react";
import Term from "../../components/admin/setting/term";

const Terms = () => {
  return (
    <>
      <Term />
    </>
  );
};

export default Terms;
