import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const OfficeDetail = () => {
  const { id } = useParams();
  const [officeData, setOfficeData] = useState([]);
  useEffect(() => {
    fetchOffices();
  }, []);
  const fetchOffices = async () => {
    try {
      const response = await axios.get(
        "https://sunchoice.us:1090/api/get-office-data"
      );
      const data = response.data.data.office_data;
      const officeContent = data.find((office) => office.id.toString() === id);
      setOfficeData(officeContent);
    } catch (error) {
      console.log("error fetching data", error);
    }
  };

  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const bgImage = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("${
      imgUrl + officeData.office_image
    }")`,
  };
  return (
    <div
      class="container-fluid blog-detail-section top-wrapper"
      style={bgImage}
    >
      <div class="container blog-detail-container">
        <div class="blog-detail-container-content">
          <div class="blog-tags-wrapper">
            <label className="">
              <svg
                width="15"
                height="15"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.744 2.63358L20.272 7.52679C20.538 7.69969 20.671 7.78615 20.7674 7.90146C20.8527 8.00354 20.9167 8.12162 20.9558 8.24877C21 8.39241 21 8.55104 21 8.8683V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H5.8C4.11984 21 3.27976 21 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V8.8683C1 8.55104 1 8.39241 1.04417 8.24877C1.08327 8.12162 1.14735 8.00354 1.23265 7.90146C1.32901 7.78615 1.46201 7.69969 1.72802 7.52679L9.25604 2.63358M12.744 2.63358C12.1127 2.22327 11.7971 2.01812 11.457 1.93829C11.1564 1.86774 10.8436 1.86774 10.543 1.93829C10.2029 2.01812 9.88728 2.22327 9.25604 2.63358M12.744 2.63358L18.9361 6.65849C19.624 7.10909 19.9679 7.32915 20.087 7.61264C20.1911 7.86039 20.1911 8.13961 20.087 8.38737C19.9679 8.67086 19.624 8.89441 18.9361 9.34152L12.744 13.3664C12.1127 13.7767 11.7971 13.9819 11.457 14.0617C11.1564 14.1323 10.8436 14.1323 10.543 14.0617C10.2029 13.9819 9.88728 13.7767 9.25604 13.3664L3.06386 9.34151C2.37601 8.89441 2.03209 8.67086 1.91297 8.38737C1.80888 8.13961 1.80888 7.86039 1.91297 7.61264C2.03209 7.32915 2.37601 7.1056 3.06386 6.65849L9.25604 2.63358M20.5 19L13.8572 13M8.14282 13L1.5 19"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {officeData.office_email}
            </label>
            <label className="">
              <svg
                width="15"
                height="15"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.38028 7.85323C8.07627 9.30285 9.02506 10.6615 10.2266 11.8631C11.4282 13.0646 12.7869 14.0134 14.2365 14.7094C14.3612 14.7693 14.4235 14.7992 14.5024 14.8222C14.7828 14.904 15.127 14.8453 15.3644 14.6752C15.4313 14.6274 15.4884 14.5702 15.6027 14.4559C15.9523 14.1063 16.1271 13.9315 16.3029 13.8172C16.9658 13.3862 17.8204 13.3862 18.4833 13.8172C18.6591 13.9315 18.8339 14.1063 19.1835 14.4559L19.3783 14.6508C19.9098 15.1822 20.1755 15.448 20.3198 15.7333C20.6069 16.3009 20.6069 16.9712 20.3198 17.5387C20.1755 17.8241 19.9098 18.0898 19.3783 18.6213L19.2207 18.7789C18.6911 19.3085 18.4263 19.5733 18.0662 19.7756C17.6667 20 17.0462 20.1614 16.588 20.16C16.1751 20.1588 15.8928 20.0787 15.3284 19.9185C12.295 19.0575 9.43264 17.433 7.04466 15.045C4.65668 12.6571 3.03221 9.79471 2.17124 6.76131C2.01103 6.19687 1.93092 5.91464 1.9297 5.5017C1.92833 5.04347 2.08969 4.42298 2.31411 4.02348C2.51636 3.66345 2.78117 3.39863 3.3108 2.86901L3.46843 2.71138C3.99987 2.17993 4.2656 1.91421 4.55098 1.76987C5.11854 1.4828 5.7888 1.4828 6.35636 1.76987C6.64174 1.91421 6.90747 2.17993 7.43891 2.71138L7.63378 2.90625C7.98338 3.25585 8.15819 3.43065 8.27247 3.60643C8.70347 4.26932 8.70347 5.1239 8.27247 5.78679C8.15819 5.96257 7.98338 6.13738 7.63378 6.48698C7.51947 6.60129 7.46231 6.65845 7.41447 6.72526C7.24446 6.96269 7.18576 7.30695 7.26748 7.5873C7.29048 7.6662 7.32041 7.72854 7.38028 7.85323Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {officeData.office_phone}
            </label>
          </div>
          <h1>
            {officeData.office_name}
            <span></span>
          </h1>
          <p>
            <svg
              width="15"
              height="15"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9823 1.764C10.631 1.49075 10.4553 1.35412 10.2613 1.3016C10.0902 1.25526 9.9098 1.25526 9.73865 1.3016C9.54468 1.35412 9.36902 1.49075 9.0177 1.764L2.23539 7.03912C1.78202 7.39175 1.55534 7.56806 1.39203 7.78886C1.24737 7.98444 1.1396 8.20478 1.07403 8.43905C1 8.70352 1 8.9907 1 9.56505V16.8C1 17.9201 1 18.4801 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.0799 20 4.2 20H6.2C6.48003 20 6.62004 20 6.727 19.9455C6.82108 19.8976 6.89757 19.8211 6.9455 19.727C7 19.62 7 19.48 7 19.2V12.6C7 12.0399 7 11.7599 7.10899 11.546C7.20487 11.3578 7.35785 11.2049 7.54601 11.109C7.75992 11 8.03995 11 8.6 11H11.4C11.9601 11 12.2401 11 12.454 11.109C12.6422 11.2049 12.7951 11.3578 12.891 11.546C13 11.7599 13 12.0399 13 12.6V19.2C13 19.48 13 19.62 13.0545 19.727C13.1024 19.8211 13.1789 19.8976 13.273 19.9455C13.38 20 13.52 20 13.8 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4801 19 17.9201 19 16.8V9.56505C19 8.9907 19 8.70352 18.926 8.43905C18.8604 8.20478 18.7526 7.98444 18.608 7.78886C18.4447 7.56806 18.218 7.39175 17.7646 7.03913L10.9823 1.764Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {officeData.office_address}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OfficeDetail;
