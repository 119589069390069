import React, { useState, useEffect } from "react";
import Banner from "../components/blog/banner/banner";
import Blogs from "../components/blog/blogs/blogs";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import axios from "axios";
import Loader from "../components/common/loader/loader";

const Blog = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setSpinner(true);
        console.log("*********   API CALL - get-post-data - Success ******** "+Date.now())
        const response = await axios.get(`${baseUrl}/get-post-data`);
        setBlogData(response?.data?.data?.blog);
        setSpinner(false);
      } catch (error) {
        console.log("*********   API CALL - get-post-data - Failed ******** "+Date.now())
        console.error("Error fetching blog data:", error);
        setSpinner(false);
      }
    };

    fetchBlogData();
  }, [baseUrl]);

  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      <Banner />
      <Blogs blogData={blogData} />
    </>
  );
};

export default Blog;
