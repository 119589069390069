import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "../../blog/blogs/pagination";
import Loader from "../../common/loader/loader";
import { NavLink } from "react-router-dom";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
const ReapProgramList = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationPerPage, setLocationPerPage] = useState(12);
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setSpinner(true);
    try {
      const response = await axios.get(`${baseUrl}/get-reap-program`);
      setOriginalData(response.data.data.reap_programs);
      setFilteredData(response.data.data.reap_programs);
      setSpinner(false);
    } catch (error) {
      console.error("Error fetching reap data:", error);
      setSpinner(false);
    }
  };
  const handleDelete = async (ID) => {
    setSpinner(true);
    try {
      const response = await axios.post(
        `${baseUrl}/delete-reap-program`,
        {
          id: ID,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      setSpinner(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting reap:", error);
      setSpinner(false);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const indexOfLastLocation = currentPage * locationPerPage;
  const indexOfFirstLocation = indexOfLastLocation - locationPerPage;

  const filterDataBySearch = (data) => {
    return data.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  const filteredDataBySearch = filterDataBySearch(filteredData);
  const currentLocation = filteredDataBySearch.slice(
    indexOfFirstLocation,
    indexOfLastLocation
  );

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onLocationPerPageChange = (perPage) => {
    setLocationPerPage(perPage);
    setCurrentPage(1);
  };

  const onNextPage = () => {
    const totalPages = Math.ceil(filteredDataBySearch.length / locationPerPage);
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const onPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const totalLocation = filteredDataBySearch.length;
  const resultsStart = (currentPage - 1) * locationPerPage + 1;
  const resultsEnd = Math.min(currentPage * locationPerPage);

  return (
    <>
      {spinner && <Loader />}
      <div className="admin-wrapper">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="container-fluid location-section">
          <div className="location-headers">
            <h1>Reap Program List</h1>
            <div className="location-right">
              <NavLink to="/add-reap">Add New</NavLink>
            </div>
          </div>
          <div className="location-tab-wrapper mt-3">
            <div className="location-tab-right">
              <input
                type="text"
                placeholder="Search here..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="pagination-header mt-3">
            <h1>
              {totalLocation}
              <span> Reap Program</span>
            </h1>
            <div className="dropdown-pagination">
              <h2>Reap Program Page:</h2>
              <select
                className="form-select"
                value={locationPerPage}
                onChange={(e) =>
                  onLocationPerPageChange(parseInt(e.target.value, 10))
                }
              >
                {[12, 14, 16, 18].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="location-table-wrapper mt-3">
            <div className="table-header">
              <div className="table-cell">Title</div>
              <div className="table-cell">Description</div>
              <div className="table-cell">Action</div>
            </div>
            {filteredData.length === 0 ? (
              <p className="text-center">No data available.</p>
            ) : (
              <>
                {currentLocation.map((data, index) => (
                  <div key={index} className="table-row">
                    <div className="table-cell" data-heading="Title">
                      {data.title}
                    </div>
                    <div className="table-cell" data-heading="Description">
                      {data.content}
                    </div>

                    <div className="table-cell " data-heading="Action">
                      <div className="table-cell-action">
                        <NavLink to={`/edit-reap/${data.id}`}>
                          <img className="update-data" src={EditIcon} alt="" />
                        </NavLink>
                        <NavLink>
                          <img
                            src={DeleteIcon}
                            alt=""
                            onClick={() => handleDelete(data.id)}
                          />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="pagination-footer">
            <div className="pagination-value">
              <p>
                Results {resultsStart} to {resultsEnd} of {totalLocation}
              </p>
            </div>
            <div className="pagination-counter">
              <button
                className=""
                onClick={onPrevPage}
                disabled={currentPage === 1}
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Previous
              </button>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(filteredData.length / locationPerPage)}
                onPageChange={onPageChange}
                locationPerPage={locationPerPage}
              />
              <button
                className=""
                onClick={onNextPage}
                disabled={
                  currentPage ===
                  Math.ceil(filteredData.length / locationPerPage)
                }
              >
                Next
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReapProgramList;
