import React from "react";
import Aimcard from "../../common/airmCard/aimcard";
const Freedom = ({ embraceFreedomData }) => {
  console.log(embraceFreedomData, "data");
  return (
    <>
      {embraceFreedomData.map((data, index) => (
        <Aimcard key={index} {...data} />
      ))}
    </>
  );
};

export default Freedom;
