import React from "react";
import "./banner.css";
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <div className="container-fluid services-section support-section top-wrapper">
      <div className="container-fluid services-container">
        <div className="services-container-header">
          <h1>
            Financing Support:
            <span> Tailored Solutions for Your Solar Investment</span>
          </h1>
        </div>
        <div className="services-container-body">
          <p>
            At SunChoice, we understand the financial considerations that come
            with embracing solar solutions. Our Financing Support page is
            designed to offer tailored payment plan options, ensuring that your
            solar investment aligns seamlessly with your business's unique needs
            and budget.
          </p>
          <NavLink to="/solar-system">Get started </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Banner;
