import React from "react";
import "./solarsystem.css";
import { NavLink } from "react-router-dom";

const SolarSystem = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="top-wrapper container-fluid solar-section">
      <div className="solar-container">
        <h1>Elevate Your Energy Strategy with Solar Power!</h1>
        <p>Unlock the Potential of Solar Energy for Your Home or Business</p>
        <NavLink onClick={() => scrollToSection("solar-form")}>
          Get Started with Our Solar System Setup Form Below
        </NavLink>
      </div>
    </div>
  );
};

export default SolarSystem;
