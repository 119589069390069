// Blogs.js
import React, { useState } from "react";
import "./blogs.css";
import BlogCard from "../../common/blogSection/blogCard";
import Pagination from "./pagination";
const Blogs = ({ blogData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage, setBlogsPerPage] = useState(12);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogData.slice(indexOfFirstBlog, indexOfLastBlog);
  console.log(indexOfLastBlog, "last");
  console.log(indexOfFirstBlog, "first");
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onBlogsPerPageChange = (perPage) => {
    setBlogsPerPage(perPage);
    setCurrentPage(1);
  };
  const onNextPage = () => {
    const totalPages = Math.ceil(blogData.length / blogsPerPage);
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const onPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };
  const totalBlogs = blogData.length;
  const resultsStart = (currentPage - 1) * blogsPerPage + 1;
  const resultsEnd = Math.min(currentPage * blogsPerPage);
  return (
    <>
      <div className="container-fluid blogs-section">
        <div className="container blogs-container">
          <div className="pagination-header">
            <h1>{totalBlogs} Blog Posts</h1>
            <div className="dropdown-pagination">
              <h2>Posts per Page:</h2>
              <select
                id="blogsPerPageSelect"
                className="form-select"
                value={blogsPerPage}
                onChange={(e) =>
                  onBlogsPerPageChange(parseInt(e.target.value, 10))
                }
              >
                {[12, 14, 16, 18].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row py-4">
            {currentBlogs && currentBlogs.length > 0 ? (
              currentBlogs?.map((data, index) => (
                <div key={data.id} className="col-lg-4 mb-3">
                  <BlogCard {...data} customClass="blogCardWhite" />
                </div>
              ))
            ) : (
              <p className="text-center">No data available</p>
            )}
          </div>
          <div className="pagination-footer">
            <div className="pagination-value">
              <p>
                Results {resultsStart} to {resultsEnd} of {totalBlogs}
              </p>
            </div>
            <div className="pagination-counter">
              <button
                className=""
                onClick={onPrevPage}
                disabled={currentPage === 1}
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7L7 13"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Previous
              </button>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(blogData.length / blogsPerPage)}
                onPageChange={onPageChange}
                blogsPerPage={blogsPerPage}
              />
              <button
                className=""
                onClick={onNextPage}
                disabled={
                  currentPage === Math.ceil(blogData.length / blogsPerPage)
                }
              >
                Next
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
