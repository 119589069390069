const grantIcon1 = require("../../../assets/images/grant-icon1.png");
const grantIcon2 = require("../../../assets/images/grant-icon2.png");
const grantIcon3 = require("../../../assets/images/grant-icon3.png");
const grantIcon4 = require("../../../assets/images/grant-icon4.png");
const grantData = [
  {
    icon: grantIcon1,
    title: "Versatility in Grant Assistance",
    description:
      "While rooted in REAP expertise, SunChoice is versatile, assisting clients in grant applications through various programs, including USDA, EPA, and OCED.",
    learnMoreLink: "/about",
  },
  {
    icon: grantIcon2,
    title: "Grant Amounts",
    description:
      "While rooted in REAP expertise, SunChoice is versatile, assisting clients in grant applications through various programs, including USDA, EPA, and OCED.",
    learnMoreLink: "/about",
  },
  {
    icon: grantIcon3,
    title: "Tailored Payment Plans",
    description:
      "While rooted in REAP expertise, SunChoice is versatile, assisting clients in grant applications through various programs, including USDA, EPA, and OCED.",
    learnMoreLink: "/about",
  },
  {
    icon: grantIcon4,
    title: "Premium Products, Money-Saving Terms",
    description:
      "While rooted in REAP expertise, SunChoice is versatile, assisting clients in grant applications through various programs, including USDA, EPA, and OCED.",
    learnMoreLink: "/about",
  },
];

export default grantData;
