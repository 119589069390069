import React from "react";
import "./feature.css";
import featureData from "./featureData";

const Feature = () => {
  return (
    <div class="container-fluid feature-section">
      <div class="container feature-container">
        {featureData.map((section, sectionIndex) => (
          <div key={sectionIndex} className="table-header">
            <div className="table-cell table-cell-main">
              {section.heading} <span>For each membership plan:</span>
            </div>
            {section.subHeading.map((subHeadings, featureIndex) => (
              <div
                key={featureIndex}
                className={`table-cell ${
                  featureIndex === 1 ? "table-cell-highlight" : ""
                }`}
              >
                {subHeadings.title}
              </div>
            ))}
          </div>
        ))}
        {featureData.map((section, sectionIndex) =>
          section.features.map((feature, featureIndex) => (
            <div key={featureIndex} className="table-row">
              <div
                className="table-cell table-cell-main"
                data-heading={section.heading}
              >
                {feature.title}
              </div>
              {feature.plans.map((plan, planIndex) => (
                <div
                  key={planIndex}
                  className={`table-cell ${
                    planIndex === 1 ? "table-cell-highlight" : ""
                  }`}
                  data-heading={feature.title}
                >
                  {plan}
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Feature;
