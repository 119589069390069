import React, { useState } from "react";
import "./solarform.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
const SolarForm = () => {
  const [errors, setErrors] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phone_number: "",
    email: "",
    monthly_bill: "",
    roof_condition: "",
    roof_space: "",
    roof_orientation: "",
    installation_budget: "",
    installation_timeline: "",
    description: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.phone_number || !formData.address) {
      setErrors({
        name: !formData.name ? "Name is required" : "",
        phone_number: !formData.phone_number ? "Phone number is required" : "",
        address: !formData.address ? "Address is required" : "",
      });
      return;
    }
    setSpinner(true);
    try {
      const response = await axios.post(
        "https://sunchoice.us:1090/api/add-solar-request",
        formData
      );
      setSpinner(false);
      toast.success("Request submitted successfully!");
      resetForm();
    } catch (error) {
      setSpinner(false);
      console.error("Failed to submit form:", error.message);
    }
  };
  const resetForm = () => {
    setFormData({
      name: "",
      address: "",
      phone_number: "",
      email: "",
      monthly_bill: "",
      roof_condition: "",
      roof_space: "",
      roof_orientation: "",
      installation_budget: "",
      installation_timeline: "",
      description: "",
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  return (
    <>
      {spinner && <Loader />}
      <div className="container solar-form" id="solar-form">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="form-heading text-center">
              <h1>Take the First Step Towards Solar Power!</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name here..."
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <div className="error-message">{errors.name}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address here..."
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
                {errors.address && (
                  <div className="error-message">{errors.address}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone here..."
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                />
                {errors.phone_number && (
                  <div className="error-message">{errors.phone_number}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email here..."
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Monthly Electricity Bill</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bill here..."
                  name="monthly_bill"
                  value={formData.monthly_bill}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Roof Condition</label>
                <select
                  className="form-control"
                  name="roof_condition"
                  value={formData.roof_condition}
                  onChange={handleChange}
                >
                  <option value="">Select Condition Type...</option>
                  <option value="good">Good</option>
                  <option value="fair">Fair</option>
                  <option value="poor">Poor</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Roof Space (sq. ft. or sq. m).</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Space here..."
                  name="roof_space"
                  value={formData.roof_space}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Roof Orientation</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Orientation here..."
                  name="roof_orientation"
                  value={formData.roof_orientation}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Installation Budget</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Budget here..."
                  name="installation_budget"
                  value={formData.installation_budget}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3  form-field-solar">
                <label>Installation Timeline</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Timeline here..."
                  name="installation_timeline"
                  value={formData.installation_timeline}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3  form-field-solar">
                <label>Any specific preferences or requirements</label>
                <textarea
                  className="form-control"
                  placeholder="Text here..."
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3  form-field-solar pt-3">
                <button>Get Solarized</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SolarForm;
