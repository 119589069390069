import React from "react";
import "./plan.css";
import CardCommon from "../../common/cardCommom/cardCommon";
import headingBorder from "../../../assets/images/mission-heading.png";
import planDatas from "./planData";

const Plan = ({ planData }) => {
  console.log(planData);
  return (
    <div className="container-fluid plan-section mb-5">
      <div className="container plan-container">
        <div className="common-heading-border-large">
          <h1>
            Choose the plan that’s right for you
            <img src={headingBorder} alt="" />
          </h1>
        </div>
        <div className="plan-container-body">
          <div className="row">
            {planData.map((data, index) => (
              <div key={index} className="col-lg-4 mb-3">
                <CardCommon {...data} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
