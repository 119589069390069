import React from "react";
import "./blogContent.css";

const BlogContent = (props) => {
  const imgUrl = "https://sunchoice.us/sunchoice-backend/";
  const blogContentArray = JSON.parse(`[${props.blog_content}]`);
  return (
    <div class="container-fluid blog-detail-content-section">
      <div class="container blog-detail-content-container">
        {blogContentArray.map((content, index) => (
          <div key={index} className="blog-content-wrapper">
            <h1>{content.heading}</h1>
            <p dangerouslySetInnerHTML={{ __html: content.description }} />
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="blog-content-image">
                  <img src={imgUrl + content.post_image} alt="" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogContent;
