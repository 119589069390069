import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
import "./plan.css";

const EditPlan = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    list: [],
  });
  const [listInput, setListInput] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    const fetchPlanData = async (id) => {
      setSpinner(true);
      try {
        const response = await axios.get(`${baseUrl}/get-plan`);
        const planData = response?.data?.data?.plans;
        setSpinner(false);
        const plan = planData.find((slide) => slide.id == id);
        setFormData({
          title: plan.title,
          content: plan.content,
          list: plan.list ? JSON.parse(plan.list) : [],
        });
      } catch (error) {
        setSpinner(false);
        console.error("Error fetching plan data:", error);
        toast.error("Failed to fetch plan data. Please try again later.");
      }
    };

    fetchPlanData(id);
  }, [id]);

  const handleAddListItem = () => {
    if (listInput.trim() !== "") {
      setFormData({
        ...formData,
        list: [...formData.list, listInput.trim()],
      });
      setListInput("");
    }
  };

  const handleRemoveListItem = (index) => {
    setFormData({
      ...formData,
      list: formData.list.filter((_, i) => i !== index),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token } = localStorage;
    setSpinner(true);
    const { title, content, list } = formData;

    if (!title || !content || list.length === 0) {
      toast.error("All fields required, including at least one list item");
      setSpinner(false);
      return;
    }

    const postFormData = {
      id: id,
      title: title,
      content: content,
      list: list,
    };

    try {
      await axios.post(`${baseUrl}/add-plan`, postFormData, {
        headers: {
          Authorization: token,
        },
      });
      toast.success("Our Plan updated successfully", {
        onClose: () => navigate("/plan-list"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("Error editing plan", error);
      toast.error("Error updating plan content");
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="admin-wrapper">
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Update Our Plan</h1>
              </div>
            </div>
            <div className="mb-4 form-field">
              <input
                type="text"
                className="form-control"
                placeholder="title here..."
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field">
              <div className="category-wrapper">
                {formData.list.map((item, index) => (
                  <div key={index} className="category-list">
                    <h1>{item}</h1>
                    <button
                      type="button"
                      onClick={() => handleRemoveListItem(index)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
              <div className="add-category">
                <input
                  type="text"
                  className="form-control"
                  placeholder="item here..."
                  value={listInput}
                  onChange={(e) => setListInput(e.target.value)}
                />
                <button type="button" onClick={handleAddListItem}>
                  Add
                </button>
              </div>
            </div>
            <div className="mb-4 form-field blog-content-more">
              <textarea
                className="form-control"
                placeholder="description here..."
                name="content"
                value={formData.content}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-field pt-4">
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditPlan;
