import React from "react";
import "./independence.css";
import independenceImage from "../../../assets/images/independenc-bg.png";
import { NavLink } from "react-router-dom";

const Independence = () => {
  return (
    <div className="container-fluid independence-section">
      <div className="container independence-container">
        <div className="independence-container-header">
          <h1>Energy Independence</h1>
        </div>
        <div className="independence-container-body">
          <div className="independence-container-left">
            <img src={independenceImage} alt="" />
          </div>
          <div className="independence-container-right">
            <h1>
              Owning Your Power, Redefining Your
              <span>Relationship with Energy</span>
            </h1>
            <ul>
              <li>
                <strong>End Renting, Start Owning:</strong> "Say goodbye to the
                traditional tenant-landlord relationship with your power
                company. Own your energy, build value, and control costs."
              </li>
              <li>
                <strong>Asset for Your Business:</strong> "Redirect the money
                you would pay to a power company into a long-term asset that
                continuously brings financial and operational value for
                decades."
              </li>
            </ul>
            <NavLink to="/solar-system">Get started </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Independence;
