import React, { useState, useEffect } from "react";
import axios from "axios";
import "./setting.css";

const PrivacyPolicy = () => {
  const [adminContent, setAdminContent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://sunchoice.us:1090/api/get-admin-content"
        );
        const data = response.data;
        const filteredContent = data.data.admin_content.filter(
          (item) => item.type === "privacy"
        );
        setAdminContent(filteredContent);
      } catch (error) {
        console.error("Error fetching admin content:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="top-wrapper container-fluid solar-section">
        <div className="solar-container">
          <h1>Privacy Statement</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
      </div>
      <div className="container py-5 editor-content my-5">
        {adminContent.map((item) => (
          <div key={item.id}>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        ))}
      </div>
    </>
  );
};

export default PrivacyPolicy;
