import React from "react";
import ListLocation from "../../components/admin/listLocation/listLocation";
const Dashboard = () => {
  return (
    <>
      <ListLocation />
    </>
  );
};

export default Dashboard;
