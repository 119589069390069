import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./addlocation.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../common/loader/loader";
const AddLocation = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const googleKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  // loader
  const [spinner, setSpinner] = useState(false);
  let navigation = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    type: "",
    applicant: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    amount: "",
  });
  useEffect(() => {
    if (id) {
      fetchLocationData(id);
    }
  }, [id]);

  const fetchLocationData = async (id) => {
    setSpinner(true);
    try {
      const response = await axios.get(`${baseUrl}/get-location-data`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = response.data.data.location;

      const location = data.find((item) => item.id == id);
      setSpinner(false);
      setFormData(location);
    } catch (error) {
      setSpinner(false);
      toast.error("Failed to fetch location data. Please try again later.");
    }
  };

  const [displayError, setDisplayError] = useState();
  const handleChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const validateForm = () => {
    const { type, applicant, address, city, state, zipCode } = formData;
    if (!type || !applicant || !address || !city || !state || !zipCode) {
      setDisplayError("All fields are required.");
      return false;
    }
    return true;
  };
  const getCoordinates = async () => {
    const { address, city, state } = formData;
    const formattedAddress = `${address}, ${city}, ${state}`;
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          formattedAddress
        )}&key=${googleKey}`
      );
      console.log(response, "result");
      const results = response.data.results;

      if (results.length > 0) {
        const location = results[0].geometry.location;
        console.log("Latitude:", location.lat);
        console.log("Longitude:", location.lng);
        return {
          ...formData,
          lat: location.lat.toString(),
          lng: location.lng.toString(),
        };
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      setDisplayError("Failed to retrieve coordinates. Please try again.");
    }
  };
  console.log(formData, "formdata");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSpinner(true);
      if (id) {
        formData.location_id = id;
      }

      try {
        const updatedFormData = await getCoordinates();

        console.log("Form Data before submission:", updatedFormData);

        const formDataEncoded = new URLSearchParams(updatedFormData).toString();
        console.log("Encoded Form Data:", formDataEncoded);
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: localStorage.getItem("token"),
          },
          body: formDataEncoded,
        };

        let url = `${baseUrl}/add-location`;
        if (id) {
          url = `${baseUrl}/update-location-data`;
        }

        const response = await fetch(url, requestOptions);
        setSpinner(false);

        if (response.ok) {
          toast.success(
            id
              ? "Location updated successfully!"
              : "Location added successfully!",
            {
              onClose: () => navigation("/location"),
            }
          );
          setDisplayError(null);
        } else {
          const errorData = await response.text();
          throw new Error(errorData);
        }
      } catch (error) {
        setSpinner(false);
        toast.error(
          `Failed to ${id ? "update" : "add"} location. ${error.message}`
        );
      }
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <div className="admin-wrapper">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="container-fluid add-location-section">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>{id ? "Edit Location" : "Add Location"}</h1>
              </div>
            </div>
            {displayError && (
              <div class="alert alert-danger" role="alert">
                {displayError}
              </div>
            )}
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Name here..."
                name="applicant"
                value={formData.applicant}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Address here..."
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="City here..."
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="State here..."
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="zipCode here..."
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
              />
            </div>
            {formData.type === "awardgrant" && (
              <div className="mb-4 form-field">
                <input
                  type="text"
                  className="form-control"
                  placeholder="$ Amount here..."
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className="mb-4 form-field ">
              <select
                className="form-control"
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select Type...</option>
                <option value="grant">Grant</option>
                <option value="awardgrant">Award Grant</option>
              </select>
            </div>
            <div className="form-field pt-4">
              <button type="submit">{id ? "Update" : "Add"}</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddLocation;
