import React from 'react'
import GrantProgramList from '../../components/admin/grantProgram/grantProgramList'

const GrantProgramIndex = () => {
  return (
   <GrantProgramList/>
  )
}

export default GrantProgramIndex
