import React, { useState, useEffect } from "react";
import Banner from "../components/support/banner/banner";
import Freedom from "../components/service/freedom/freedom";
import Plan from "../components/support/plan/plan";
import Feature from "../components/support/feature/feature";
import Blog from "../components/common/blogSection/blog";
import SunAnimation from "../components/layout/sun-animation/sunanimation";
import axios from "axios";
import Loader from "../components/common/loader/loader";
import checkIcon from "../assets/images/check-icon.png";
const Support = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [embraceFreedomData, setEmbraceFreedomData] = useState([]);
  const fetchPlanData = async () => {
    setSpinner(true);
    try {
      console.log("*********   API CALL - get-plan - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-plan`);
      const data = response?.data?.data?.plans;
      const transformedData = data.map((item) => ({
        ...item,
        list: item.list ? JSON.parse(item.list) : [],
        buttonContent: "Get started",
      }));
      setPlanData(transformedData);
      
    fetchEmbraceFreedomData();
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.log("error fetching plan data", error);
      setSpinner(false);
    }
  };
  useEffect(() => {
    fetchPlanData();
  }, []);
  const fetchEmbraceFreedomData = async () => {
    try {
      console.log("*********   API CALL - get-aims-card - Success ******** "+Date.now())
      const response = await axios.get(`${baseUrl}/get-aims-card`);
      const data = response?.data?.data?.aims_cards;
      const missionAimCard = data.filter((data) => data.type === "freedom");
      const transformedData = missionAimCard.map((item) => ({
        ...item,
        mission_items: item.mission_items ? JSON.parse(item.mission_items) : [],
        buttonContent: "Get started",
        icon: checkIcon,
      }));
      setEmbraceFreedomData(transformedData);
      setSpinner(false);
    } catch (error) {
      console.log("*********   API CALL - Error ******** "+Date.now())
      console.error("Error fetching embrace freedom data:", error);
      setSpinner(false);
    }
  };
  return (
    <>
      {spinner && <Loader />}
      <SunAnimation />
      <Banner />
      <Plan planData={planData} />
      <Feature />
      <Freedom embraceFreedomData={embraceFreedomData} />
      <Blog />
    </>
  );
};

export default Support;
